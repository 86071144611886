/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { ControlModeCellRender, WorkModeCellRender } from '../../columns/cells';
import { ControlModeHeaderRender, WorkModeHeaderRender } from '../../columns/headers';
import { translateControlMode, translateControlModeOrWorkMode } from '../../funcs/calculateCellValue';
import { TEMPORARY_PROPERTY } from '../../constants';
import detectedTablesSharedFields from './detectedsSharedColumns';
import { StateCellRenderForTemporaryField } from '../../columns/cellsForTemporaryField';
import { translateCurrentWorkModeForTemporaryField } from '../../funcs/calculateCellValueForTemporaryField';

export default ({
  deviceListClasses, now, controlModesStrizh3,
  selectedObjects, handleCategoryParamStatusChangeFromHeader,
  actionMenu, t, handleCategoryParamStatusChange,
  theme, workModes, positions, innerClasses,
}) => [
    ...detectedTablesSharedFields.calculateValues.shared({ innerClasses, actionMenu, selectedObjects, t, deviceListClasses, now, theme }),
    ...detectedTablesSharedFields.calculateValues.byCategory.radarAndAntiUav({ positions, theme, t }),
    {
      dataField: 'attributes.categoryParams.controlMode',
      caption: 'deviceControlMode',
      width: 275,
      headerCellComponent: () => <ControlModeHeaderRender controlModesStrizh3={controlModesStrizh3} handleCategoryParamStatusChangeFromHeader={handleCategoryParamStatusChangeFromHeader} t={t} />,
      cellComponent: ({ data }) => <ControlModeCellRender dataset={data} controlModesStrizh3={controlModesStrizh3} handleCategoryParamStatusChange={handleCategoryParamStatusChange} theme={theme} t={t} />,
      calculateCellValue: (item) => translateControlMode(item, t),
      calculateGroupValue: (item) => translateControlMode(item, t),
    },
    {
      dataField: 'attributes.categoryParams.workMode',
      caption: 'deviceWorkMode',
      width: 320,
      headerCellComponent: () => <WorkModeHeaderRender handleCategoryParamStatusChangeFromHeader={handleCategoryParamStatusChangeFromHeader} workModes={workModes} t={t} />,
      cellComponent: ({ data }) => <WorkModeCellRender dataset={data} handleCategoryParamStatusChange={handleCategoryParamStatusChange} workModes={workModes} t={t} />,
      calculateCellValue: (item) => translateControlModeOrWorkMode(item, t),
      calculateGroupValue: (item) => translateControlModeOrWorkMode(item, t),
    },
    {
      dataField: TEMPORARY_PROPERTY.CurrentWorkMode,
      caption: 'deviceCurrentWorkMode',
      width: 200,
      allowGrouping: true,
      allowFiltering: true,
      cellComponent: ({ data }) => <StateCellRenderForTemporaryField dataset={data} t={t} />,
      calculateCellValue: (item) => translateCurrentWorkModeForTemporaryField(item, t),
      calculateGroupValue: (item) => translateCurrentWorkModeForTemporaryField(item, t),
    },
  ];
