import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default () => {
  const t = useTranslation();

  const registerUnknownDefaultGroupId = useSelector((state) => state.session.server.attributes.registerUnknownDefaultGroupId);

  return columnTemplates.settingsFields.devicesGroupsSettings({
    t, registerUnknownDefaultGroupId,
  });
};
