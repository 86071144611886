import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import MainPage from './views/Main/MainPage/MainPage';
import RouteReportPage from './views/Reports/RouteReportPage';
import ServerPage from './views/Settings/ServerPage';
import UsersPage from './views/Settings/UsersPage';
import DevicePage from './views/Settings/DevicePage';
import UserPage from './views/Settings/UserPage';
import UnionsPage from './views/Settings/UnionsPage';
import UnionPage from './views/Settings/UnionPage';
import NotificationsPage from './views/Settings/NotificationsPage';
import NotificationPage from './views/Settings/NotificationPage';
import GroupsPage from './views/Settings/GroupsPage';
import GroupPage from './views/Settings/GroupPage';
import ReplayPage from './views/Replay/ReplayPage/ReplayPage';
import LoginForm from './views/Login/LoginForm';
import RegisterForm from './views/Login/RegisterForm';
import ResetPasswordForm from './views/Login/ResetPasswordForm';
import GeofencesPage from './views/Settings/GeofencesPage';
import OptionsLayout from './views/Settings/OptionsLayout';
import DevicesPage from './views/Settings/DevicesPage';
import StopReportPage from './views/Reports/StopReportPage';
import EventReportPage from './views/Reports/EventReportPage';
import SmsReportPage from './views/Reports/SmsReportPage';
import LogReportPage from './views/Reports/LogReportPage';
import CommandPage from './views/Settings/CommandPage';
// import InstructionPage from './views/Settings/InstructionPage';
import TripReportPage from './views/Reports/TripReportPage';
import MarkercatsPage from './views/Settings/MarkercatsPage';
import MarkercatPage from './views/Settings/MarkercatPage';
import MarkersPage from './views/Settings/MarkersPage';
import MarkerPage from './views/Settings/MarkerPage';
import DetectedObjectsTableView from './views/Settings/DetectedObjectsTableView';
import RadarsTableView from './views/Settings/RadarsTableView';
import AntiUavsTableView from './views/Settings/AntiUavsTableView';
import DetectedObjectsReportPage from './views/Reports/DetectedObjectsReportPage';
import RadarReportPage from './views/Reports/RadarReportPage';
import AntiUavReportPage from './views/Reports/AntiUavReportPage';
import DetectedObjectsPageView from './views/Settings/DetectedObjectsPageView';
import RadarsPageView from './views/Settings/RadarsPageView';
import AntiUavsPageView from './views/Settings/AntiUavsPageView';

const Navigation = () => {
  const initialized = useSelector((state) => state.session.initialized);
  const accessibleCategoriesByDetected = useSelector((state) => state.devices.accessibleCategoriesByDetected);

  const devicesDetectedReportsPages = {
    detected_object: <Route key="detected_object" exact path="/reports/detected" component={DetectedObjectsReportPage} />,
    radar: <Route key="radar" exact path="/reports/radar" component={RadarReportPage} />,
    anti_uav: <Route key="anti_uav" exact path="/reports/antiuav" component={AntiUavReportPage} />,
  };

  return (
    <Switch>
      <Route exact path="/login" component={LoginForm} />
      <Route exact path="/register" component={RegisterForm} />
      <Route exact path="/reset-password" component={ResetPasswordForm} />
      <Route>
        {!initialized ? (<LinearProgress />) : (
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/replay" component={ReplayPage} />
            <Route exact path="/user/:id?" component={UserPage} />
            <Route exact path="/device/:id?" component={DevicePage} />
            <Route exact path="/command/:id?" component={CommandPage} />
            <Route exact path="/geofences" component={GeofencesPage} />
            <Route exact path="/settings" component={OptionsLayout} />

            <Route exact path="/objects/detected" component={DetectedObjectsPageView} />
            <Route exact path="/objects/detected/table" component={DetectedObjectsTableView} />
            <Route exact path="/objects/radars" component={RadarsPageView} />
            <Route exact path="/objects/radars/table" component={RadarsTableView} />
            <Route exact path="/objects/antiuavs" component={AntiUavsPageView} />
            <Route exact path="/objects/antiuavs/table" component={AntiUavsTableView} />

            <Route exact path="/settings/notifications" component={NotificationsPage} />
            <Route exact path="/settings/notification/:id?" component={NotificationPage} />
            <Route exact path="/settings/groups" component={GroupsPage} />
            <Route exact path="/settings/group/:id?" component={GroupPage} />
            <Route exact path="/settings/unions" component={UnionsPage} />
            <Route exact path="/settings/union/:id?" component={UnionPage} />
            <Route exact path="/settings/markers" component={MarkersPage} />
            <Route exact path="/settings/marker/:id?" component={MarkerPage} />
            <Route exact path="/settings/icons" component={MarkercatsPage} />
            <Route exact path="/settings/icon/:id?" component={MarkercatPage} />
            <Route exact path="/admin/server" component={ServerPage} />
            <Route exact path="/admin/users" component={UsersPage} />
            <Route exact path="/settings/devices" component={DevicesPage} />
            {/* <Route exact path="/settings/instruction" component={InstructionPage} /> */}

            <Route exact path="/reports/route" component={RouteReportPage} />
            {accessibleCategoriesByDetected.map((category) => devicesDetectedReportsPages[category])}
            <Route exact path="/reports/event" component={EventReportPage} />
            <Route exact path="/reports/trip" component={TripReportPage} />
            <Route exact path="/reports/stop" component={StopReportPage} />
            <Route exact path="/reports/sms" component={SmsReportPage} />
            <Route exact path="/reports/log" component={LogReportPage} />
          </Switch>
        )}
      </Route>
    </Switch>
  );
};

export default Navigation;
