import React, { useState } from 'react';
import {
  Grid, Button, TextField, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { sessionActions } from '../../store';
import StartPage from './StartPage';
import usePersistedState from '../../common/utils/usePersistedState';
import BadResponse from '../../common/components/BadResponse';
import { useTranslation } from '../../common/components/LocalizationProvider';

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();
  const cookies = new Cookies();

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState();

  const registrationEnabled = useSelector((state) => (!!state.session.server?.registration));
  const timeExpired = useSelector((state) => state.session.timeExpired);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch('/api/session', {
      method: 'POST',
      body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
    });
    if (response.ok) {
      cookies.set('user', btoa(email), { path: '/', expires: moment().add(1, 'year').toDate() });
      cookies.set('password', btoa(password), { path: '/', expires: moment().add(1, 'year').toDate() });
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      history.push('/');
    } else {
      setStatus(response.status);
      if (response.status === 401) setFailed(true);
      setPassword('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handleSubmit(e);
    }
  };

  return (
    <StartPage>
      <BadResponse status={status} setStatus={setStatus} />
      {timeExpired ? (
        <Alert severity="warning">{t('accessPeriodExpired')}</Alert>
      ) : (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              required
              fullWidth
              error={failed}
              label={t('userLogin')}
              name="email"
              value={email}
              autoComplete="email"
              autoFocus={!email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={handleSpecialKey}
              helperText={failed && t('loginFailed')}
              variant="filled"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              error={failed}
              label={t('userPassword')}
              name="password"
              value={password}
              type="password"
              autoComplete="current-password"
              autoFocus={!!email}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={handleSpecialKey}
              variant="filled"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit}
              onKeyUp={handleSpecialKey}
              variant="contained"
              color="secondary"
              disabled={!email || !password}
              fullWidth
            >
              {t('loginLogin')}
            </Button>
          </Grid>
          <Grid item container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              {registrationEnabled && (
                <Button onClick={() => history.push('/register')} color="secondary">
                  {t('loginRegister')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button onClick={() => history.push('/reset-password')} size="medium">
                <Typography variant="caption">
                  {t('loginReset')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </StartPage>
  );
};

export default LoginForm;
