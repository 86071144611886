import React from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, FormControlLabel, Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CoordinatesBlock from '../../../common/components/CoordinatesBlock';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MapBlock = ({
  item, setItem, errors, setErrors,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const isValidatedInt = (value) => {
    const numValue = Number(value);
    if (Number.isInteger(numValue)) {
      return true;
    }
    return false;
  };

  const isValidatedZoom = (value) => {
    const numValue = Number(value);
    if (isValidatedInt(numValue) && numValue >= 0 && numValue <= 15) {
      return true;
    }
    return false;
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedMap')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          {t('serverCenterTitle')}
        </Typography>
        <Grid container spacing={1}>
          <CoordinatesBlock
            latitude={item.latitude}
            longitude={item.longitude}
            setLatitude={(latitude) => setItem({ ...item, latitude })}
            setLongitude={(longitude) => setItem({ ...item, longitude })}
            errors={errors}
            setErrors={setErrors}
          />
        </Grid>
        <TextField
          margin="normal"
          value={item.zoom}
          onChange={(event) => setItem({ ...item, zoom: event.target.value })}
          label={t('serverZommTitle')}
          variant="filled"
          error={!(isValidatedZoom(item.zoom) || !item.zoom)}
          helperText={!(isValidatedZoom(item.zoom) || !item.zoom) && t('ValidateErrorMsg')}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={t('miniMapLabel')}
          checked={item.attributes?.showMinimap}
          onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, showMinimap: event.target.checked } })}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default MapBlock;
