/* eslint-disable object-curly-newline */
import React from 'react';
import { ColorsCellRender, IconCellRender } from '../../columns/cells';
import { ColorsGroupCellRender, TextIconGroupCellRender } from '../../columns/groups';
import {
  getTextForIconOrDefaultValue, getTextFromIcon, getTextFromIconWithSeparator,
  translateDeviceModelFromItem,
} from '../../funcs/calculateCellValue';
import baseProperties from '../shared/baseProperties';
import { getStringFromBoolean } from '../../funcs/utils';
import presetsColumns from '../shared/presetsColumns';

export default {
  calculateValues: {
    shared: ({ devicesObject, groups, t, theme, devices }) => [
      {
        ...presetsColumns.common.uniqueId,
        ...baseProperties,
        calculateCellValue: (item) => devicesObject[item.deviceId]?.uniqueId,
        calculateGroupValue: (item) => devicesObject[item.deviceId]?.uniqueId,
      },
      {
        ...presetsColumns.common.name,
        ...baseProperties,
        calculateCellValue: (item) => devices[item.deviceId]?.name,
        calculateGroupValue: (item) => devices[item.deviceId]?.name,
      },
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
        calculateGroupValue: (item) => groups[devices[item.deviceId]?.groupId]?.name,
      },
      {
        ...presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
        ...baseProperties,
        cellComponent: ({ data }) => <IconCellRender dataset={{ data: devices[data.data.deviceId] }} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(devices[item.deviceId], t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(devices[item.deviceId], t),
        calculateDisplayValue: (item) => getTextFromIcon(devices[item.deviceId], t),
      },
      {
        ...presetsColumns.calculateValues.deviceColor({ theme }),
        ...baseProperties,
        cellComponent: ({ data }) => <ColorsCellRender dataset={{ data: devices[data.data.deviceId] }} theme={theme} />,
        groupCellComponent: ({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />,
        calculateCellValue: (item) => getTextForIconOrDefaultValue(devices[item.deviceId], theme),
        calculateGroupValue: (item) => getTextForIconOrDefaultValue(devices[item.deviceId], theme),
      },
    ],
    byCategory: {
      radarAndAntiUav: ({ devices, t, positions }) => [
        {
          ...baseProperties,
          name: 'deviceModel',
          caption: 'deviceModel',
          calculateCellValue: (item) => translateDeviceModelFromItem(item, t, devices),
          calculateGroupValue: (item) => translateDeviceModelFromItem(item, t, devices),
        },
        {
          ...presetsColumns.calculateValues.deviceConnectionStatus.text({ t }),
          ...baseProperties,
        },
        {
          ...baseProperties,
          name: 'positionConnectionStatusDescription',
          caption: 'positionConnectionStatusDescription',
          calculateCellValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.connectionStatusDescription,
          calculateGroupValue: (item) => positions[item.deviceId]?.attributes?.categoryParams?.connectionStatusDescription,
        },
        {
          dataField: 'attributes.categoryParams.showRadarArea',
          name: 'deviceShowRadarArea',
          caption: 'deviceShowRadarArea',
          visible: false,
          calculateCellValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
          calculateGroupValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
        },
      ],
    },
  },
  serverField: [
    presetsColumns.serverField.serverTime,
    presetsColumns.serverField.fixTime,
  ],
};
