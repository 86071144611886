/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { TEMPORARY_PROPERTY } from '../../constants';
import presetsColumns from '../shared/presetsColumns';
import { ColorConnectionCellRenderForTemporaryField, NameCellRenderForTemporaryField, VisibleOnMapCellRenderForTemporaryField } from '../../columns/cellsForTemporaryField';
import { translateConnectionStatusForTemporaryField } from '../../funcs/calculateCellValueForTemporaryField';

export default {
  calculateValues: {
    shared: ({ innerClasses, actionMenu, selectedObjects, t, deviceListClasses, now, theme }) => [
      presetsColumns.calculateValues.action({ actionMenu, innerClasses, selectedObjects }),
      presetsColumns.common.uniqueId,
      {
        ...presetsColumns.calculateValues.deviceName.withHighlightAndTracking({ innerClasses }),
        cellComponent: ({ data }) => <NameCellRenderForTemporaryField dataset={data} innerClasses={innerClasses} />,
      },
      presetsColumns.calculateValues.deviceStatus({ deviceListClasses, t }),
      presetsColumns.calculateValues.lastUpdateShort({ now, t }),
      presetsColumns.calculateValues.lastUpdate(),
      presetsColumns.calculateValues.deviceIcon.icon({ t }),
      presetsColumns.calculateValues.deviceColor({ theme }),
      {
        dataField: TEMPORARY_PROPERTY.IsClosed,
        caption: 'globalVisibleOnMap',
        width: 40,
        allowExporting: false,
        cellComponent: ({ data }) => <VisibleOnMapCellRenderForTemporaryField dataset={data} />,
        calculateCellValue: (item) => !item[TEMPORARY_PROPERTY.IsClosed],
      },
      {
        ...presetsColumns.base.groupName,
        dataField: TEMPORARY_PROPERTY.GroupName,
      },
    ],
    byCategory: {
      radarAndAntiUav: ({ positions, theme, t }) => [
        {
          ...presetsColumns.calculateValues.deviceConnectionStatus.iconFromPositions({ positions, t, theme }),
          dataField: TEMPORARY_PROPERTY.ConnectionStatus,
          defaultVisibleIndex: 1,
          cellComponent: ({ data }) => <ColorConnectionCellRenderForTemporaryField dataset={data} theme={theme} />,
          calculateCellValue: (item) => translateConnectionStatusForTemporaryField(item, t, positions),
          calculateGroupValue: (item) => translateConnectionStatusForTemporaryField(item, t, positions),
        },
        {
          ...presetsColumns.calculateValues.deviceModel({ t }),
          visible: false,
        },
      ],
    },
  },
};
