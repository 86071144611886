import React from 'react';
import {
  makeStyles, Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    height: '300px',
    margin: '10px',
    // Первый блок заголовка.
    '& > div:first-child p': {
      fontWeight: 'bold',
    },
    // Начиная с блока заголовка (drawerHeader) устанавливает шрифт всем тегам <p>.
    '& div + div p': {
      fontSize: '12px',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    height: theme.spacing(3),
  },
}));

const CompactPanel = ({
  bodyContent, headerContent, paperStyle,
}) => {
  const classes = useStyles();

  return (
    <Paper
      style={paperStyle}
      className={classes.root}
      elevation={0}
    >
      <div className={classes.drawerHeader}>
        {headerContent}
      </div>

      {bodyContent}
    </Paper>
  );
};

export default CompactPanel;
