/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { ActionsMenuHeaderCellComponent } from '../../columns/headers';
import {
  ActionsMenuRender, AutoregistrationIconCellRender, ColorConnectionCellRender,
  ColorsCellRender, IconCellRender, LastUpdateCellRender,
  NameCellRender, NotificatorLinksRender, StatusCellRender, TextIconCellRender,
} from '../../columns/cells';
import {
  getCategoryName, getDateTime, getShortDateTime, getTextForIconOrDefaultValue,
  getTextFromIcon, getTextFromIconWithSeparator, toFixedNumber,
  translateConnectionStatus, translateConnectionStatusFromItem, translateDeviceStatus,
  concateStringsFromNotificator,
  translateDeviceStatusFromItem,
  toFixedWithConvertToNumber,
} from '../../funcs/calculateCellValue';
import {
  ColorsGroupCellRender, LastUpdateGroupCellRender, StatusGroupCellRender,
  TextIconGroupCellRender,
} from '../../columns/groups';
import { isUndefinedOrNull, prefixString } from '../../../../utils/stringUtils';
import { getStringFromBoolean } from '../../funcs/utils';
import { translateDeviceModel } from '../../funcs/sharedCalculateCellValue';

export default {
  calculateValues: {
    action: ({ actionMenu, innerClasses, selectedObjects }) => ({
      name: 'globalActions',
      caption: 'globalActions',
      allowExporting: false,
      allowFiltering: false,
      allowGrouping: false,
      width: 40,
      headerCellComponent: () => <ActionsMenuHeaderCellComponent innerClasses={innerClasses} onMenuClick={actionMenu.openActionMenu} selectedObjects={selectedObjects} />,
      cellComponent: ({ data }) => <ActionsMenuRender dataset={data} innerClasses={innerClasses} onMenuClick={actionMenu.openActionMenu} />,
    }),
    deviceName: {
      withHighlightAndTracking: ({ innerClasses, chosenDevice }) => ({
        name: 'deviceNameWithHighlightAndTracking',
        dataField: 'name',
        caption: 'deviceName',
        cellComponent: ({ data }) => <NameCellRender chosenDevice={chosenDevice} dataset={data} innerClasses={innerClasses} />,
      }),
    },
    deviceStatus: ({ deviceListClasses, t }) => ({
      name: 'deviceStatus',
      dataField: 'status',
      caption: 'deviceStatus',
      width: 27,
      cellComponent: ({ data }) => <StatusCellRender dataset={data} deviceListClasses={deviceListClasses} />,
      groupCellComponent: ({ data }) => <StatusGroupCellRender dataset={data} deviceListClasses={deviceListClasses} />,
      calculateCellValue: (item) => translateDeviceStatusFromItem(item, t),
      calculateGroupValue: (item) => translateDeviceStatus(item, t),
      calculateDisplayValue: (item) => translateDeviceStatusFromItem(item, t),
    }),
    lastUpdateShort: ({ now, t }) => ({
      name: 'lastUpdateShort',
      dataField: 'lastUpdate',
      dataType: 'datetime',
      caption: 'lastUpdateShortTable',
      allowSorting: true,
      allowFiltering: false,
      allowGrouping: false,
      width: 60,
      cellComponent: ({ data }) => <LastUpdateCellRender dataset={data} now={now} t={t} />,
      groupCellComponent: ({ data }) => <LastUpdateGroupCellRender dataset={data} now={now} t={t} />,
      calculateCellValue: (item) => getShortDateTime(item),
    }),
    lastUpdate: () => ({
      name: 'lastUpdate',
      dataField: 'lastUpdate',
      dataType: 'datetime',
      caption: 'deviceLastUpdate',
      visible: false,
      customizeText: (text) => getDateTime(text?.value),
    }),
    deviceIcon: {
      icon: ({ t }) => ({
        name: 'deviceIcon',
        dataField: 'icon',
        caption: 'deviceIcon',
        width: 40,
        cellComponent: ({ data }) => <IconCellRender dataset={data} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(item, t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(item, t),
        calculateDisplayValue: (item) => getTextFromIcon(item, t),
      }),
      iconWithText: ({ t }) => ({
        name: 'deviceIconWithText',
        dataField: 'icon',
        caption: 'deviceIcon',
        cellComponent: ({ data }) => <TextIconCellRender dataset={data} t={t} />,
        groupCellComponent: ({ data }) => <TextIconGroupCellRender dataset={data} />,
        calculateCellValue: (item) => getTextFromIcon(item, t),
        calculateGroupValue: (item) => getTextFromIconWithSeparator(item, t),
        calculateDisplayValue: (item) => getTextFromIcon(item, t),
      }),
    },
    deviceColor: ({ theme }) => ({
      name: 'attributes.color',
      dataField: 'attributes.color',
      caption: 'sharedColor',
      width: 40,
      cellComponent: ({ data }) => <ColorsCellRender dataset={data} theme={theme} />,
      groupCellComponent: ({ data }) => <ColorsGroupCellRender dataset={data} theme={theme} />,
      calculateCellValue: (item) => getTextForIconOrDefaultValue(item, theme),
    }),
    deviceConnectionStatus: {
      text: ({ t }) => ({
        name: 'deviceConnectionStatusText',
        dataField: 'attributes.categoryParams.connectionStatus',
        caption: 'deviceConnectionStatus',
        calculateCellValue: (item) => translateConnectionStatusFromItem(item, t),
      }),
      iconFromPositions: ({ t, positions, theme }) => ({
        name: 'deviceConnectionStatusIconFromPositions',
        dataField: 'attributes.categoryParams.connectionStatus',
        caption: 'deviceConnectionStatus',
        width: 40,
        cellComponent: ({ data }) => <ColorConnectionCellRender dataset={data} positions={positions} theme={theme} />,
        calculateCellValue: (item) => translateConnectionStatus(item, t, positions),
      }),
    },
    category: ({ t }) => ({
      name: 'category',
      dataField: 'category',
      caption: 'deviceCategory',
      calculateCellValue: (item) => getCategoryName(item, t),
    }),
    notificationType: ({ t }) => ({
      name: 'notificationType',
      dataField: 'type',
      caption: 'notificationType',
      customizeText: (text) => t(prefixString('event', text?.value)),
    }),
    isEnableNotificationForAllDevices: ({ t }) => ({
      name: 'isEnableNotificationForAllDevices',
      dataField: 'always',
      dataType: 'string',
      caption: 'notificationAlways',
      calculateCellValue: (item) => getStringFromBoolean(item?.always, t),
    }),
    notificators: ({ t }) => ({
      name: 'notificators',
      dataField: 'notificators',
      caption: 'notificationNotificators',
      cellComponent: ({ data }) => <NotificatorLinksRender dataset={data} t={t} />,
      calculateCellValue: (item) => concateStringsFromNotificator(item, t),
    }),
    isAdmin: ({ t }) => ({
      name: 'isAdministrator',
      dataField: 'administrator',
      dataType: 'string',
      caption: 'userAdmin',
      calculateCellValue: (item) => getStringFromBoolean(item?.administrator, t),
    }),
    isAutoregistration: ({ t, registerUnknownDefaultGroupId }) => ({
      name: 'isAutoregistration',
      caption: 'autoregistrationTitle',
      dataType: 'string',
      allowFiltering: true,
      allowSorting: true,
      allowGrouping: true,
      cellComponent: ({ data }) => <AutoregistrationIconCellRender data={data} registerUnknownDefaultGroupId={registerUnknownDefaultGroupId} />,
      calculateCellValue: (item) => getStringFromBoolean(item?.id === registerUnknownDefaultGroupId, t),
    }),
    deviceModel: ({ t }) => ({
      name: 'deviceModel',
      dataField: 'model',
      caption: 'deviceModel',
      calculateCellValue: (item) => translateDeviceModel(t, item?.model),
    }),
  },

  base: {
    groupName: {
      name: 'groupName',
      dataType: 'string',
      caption: 'deviceGroup',
    },
    sourceId: {
      name: 'sourceId',
      dataType: 'string',
      caption: 'deviceSource',
    },
  },

  common: {
    id: { dataField: 'id', caption: 'deviceIdentifier', visible: false },
    uniqueId: { dataField: 'uniqueId', caption: 'deviceIdentifier', visible: false },
    name: { dataField: 'name', caption: 'deviceName' },
    address: { dataField: 'address', caption: 'reportAddress' },
    latitude: {
      name: 'latitude',
      dataField: 'latitude',
      caption: 'reportLatitude',
      calculateCellValue: (item) => toFixedNumber(item?.latitude),
    },
    longitude: {
      name: 'longitude',
      dataField: 'longitude',
      caption: 'reportLongitude',
      calculateCellValue: (item) => toFixedNumber(item?.longitude),
    },
    speed: {
      name: 'speed',
      dataField: 'speed',
      caption: 'positionSpeed',
      dataType: 'number',
      alignment: 'left',
      calculateCellValue: (item) => toFixedNumber(item?.speed),
    },
    course: {
      name: 'course',
      dataField: 'course',
      caption: 'positionCourse',
      dataType: 'number',
      alignment: 'left',
      calculateCellValue: (item) => {
        if (isUndefinedOrNull(item?.course) || item?.course === -1) {
          return '';
        }
        return toFixedWithConvertToNumber(item.course);
      },
      customizeText: (text) => {
        if (isUndefinedOrNull(text.value) || text.value === '') {
          return '';
        }
        return `${text.value}°`;
      },
      // cellComponent: ({ data }) => <CustomizeCourseRender dataset={data} />,
    },
    login: { dataField: 'login', caption: 'userLogin' },
    email: { dataField: 'email', caption: 'userEmail' },

    attributes: {
      description: {
        dataField: 'attributes.description',
        caption: 'sharedDescription',
      },

      categoryParams: {
        sector: {
          dataField: 'attributes.categoryParams.sector',
          caption: 'positionSector',
          dataType: 'number',
          alignment: 'left',
        },
        centerFrequency: {
          dataField: 'attributes.categoryParams.centerFrequency',
          caption: 'deviceСenter_frequency_hz',
          dataType: 'number',
          alignment: 'left',
        },
        bandwidth: {
          dataField: 'attributes.categoryParams.bandwidth',
          caption: 'deviceBandwidth_hz',
          dataType: 'number',
          alignment: 'left',
        },
        averageSnr: {
          dataField: 'attributes.categoryParams.averageSnr',
          caption: 'positionAverageSnr',
          dataType: 'number',
          alignment: 'left',
          calculateCellValue: (item) => toFixedNumber(item.attributes?.categoryParams?.averageSnr),
        },
        peleng: {
          dataField: 'attributes.categoryParams.peleng',
          caption: 'positionPeleng',
          dataType: 'number',
          alignment: 'left',
          calculateCellValue: (item) => (item.attributes?.categoryParams?.peleng ? toFixedNumber(item.attributes?.categoryParams?.peleng) : ''),
          customizeText: (text) => {
            if (isUndefinedOrNull(text.value) || text.value === '') {
              return '';
            }
            return `${text.value}°`;
          },
        },
        height: {
          dataField: 'attributes.categoryParams.height',
          caption: 'globalHeight',
          dataType: 'number',
          alignment: 'left',
        },
        elevationAngle: {
          dataField: 'attributes.categoryParams.elevationAngle',
          caption: 'globalPlaceAngle',
          dataType: 'number',
          alignment: 'left',
        },
        dist: {
          dataField: 'attributes.categoryParams.dist',
          caption: 'positionDist',
          dataType: 'number',
          alignment: 'left',
        },
        zone: {
          dataField: 'attributes.categoryParams.zone',
          caption: 'positionZone',
        },
        comment: {
          dataField: 'attributes.categoryParams.comment',
          caption: 'positionComment',
        },
      },
    },
  },

  serverField: {
    serverTime: {
      dataField: 'serverTime',
      dataType: 'datetime',
      caption: 'reportServerTime',
      customizeText: (text) => getDateTime(text.value),
    },
    fixTime: {
      dataField: 'fixTime',
      dataType: 'datetime',
      caption: 'reportFixTime',
      customizeText: (text) => getDateTime(text.value),
    },
  },
};
