/* eslint-disable object-curly-newline */
import { getSourceNameFromDevicesObject } from '../../funcs/calculateCellValue';
import presetsColumns from '../shared/presetsColumns';
import baseProperties from '../shared/baseProperties';
import reportsSharedFields from './reportsSharedColumns';

export default ({ devicesObject, groups, sources, t, theme, devices }) => [
  ...reportsSharedFields.calculateValues.shared({ devicesObject, groups, t, theme, devices }),
  {
    ...presetsColumns.base.sourceId,
    ...baseProperties,
    calculateCellValue: (item) => getSourceNameFromDevicesObject(item, sources, devicesObject, devices),
  },
  ...reportsSharedFields.serverField,
  presetsColumns.common.speed,
  presetsColumns.common.latitude,
  presetsColumns.common.longitude,
  presetsColumns.common.address,
  presetsColumns.common.attributes.categoryParams.averageSnr,
  presetsColumns.common.attributes.categoryParams.bandwidth,
  presetsColumns.common.attributes.categoryParams.centerFrequency,
  presetsColumns.common.attributes.categoryParams.comment,
  presetsColumns.common.attributes.categoryParams.dist,
  presetsColumns.common.attributes.categoryParams.peleng,
  presetsColumns.common.course,
  presetsColumns.common.attributes.categoryParams.sector,
  presetsColumns.common.attributes.categoryParams.zone,
  presetsColumns.common.attributes.categoryParams.height,
  presetsColumns.common.attributes.categoryParams.elevationAngle,
];
