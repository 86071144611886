import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper, Slider, Typography, makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { getExtraMapId } from '../utils/formatter';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    width: 'max-content',
    opacity: 0.8,
  },
  title: {
    display: 'flex',
  },
  text: {
    margin: 'auto',
  },
  formControl: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: 110,
  },
  space: {
    marginLeft: 5,
  },
  sliderBlock: {
    maxWidth: '300px', width: '300px', marginLeft: 20, marginRight: 20,
  },
}));

const OpacityMapSliders = ({ opacityMaps, setOpacityMaps, setOpacityMapsOpen }) => {
  const classes = useStyles();
  const t = useTranslation();
  const extraMaps = useSelector((state) => state.session.server.attributes.extraMaps ?? []);

  const handleClose = () => {
    setOpacityMapsOpen(false);
  };

  const handleSlider = (newValue, mapId) => {
    const extraMapId = getExtraMapId(mapId);
    setOpacityMaps({ ...opacityMaps, [extraMapId]: { ...opacityMaps[extraMapId], opacity: newValue } });
  };

  const handleCheckbox = (e, mapId) => {
    const extraMapId = getExtraMapId(mapId);
    setOpacityMaps({ ...opacityMaps, [extraMapId]: { ...opacityMaps[extraMapId], s: e.target.checked } });
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.title}>
        <Typography className={classes.text}>
          {t('opacityMapsTitle')}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {extraMaps.map((map) => (
        <div key={map.id} className={classes.sliderBlock}>
          <FormControlLabel
            control={<Checkbox checked={opacityMaps[getExtraMapId(map.id)]?.s ?? false} size="small" onClick={(e) => handleCheckbox(e, map.id)} />}
            label={map.name}
          />
          <Slider
            value={opacityMaps[getExtraMapId(map.id)]?.opacity ?? 0}
            onChange={(_, newValue) => handleSlider(newValue, map.id)}
            min={0}
            max={1}
            step={0.01}
          />
        </div>
      ))}
    </Paper>
  );
};

export default OpacityMapSliders;
