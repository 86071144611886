import {
  memo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { map } from './Map';
import { createGeojsonPath, pathDataFromPositions } from './funcs/pathFuncs';

const ReplayPathMap = ({
  positions, tail, matching, devices, stateSyncDevices, stateSyncPositions,
}) => {
  const id = `path${matching ? '_matching' : ''}`;
  // const [colorsDevices, setColorsDevices] = useState({});

  const createCollection = (positions) => {
    const result = {};
    Object.keys(positions).forEach((id) => {
      const clearPosition = [];
      let lastPosition;
      let partPath = [];
      positions[id].forEach((position) => {
        if (lastPosition) {
          if (new Date(position.fixTime).getTime() - new Date(lastPosition.fixTime).getTime() > 3600000) {
            clearPosition.push(partPath);
            clearPosition.push({ timeGap: [lastPosition, position] });
            partPath = [];
          }
        }
        partPath.push(position);
        lastPosition = position;
      });
      if (partPath.length) {
        clearPosition.push(partPath);
      }
      result[id] = clearPosition;
    });
    return result;
  };

  // const getColors = () => {
  //   const colorsDevices = {};
  //   Object.values(devices)
  //     .forEach((device) => colorsDevices[device.id] = device.attributes.color);
  //   return colorsDevices;
  // };

  // useEffect(() => {
  //   setColorsDevices(getColors());
  // }, [stateSyncDevices]);

  useEffect(() => createGeojsonPath(id), []);

  useEffect(() => {
    const collectedPositions = tail ? createCollection(positions) : positions;
    map.getSource(id).setData(pathDataFromPositions(
      collectedPositions,
      devices,
    ));
  }, [stateSyncPositions ?? positions]);

  return null;
};

export default memo(ReplayPathMap);

ReplayPathMap.propTypes = {
  positions: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
  stateSyncDevices: PropTypes.any,
  tail: PropTypes.bool,
  matching: PropTypes.bool,
};
