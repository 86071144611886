import React, { useEffect, useState } from 'react';
import {
  makeStyles, Paper, Tooltip, Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { devicesActions } from '../../store';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(32),
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
  eye: {
    color: '#E32636',
    animation: '$color 10s infinite alternate',
  },
  '@keyframes color': {
    '50%': {
      color: '#ebebeb',
    },
  },
  trackingModeOn: {
    animation: '$fadeIn .2s ease-in-out',
    position: 'fixed',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    maxWidth: 220,
    zIndex: 1310,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  trackingModeOff: {
    animation: '$fadeOut 2.0s ease-in-out',
    position: 'fixed',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -30%)',
    maxWidth: 220,
    zIndex: 1310,
    backgroundColor: 'rgba(255,255,255,0.8)',
    opacity: 0,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  textTrackingMode: {
    margin: theme.spacing(2),
    color: '#f55656',
    fontWeight: 'bold',
  },
}));
const TrackingModeOn = ({ deviceSelected }) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [trackingMode, setTrackingMode] = useState(false);
  const [exit, setExit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setExit(false);
    setTrackingMode(true);
  }, [deviceSelected]);

  useEffect(() => {
    if (trackingMode) {
      const exitTimeout = setTimeout(() => {
        setExit(true);
      }, 3000);
      const trackingModeTimeout = setTimeout(() => {
        setTrackingMode(false);
      }, 5000);
      return (() => {
        clearTimeout(exitTimeout);
        clearTimeout(trackingModeTimeout);
      });
    }
    return (() => null);
  }, [trackingMode]);

  return (
    <>
      <Paper className={classes.trackingMode} elevation={3}>
        <Tooltip title={t('trackingMode')}>
          <Avatar
            onClick={() => dispatch(devicesActions.deselect(null))}
            className={classes.avatar}
          >
            <RemoveRedEyeIcon className={classes.eye} />
          </Avatar>
        </Tooltip>
      </Paper>
      {!!(trackingMode && isTablet) && (
        <Paper elevation={3} className={!exit ? classes.trackingModeOn : classes.trackingModeOff}>
          <Typography align="center" className={classes.textTrackingMode}>
            {t('trackingMode')}
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default TrackingModeOn;
