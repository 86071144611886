/* eslint-disable object-curly-newline */

import baseProperties from '../shared/baseProperties';
import presetsColumns from '../shared/presetsColumns';
import settingsSharedFields from './settingsSharedColumns';

export default ({ t, theme }) => [
  ...settingsSharedFields.common,
  presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
  {
    ...presetsColumns.calculateValues.deviceColor({ theme }),
    ...baseProperties,
  },
];
