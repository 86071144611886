import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const InsideVariablesController = () => {
  const websiteSettings = useSelector((state) => state.session);

  useEffect(() => {
    setTimeout(() => {
      document.title = websiteSettings.server?.attributes?.websiteName ?? '';
    }, 50);
  }, [websiteSettings]);

  return null;
};

export default InsideVariablesController;
