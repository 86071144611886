import React from 'react';
import { Column } from 'devextreme-react/data-grid';
import { useTranslation } from '../../LocalizationProvider';
import { isUndefinedOrNull } from '../../../utils/stringUtils';

export default (columns) => {
  const t = useTranslation();
  const columnsElements = [];
  let count = 1;

  const getKey = () => count++;

  for (const column of columns) {
    const isOnlyCalculateCellValueDefined = !isUndefinedOrNull(column.calculateCellValue) && isUndefinedOrNull(column.calculateGroupValue);
    const isEnabledGrouping = isUndefinedOrNull(column.allowGrouping) || column.allowGrouping;

    if (isOnlyCalculateCellValueDefined && isEnabledGrouping) {
      columnsElements.push(
        <Column calculateGroupValue={column.calculateCellValue} key={getKey()} {...column} caption={t(column.caption)} />,
      );
    } else {
      columnsElements.push(
        <Column key={getKey()} {...column} caption={t(column.caption)} />,
      );
    }
  }

  const getColumns = () => columnsElements.map((column) => column);

  return getColumns;
};
