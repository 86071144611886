import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useAttributePreference } from '../../common/utils/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useEffectAsync } from '../../common/utils/reactHelper';
import usePersistedState from '../../common/utils/usePersistedState';
import {
  abortRequest,
  collectReport,
  formatDate, formatDistance, formatMsToTime, formatPosition, formatSpeed, getSortedArray,
} from '../../common/utils/formatter';
import { positionHandler } from '../../common/utils/requestRoute';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import ReplayPathMap from '../../map/ReplayPathMap';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { runLoadingResources } from '../../map/MapControllerDynamic';
import { errorsActions } from '../../store';

const typeReport = 'tripReport';

const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['startTime', 'reportStartTime', 1],
  ['startCoordinates', 'reportStartCoordinates', 0],
  ['startAddress', 'reportStartAddress', 0],
  ['endTime', 'reportEndTime', 1],
  ['endCoordinates', 'reportEndCoordinates', 0],
  ['endAddress', 'reportEndAddress', 0],
  ['distance', 'sharedDistance', 0],
  ['averageSpeed', 'reportAverageSpeed', 0],
  ['maxSpeed', 'reportMaximumSpeed', 0],
  ['duration', 'reportDuration', 0],
];

const typesSorting = {
  deviceId: (a, b) => getSortedArray(a.deviceId, b.deviceId),
  deviceIdReverse: (a, b) => getSortedArray(a.deviceId, b.deviceId, true),
  group: (a, b) => getSortedArray(a.groupId, b.groupId),
  groupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  startTime: (a, b) => getSortedArray(a.startTime, b.startTime),
  startTimeReverse: (a, b) => getSortedArray(a.startTime, b.startTime, true),
  endTime: (a, b) => getSortedArray(a.endTime, b.endTime),
  endTimeReverse: (a, b) => getSortedArray(a.endTime, b.endTime, true),
};

const TripReportPage = () => {
  const t = useTranslation();
  const classes = useReportStyles();

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [route, setRoute] = useState(null);
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});

  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [page, setPage] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  useEffectAsync(async () => {
    if (selectedItem) {
      const query = new URLSearchParams({
        deviceId: selectedItem.deviceId,
        from: selectedItem.startTime,
        to: selectedItem.endTime,
      });
      const response = await fetch(`/api/reports/route?${query.toString()}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) {
        const clearResponsePath = [];
        positionHandler(await response.json(), clearResponsePath, true);
        setRoute({ [selectedItem.deviceId]: clearResponsePath });
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    } else {
      setRoute(null);
    }
  }, [selectedItem]);

  const handleSubmit = (deviceId, from, to, headers) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    setProgress(1);
    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        deviceId.forEach((it) => query.append('deviceId', it));
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        const response = await fetch(`/api/reports/trips?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport, /,{"deviceId"/);
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
      }
    };
    apiCall();
    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'distance':
        return formatDistance(item[key], distanceUnit, t);
      case 'averageSpeed':
      case 'maxSpeed':
        return formatSpeed(item[key], speedUnit, t);
      case 'duration':
        return formatMsToTime(item[key], t);
      case 'startCoordinates':
        return `${formatPosition(item.startLat, 'latitude', t)}, ${formatPosition(item.startLon, 'longitude', t)}`;
      case 'endCoordinates':
        return `${formatPosition(item.endLat, 'latitude', t)}, ${formatPosition(item.endLon, 'longitude', t)}`;
      default:
        return item[key];
    }
  };

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);

            const resultObj = {};
            result.forEach((item) => resultObj[item.id] = item);
            setDevicesObject(resultObj);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => runLoadingResources(), []);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="startTime"
      formatValue={formatValue}
      page={page}
      setPage={setPage}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      typesSorting={typesSorting}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      columnsArrayFiltered={columnsArrayFiltered}
      breadcrumbs={['reportTitle', 'reportTrips']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      route={route}
      countProgress={countProgress}
      progressReport={progressReport}
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            {route && <ReplayPathMap positions={route} devices={devicesObject} />}
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default TripReportPage;
