/* eslint-disable object-curly-newline */

import { getGroupName } from '../../funcs/calculateCellValue';
import presetsColumns from '../shared/presetsColumns';
import baseProperties from '../shared/baseProperties';

export default ({ t, groups, theme }) => [
  presetsColumns.common.uniqueId,
  presetsColumns.common.name,
  {
    ...presetsColumns.calculateValues.lastUpdate(),
    ...baseProperties,
  },
  presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
  {
    ...presetsColumns.base.groupName,
    ...baseProperties,
    calculateCellValue: (item) => getGroupName(item, groups),
    calculateGroupValue: (item) => getGroupName(item, groups),
  },
  {
    ...presetsColumns.calculateValues.deviceColor({ theme }),
    ...baseProperties,
  },
  presetsColumns.calculateValues.category({ t }),
];
