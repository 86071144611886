import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import useDebounceWithCancel from "../hooks/useDebounceWithCancel";

const ControlledTextField = ({initValue, onChange, onValidate, isValid, ...props}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const delayedValidation = useDebounceWithCancel((value) => {
    if (isValid) {
      setError(!isValid(value))
    }
  })
  const delayedOnChange = useDebounceWithCancel((value) => {
    onChange(value)
  })

  useEffect(() => {
    setValue(initValue !== undefined ? initValue : '')
  }, [initValue])

  useEffect(() => {
    if (onValidate) {
      onValidate(error)
    }
  }, [error])

  const onChangeHandler = (value) => {
    setValue(value)
    delayedOnChange(value)
    delayedValidation(value)
  }

  return (
    <TextField
      value={value}
      error={error}
      onChange={(e) => onChangeHandler(e.target.value)}
      {...props}
    />
  );
};

export default ControlledTextField;
