import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import StopIcon from '@material-ui/icons/Stop';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatMsToTime, getStatusColor } from '../../../utils/formatter';
import {
  getColorConnectionStatus, getTextFromIcon, getWorkModeButtonColor,
} from '../funcs/calculateCellValue';
import { prefixString } from '../../../utils/stringUtils';
import {
  CustomizeCourseRenderBase, NameCellRenderBase,
  StateCellRenderBase, VisibleCellRenderBase,
} from './sharedCells';

const CustomizeCourseRender = ({ dataset }) => (
  <CustomizeCourseRenderBase value={dataset.data?.course} />
);

const AutoregistrationIconCellRender = ({ data, registerUnknownDefaultGroupId }) => (
  <>
    {data?.data?.id === registerUnknownDefaultGroupId ? (<CheckCircleOutlineIcon />) : ''}
  </>
);

const ColorsCellRender = ({
  dataset, theme,
}) => (
  <FiberManualRecordIcon style={{ color: (dataset?.data?.color || dataset.data?.attributes?.color) ?? theme.palette.tracks.replay0 }} />
);

const ActionsMenuRender = ({
  dataset, innerClasses, onMenuClick,
}) => (
  <MoreVertIcon className={innerClasses.pointerCursor} color="action" onClick={(e) => onMenuClick(e.currentTarget, dataset.data.id)} />
);

const LastUpdateCellRender = ({
  dataset, now, t,
}) => (
  <>
    {formatMsToTime(now.valueOf() - new Date(dataset.data?.lastUpdate).valueOf(), t)}
  </>
);

const VisibleCellRender = ({
  dataset, closedDevices = [],
}) => {
  const isChosen = closedDevices[dataset.data.id];

  return (
    <VisibleCellRenderBase isChosen={isChosen} />
  );
};

const StatusCellRender = ({
  dataset, deviceListClasses,
}) => (
  <Brightness1Icon className={`${deviceListClasses[getStatusColor(dataset.data.status)]} ${deviceListClasses.brightness}`} />
);

const IconCellRender = ({
  dataset,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset?.data?.icon || 'default'}.svg`} alt="" />
    </Grid>
  </Grid>
);

const TextIconCellRender = ({
  dataset, t,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset.data.icon || 'default'}.svg`} alt="" />
    </Grid>
    <Grid item>
      {getTextFromIcon(dataset.data, t)}
    </Grid>
  </Grid>
);

const NameCellRender = ({
  dataset, innerClasses, chosenDevice,
}) => {
  const isChosen = chosenDevice === dataset.data.id;
  const chosenId = chosenDevice;

  return (
    <NameCellRenderBase dataset={dataset} innerClasses={innerClasses} isChosen={isChosen} chosenId={chosenId} />
  );
};

const TemporaryCellRender = ({
  dataset, handleTemporaryStatusChange, innerClasses, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        checked={dataset.data.temporary ?? false}
        color="secondary"
        onChange={(event, value) => handleTemporaryStatusChange(dataset.data, value)}
      />
    )}
    label={(dataset.data.temporary ?? false) ? t('sharedYes') : t('sharedNo')}
  />
);

const ColorConnectionCellRender = ({
  dataset, theme, positions,
}) => {
  const connectionStatus = positions[dataset.data.id]?.attributes.categoryParams?.connectionStatus;

  return (
    <StopIcon
      style={{
        color: getColorConnectionStatus(connectionStatus, theme),
      }}
    />
  );
};

const ControlModeCellRender = ({
  dataset, handleCategoryParamStatusChange, controlModesStrizh3, theme, t,
}) => (
  <>
    {dataset.data.model === 'strizh3' && (
      <ToggleButtonGroup
        value={dataset.data.attributes.categoryParams.controlMode}
        exclusive
        onChange={(_, value) => {
          if (value) {
            handleCategoryParamStatusChange(value, dataset.data, 'controlMode');
          }
        }}
        size="small"
      >
        {controlModesStrizh3.map((mode) => (
          <ToggleButton
            key={mode}
            style={{
              background: dataset.data.attributes.categoryParams.controlMode === mode ? theme.palette.common.blue : '#ededed',
            }}
            value={mode}
            size="small"
          >
            <Typography variant="caption" noWrap>
              {t(mode)}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )}
  </>
);

const WorkModeCellRender = ({
  dataset, handleCategoryParamStatusChange, t, workModes,
}) => {
  if (dataset?.data?.attributes?.categoryParams?.controlMode !== undefined
    && dataset.data.attributes.categoryParams.controlMode !== 'manual') {
    return (
      <ToggleButton
        size="small"
        value="0"
        style={{
          background: getWorkModeButtonColor(dataset.data, ''),
          cursor: 'default',
        }}
      >
        <Typography variant="caption" noWrap>{t(dataset.data.attributes.categoryParams.controlMode)}</Typography>
      </ToggleButton>
    );
  }

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={dataset.data.attributes.categoryParams.workMode}
      onChange={(_, value) => {
        if (value) {
          handleCategoryParamStatusChange(value, dataset.data, 'workMode');
        }
      }}
    >
      {workModes.map((mode) => (
        <ToggleButton
          key={mode}
          style={{ background: getWorkModeButtonColor(dataset.data, mode) }}
          value={mode}
          disabled={dataset.data.attributes.categoryParams.controlMode !== 'manual'}
        >
          <Typography variant="caption" noWrap>
            {t(mode)}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const StateCellRender = ({
  dataset, t, positions = [],
}) => {
  const positionData = positions[dataset.data.id];
  const description = positionData?.attributes?.categoryParams?.workModeDescription ?? '';
  const workMode = positionData?.attributes?.categoryParams?.workMode ?? '';

  return (
    <StateCellRenderBase dataset={dataset} description={description} t={t} workMode={workMode} />
  );
};

const ShowRadarAreaCellRender = ({
  dataset, innerClasses, handleRadarAreaStatusChange, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        checked={dataset.data.attributes.categoryParams.showRadarArea ?? false}
        color="secondary"
        onChange={(event, value) => handleRadarAreaStatusChange(dataset.data, value)}
      />
    )}
    label={(dataset.data.attributes.categoryParams.showRadarArea ?? false) ? t('sharedYes') : t('sharedNo')}
  />
);

const NotificatorLinksRender = ({
  dataset, t,
}) => {
  if (dataset.data.notificators) {
    return dataset.data.notificators
      .split(/[, ]+/)
      .filter(Boolean)
      .map((it) => (
        it === 'telegram'
          ? (
            <Tooltip title={t('telegramBotLinkTitle')} key={it}>
              <a href="https://t.me/SmartTrackBot" target="_blank" rel="noopener noreferrer">{t(prefixString('notificator', it))}</a>
            </Tooltip>
          )
          : t(prefixString('notificator', it))
      )).reduce((prev, curr) => [prev, ', ', curr]);
  }
  return '';
};

export {
  CustomizeCourseRender,
  ColorsCellRender,
  ActionsMenuRender,
  LastUpdateCellRender,
  VisibleCellRender,
  StatusCellRender,
  IconCellRender,
  TextIconCellRender,
  NameCellRender,
  AutoregistrationIconCellRender,
  TemporaryCellRender,
  ColorConnectionCellRender,
  ControlModeCellRender,
  WorkModeCellRender,
  StateCellRender,
  ShowRadarAreaCellRender,
  NotificatorLinksRender,
};
