import { toTitleCase } from '../../common/utils/formatter';
import { extraLayersActions } from '../../store';

export const updatePoints = (dispatch, name, points) => {
  dispatch(extraLayersActions.refresh({
    name,
    points,
  }));
};

export const updateStatus = (dispatch, title, status) => {
  dispatch(extraLayersActions.switch({
    name: `on${toTitleCase(title)}`,
    status,
  }));
};

const getData = async (dispatch, title, url) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      updatePoints(dispatch, title, await response.json());
    } else {
      updatePoints(dispatch, title, []);
      updateStatus(dispatch, title, 0);
    }
  } catch (e) {
    updatePoints(dispatch, title, []);
    updateStatus(dispatch, title, 0);
    throw Error(e.message);
  }
};

export default async (value, dispatch) => {
  switch (value) {
    case 'flightRadarFlights':
      await getData(dispatch, value, 'http://192.168.133.86:8800/flight-radar/flights');
      break;
    case 'flightRadarAirports':
      await getData(dispatch, value, 'http://192.168.133.86:8800/flight-radar/airports');
      break;
    default:
      break;
  }
};
