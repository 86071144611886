import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import maplibregl from 'maplibre-gl';
import { Provider } from 'react-redux';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { map } from './Map';
import MarkPopup from './MarkPopup';
import store from '../store';
import { getBeforeId } from './funcs/pathFuncs';
import markerStatus from '../common/constants/markerStatus';

const MarkerOneMap = ({
  addMarkerMode, setAddMarkerMode, setNewMarkerCoordinates, markerState, clearMarkerState,
}) => {
  const idPoint = 'markPoint';

  const [newMark, setNewMark] = useState({ type: 'Feature' });
  const theme = useTheme();
  const [popup] = useState(new maplibregl.Popup({
    offset: 25,
    anchor: 'top',
    closeButton: false,
  }));

  const createFeature = () => (
    {
      'marker-size': 'small',
    }
  );

  const addMarker = (e) => {
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      properties: createFeature(),
    };
    setNewMark(feature);
    setAddMarkerMode();
    setNewMarkerCoordinates();
  };

  // Обработка перемещения маркера от компонента TextMarkerCoordinates.
  useEffect(() => {
    if (!markerState.current || !markerState.current.lngLat) return;
    if (markerState.current.actionType === markerStatus.Create) {
      addMarker(markerState.current);
      clearMarkerState();
    }
  }, [markerState.current]);

  const updateCoordinatesText = (e) => {
    setNewMarkerCoordinates(e.lngLat);
  };

  useEffect(() => {
    if (newMark.geometry) {
      const placeholder = document.createElement('div');
      ReactDOM.render(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <MarkPopup
              setMarker={setNewMark}
              popup={popup}
              latitude={newMark.geometry.coordinates[1]}
              longitude={newMark.geometry.coordinates[0]}
            />
          </ThemeProvider>
        </Provider>,
        placeholder,
      );
      popup.setLngLat(newMark.geometry.coordinates)
        .setDOMContent(placeholder)
        .addTo(map);
    }
  }, [newMark]);

  useEffect(() => {
    map.addSource(idPoint, {
      type: 'geojson',
      data: {
        type: 'Feature',
      },
    });

    map.addLayer({
      id: idPoint,
      type: 'symbol',
      source: idPoint,
      layout: {
        'icon-image': `default-${theme.palette.markers.black}`,
      },
    }, getBeforeId(idPoint));
    if (addMarkerMode) {
      map.on('click', addMarker);
    }
    if (addMarkerMode) {
      map.on('mousemove', updateCoordinatesText);
    }

    return () => {
      if (addMarkerMode) {
        map.off('click', addMarker);
      }
      if (addMarkerMode) {
        map.off('mousemove', updateCoordinatesText);
      }
      if (map.getLayer(idPoint)) {
        map.removeLayer(idPoint);
      }
      if (map.getSource(idPoint)) {
        map.removeSource(idPoint);
      }
    };
  }, [addMarkerMode]);

  useEffect(() => {
    map.getSource(idPoint).setData(newMark);
  }, [newMark]);

  return null;
};

export default MarkerOneMap;
