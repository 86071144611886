import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

const CircularProgressWithLabel = ({ value, rotate }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }} style={rotate && { transform: 'rotate(180deg)' }}>
    <CircularProgress variant="determinate" value={value} size={24} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="caption" component="div" style={{ color: '#939597' }}>
        {`${Math.round(value)}%`}
      </Typography>
    </Box>
  </Box>
);

export default CircularProgressWithLabel;
