/* eslint-disable object-curly-newline */

import presetsColumns from '../shared/presetsColumns';

export default ({ t }) => [
  presetsColumns.common.id,
  presetsColumns.common.attributes.description,
  presetsColumns.calculateValues.notificationType({ t }),
  presetsColumns.calculateValues.isEnableNotificationForAllDevices({ t }),
  presetsColumns.calculateValues.notificators({ t }),
];
