import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { LinearProgress } from '@material-ui/core';
import { useEffectAsync } from '../utils/reactHelper';
import SimpleDataGrid from './SimpleDataGrid';
import { getExtensionFromFileName } from '../utils/fileHelper';

const DisplayFileContentByUrl = ({
  path,
}) => {
  const [component, setComponent] = useState(<LinearProgress />);

  useEffectAsync(async () => {
    try {
      const fileExtension = getExtensionFromFileName(path);

      const file = await fetch(path);
      const buffer = await file.arrayBuffer();
      let workbook;

      if (fileExtension === 'csv') {
        const data = new TextDecoder().decode(buffer);
        workbook = XLSX.read(data, { type: 'string' });
      } else {
        workbook = XLSX.read(buffer);
      }

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        // header: 1,
        defval: '',
      });

      setComponent((
        <SimpleDataGrid
          columns={Object.keys(jsonData[0])}
          dataSource={jsonData}
        />
      ));
    } catch (error) {
      console.log(error);
    }
  }, []);

  return component;
};

export default DisplayFileContentByUrl;
