import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { map } from './Map';

const SelectedDeviceMap = () => {
  const mapCenter = useSelector((state) => {
    const deviceId = state.devices.selectedId;
    if (deviceId) {
      const position = state.positions.items[deviceId] || null;
      if (position) {
        return { deviceId, position: [position.longitude, position.latitude] };
      }
    }
    return null;
  });
  const zoom = (useSelector((state) => (state.session.user.zoom === 0 ? state.session.server.zoom : state.session.user.zoom)) || 12);
  const deviceId = useSelector((state) => state.devices.selectedId);
  const position = useSelector((state) => state.positions.items[deviceId] || null);
  const [item, setItem] = useState();

  useEffect(() => {
    if (position) {
      setItem({ position: [position.longitude, position.latitude] });
    } else {
      setItem(null);
    }
  }, [deviceId]);

  useEffect(() => {
    if (!item || (mapCenter && item[0] !== mapCenter.position[0] && item[1] !== mapCenter.position[1])) {
      setItem(mapCenter?.position);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (item) {
      try {
        map.flyTo({ center: item, zoom });
      } catch (e) {
        console.warn(e.message);
      }
    }
  }, [item]);

  return null;
};

export default SelectedDeviceMap;
