import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { formatPosition } from '../../common/utils/formatter';
import scrollStyles from '../../common/theme/scrollStyles';
import MarkerCategoryRows from '../components/MarkerCategoryRows';
import { usePreference } from '../../common/utils/preferences';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: 'calc(100% - 32px)',
    overflow: 'auto',
  },
  scroll: {
    ...scrollStyles(5),
  },
  titleText: {
    fontSize: 12,
  },
  listItem: {
    width: '100%',
  },
  fontSize: {
    fontSize: 14,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(3),
  },
  iconButton: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  content: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

const MarkerBody = ({
  marker,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const coordinateFormat = usePreference('coordinateFormat');

  return (
    <Grid
      style={{ overflowY: 'auto', overflowX: 'hidden' }}
      className={classes.scroll}
      container
      direction="column"
      wrap="nowrap"
      spacing={1}
    >
      <Grid container item>
        <Grid container item direction="column">
          <Grid container item className={classes.listItem} alignItems="center">
            <Grid item xs="auto">
              <Typography className={classes.fontSize}>
                {t('markerName')}
              </Typography>
            </Grid>
            <Grid item xs style={{ textAlign: 'right' }}>
              <Typography className={classes.fontSize}>
                {marker.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid container item direction="column">
          <Grid container item className={classes.listItem} alignItems="center">
            <Grid item xs="auto">
              <Typography className={classes.fontSize}>
                {t('markerTime')}
              </Typography>
            </Grid>
            <Grid item xs style={{ textAlign: 'right' }}>
              <Typography className={classes.fontSize}>
                {formatPosition(marker.markerTime, 'time')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid container item direction="column">
          <Grid container item className={classes.listItem} alignItems="center">
            <Grid item xs="auto">
              <Typography className={classes.fontSize}>
                {t('markerCoordinates')}
              </Typography>
            </Grid>
            <Grid item xs style={{ textAlign: 'right' }}>
              <Typography className={classes.fontSize}>
                {`${formatPosition(marker.latitude, 'latitude', null, null, coordinateFormat)}, ${formatPosition(marker.longitude, 'longitude', null, null, coordinateFormat)}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MarkerCategoryRows categoryParams={marker.attributes.categoryParams} classes={classes} />

      <Grid container item>
        <Grid container item direction="column">
          <Grid container item className={classes.listItem} alignItems="center">
            <Grid item xs="auto">
              <Typography className={classes.fontSize}>
                {`${t('markerDescription')}:`}
              </Typography>
            </Grid>
            <Grid item xs style={{ textAlign: 'right' }}>
              <Typography className={classes.fontSize}>
                {marker.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default MarkerBody;
