import React from 'react';
import { Toolbar, Item } from 'devextreme-react/data-grid';

const TablesToolbar = ({
  deviceStopFunction, onAddNewRow, disableExportTool,
  isMobile, t,
  isShowFiltersInPanelAndHeaders,
  setIsShowFiltersInPanelAndHeaders, setIsShowSettingsForm, setIsShowExportingForm,
}) => {
  const getDisplayingTextForCurrentBreakpoint = (label) => (isMobile ? t(label) : '');

  const openSettingsForm = () => setIsShowSettingsForm(true);
  const openExportForm = () => setIsShowExportingForm(true);

  const handleFilterPanelVisibilityChange = () => setIsShowFiltersInPanelAndHeaders(!isShowFiltersInPanelAndHeaders);

  return (
    <Toolbar visible>
      {deviceStopFunction && (
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxButton"
          options={{
            type: 'danger',
            icon: isMobile && 'remove',
            text: t('globalStopAll'),
            hint: t('globalStopAll'),
            onClick: deviceStopFunction,
          }}
        />
      )}

      {onAddNewRow && (
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxButton"
          options={{
            icon: 'add',
            text: getDisplayingTextForCurrentBreakpoint('sharedAdd'),
            hint: t('sharedAdd'),
            onClick: onAddNewRow,
          }}
        />
      )}

      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        options={{
          type: isShowFiltersInPanelAndHeaders ? 'default' : 'normal',
          text: getDisplayingTextForCurrentBreakpoint('globalFilter'),
          hint: t('globalFilter'),
          icon: 'filter',
          onClick: handleFilterPanelVisibilityChange,
        }}
      />
      {!disableExportTool && (
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxButton"
          options={{
            icon: 'export',
            text: getDisplayingTextForCurrentBreakpoint('globalExport'),
            hint: t('globalExport'),
            onClick: openExportForm,
          }}
        />
      )}
      <Item name="columnChooserButton" />
      <Item name="applyFilterButton" />
      <Item name="groupPanel" />
      {/* <Item
        location="after"
        locateInMenu="auto"
        showText="inMenu"
        widget="dxButton"
        options={{
          icon: 'revert',
          text: getDisplayingTextForCurrentBreakpoint('devextremeResetDefaultSettings'),
          hint: t('devextremeResetDefaultSettings'),
          width: isMobile && 160,
          onClick: openResetForm,
        }}
      /> */}

      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        options={{
          icon: 'preferences',
          text: getDisplayingTextForCurrentBreakpoint('devextremeSettings'),
          hint: t('devextremeSettings'),
          onClick: openSettingsForm,
        }}
      />
    </Toolbar>
  );
};

export default TablesToolbar;
