import moment from 'moment';
import lifetimes from '../../common/static/lifetimes';
import { getCategory } from '../../common/utils/formatter';

export const filterByCategories = (device, category, categoryLifetimes, temporaryLifetime) => {
  if (device.temporary) {
    return moment(device.lastUpdate) > moment().subtract(temporaryLifetime.value, temporaryLifetime.time);
  }
  const categoryLifetime = categoryLifetimes[getCategory(device)] ?? lifetimes.categories[getCategory(device)];
  return moment(device.lastUpdate) > moment().subtract(categoryLifetime.value, categoryLifetime.time);
};

export const filterBySearchName = (device, groups, searchName) => {
  if (searchName) {
    const lowerSearchName = searchName.toLowerCase();
    return device.name.toLowerCase().includes(lowerSearchName)
    || groups[device.groupId]?.name.toLowerCase().includes(searchName.toLowerCase());
  }
  return true;
};

export const filterDevices = (device, category, groups, searchName, categoryLifetimes, temporaryLifetime) => {
  if (!filterByCategories(device, category, categoryLifetimes, temporaryLifetime)) {
    return false;
  }
  return filterBySearchName(device, groups, searchName);
};
