import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const PermissionsBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedPermissions')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={item.registration}
              onChange={(event) => setItem({ ...item, registration: event.target.checked })}
            />
          )}
          label={t('serverRegistration')}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default PermissionsBlock;
