export const reloadResourcesOnSite = () => {
  const urlPattern = /.*\?.*/;
  const links = document.getElementsByTagName('link');
  const scripts = document.getElementsByTagName('script');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    const { href } = link;
    if (urlPattern.test(href)) {
      link.href = `${href}&${Date.now()}`;
    } else {
      link.href = `${href}?${Date.now()}`;
    }
  }
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i];
    const { src } = script;
    if (urlPattern.test(src)) {
      script.src = `${src}&${Date.now()}`;
    } else {
      script.src = `${src}?${Date.now()}`;
    }
  }
};