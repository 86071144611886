import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';
import useStylesForDetectedTable from './common/useStylesForDetectedTable';

/**
 * Настроенный компонент таблицы обнаруженные объекты.
 */
const DetectedObjectsTableView = () => {
  const classesForPerfectTable = useStylesForDetectedTable();
  const getComponent = useComponentsController();

  const settings = {
    classes: classesForPerfectTable,
    small: true,
    isPage: true,
    caption: 'deviceCategoriesDetected_object',
    disableFilterRowTool: false,
    tableName: 'Detected_object_page',
  };

  return (
    <>
      {getComponent(availableComponents.DetectedObjectsTable, settings)}
    </>
  );
};

export default DetectedObjectsTableView;
