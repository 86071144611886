import React from 'react';
import { TEMPORARY_PROPERTY } from '../constants';
import {
  ColorConnectionCellRenderBase, CustomizeCourseRenderBase, NameCellRenderBase, StateCellRenderBase, VisibleCellRenderBase,
} from './sharedCells';
import { parseSettingsFromString } from '../funcs/utils';

export const ColorConnectionCellRenderForTemporaryField = ({
  dataset, theme,
}) => {
  const connectionStatus = dataset.data[TEMPORARY_PROPERTY.ConnectionStatus];

  return (
    <ColorConnectionCellRenderBase connectionStatus={connectionStatus} theme={theme} />
  );
};

export const VisibleOnMapCellRenderForTemporaryField = ({
  dataset,
}) => {
  const isChosen = dataset.data[TEMPORARY_PROPERTY.IsClosed];

  return (
    <VisibleCellRenderBase isChosen={isChosen} />
  );
};

export const NameCellRenderForTemporaryField = ({
  dataset, innerClasses,
}) => {
  const isChosen = dataset.data[TEMPORARY_PROPERTY.IsChosen];
  const chosenId = dataset.data[TEMPORARY_PROPERTY.ChosenId];

  return (
    <NameCellRenderBase dataset={dataset} innerClasses={innerClasses} isChosen={isChosen} chosenId={chosenId} />
  );
};

export const StateCellRenderForTemporaryField = ({
  dataset, t,
}) => {
  const [workMode, description] = parseSettingsFromString(dataset.data[TEMPORARY_PROPERTY.CurrentWorkMode]);

  return (
    <StateCellRenderBase dataset={dataset} description={description} t={t} workMode={workMode} />
  );
};

export const CustomizeCourseRenderForTemporaryField = ({ dataset }) => (
  <CustomizeCourseRenderBase value={dataset.data[TEMPORARY_PROPERTY.Course]} />
);
