export default {
  replay0: '#074eb8',
  replay1: '#c94c4c',
  replay2: '#1d4524',
  replay3: '#6b5b95',
  replay4: '#ff7b25',
  replay5: '#3b3a30',
  replay6: '#618685',
  replay7: '#bc5a45',
  replay8: '#563f46',
  replay9: '#e0876a',
  replay10: '#ababab',
};
