import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, useTheme, Dialog,
  DialogTitle, DialogContent, DialogActions, Button, Avatar,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import SelectField from '../../common/form/SelectField';
import icons from '../../common/static/icons';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  iconDialogContent: {
    maxWidth: 400,
    display: 'flex',
    flexFlow: 'column wrap',
    width: '300px',
  },
}));

const MarkercatPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();

  const [item, setItem] = useState();
  const [markerIconOpen, setMarkerIconOpen] = useState(false);

  const chooseIcon = (icon) => {
    setItem({ ...item, icon });
    setMarkerIconOpen(false);
  };

  useEffect(() => {
    if (item && !item?.color) {
      setItem({ ...item, color: theme.palette.markers.black });
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="icons"
      item={item}
      setItem={setItem}
      disabledSave={!item?.name || !item?.icon || !item?.color}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('settingsMarkercat')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={item.name || ''}
                onChange={(event) => event.target.value.length < 26 && setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
                variant="filled"
              />
              <Button
                onClick={() => setMarkerIconOpen(true)}
                fullWidth
                variant="contained"
                required
                startIcon={(
                  <Avatar style={{ background: '#fff0' }}>
                    <img src={`/images/icon/device/${item.icon || 'default'}.svg`} alt="" />
                  </Avatar>
                )}
              >
                {t('markerIcon')}
              </Button>

              <Dialog
                maxWidth="lg"
                open={markerIconOpen}
                onClose={() => setMarkerIconOpen(false)}
              >
                <DialogTitle>{t('markerIcon')}</DialogTitle>
                <DialogContent className={classes.iconDialogContent}>
                  {icons.map((ic) => (
                    <Avatar
                      key={ic}
                      style={{ background: item.icon === ic ? '#198cffa4' : '#fff', cursor: 'pointer' }}
                      onClick={() => chooseIcon(ic)}
                    >
                      <img src={`/images/icon/device/${ic}.svg`} alt="" />
                    </Avatar>
                  ))}
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setMarkerIconOpen(false)}>{t('closeTitle')}</Button>
                </DialogActions>
              </Dialog>

              <SelectField
                margin="normal"
                value={item.color?.toLowerCase() || ''}
                emptyValue={null}
                onChange={(e) => setItem({ ...item, color: e.target.value.toLowerCase() })}
                keyGetter={(it) => it}
                titleGetter={(it) => <FiberManualRecordIcon style={{ color: it }} />}
                label={t('markerColor')}
                variant="filled"
                data={Object.values(theme.palette.markers)}
                required
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default MarkercatPage;
