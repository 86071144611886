import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonEdit = () => (
  <div>
    <Skeleton animation="wave" height="95px" />
    {[1, 2, 3].map((i) => (
      <Skeleton key={i} style={{ margin: '0 10px' }} animation="wave" height="35px" />
    ))}

    <Skeleton animation="wave" height="95px" />
    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
      <Skeleton key={i} style={{ margin: '0 10px' }} animation="wave" height="35px" />
    ))}
  </div>
);

export default SkeletonEdit;
