import React, {
  useRef,
} from 'react';
import { ToolbarItem } from 'devextreme-react/popup';
import {
  Typography,
} from '@material-ui/core';
import {
  Popup,
} from 'devextreme-react';
import { useTranslation } from '../LocalizationProvider';

const ResetForm = ({
  isShowToDefaultResetForm, setIsShowToDefaultResetForm, devicesTableSettings,
}) => {
  const t = useTranslation();

  const isNeedToResetSettingsToDefault = useRef(false);

  const closeResetForm = () => setIsShowToDefaultResetForm(false);

  const handleResetToDefault = () => {
    if (isNeedToResetSettingsToDefault.current) return;
    isNeedToResetSettingsToDefault.current = true;
    devicesTableSettings.clear();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Popup
      visible={isShowToDefaultResetForm}
      maxWidth={450}
      height="auto"
      position="center"
      showTitle={false}
      closeOnOutsideClick
      shadingColor="rgba(80 80 80 / 10%)"
      dragEnabled={false}
      onHiding={closeResetForm}
    >
      <Typography>{`${t('devextremeResetDefaultSettingsWithWarning')}.`}</Typography>
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        options={{
          text: t('sharedCancel'),
          icon: 'close',
          stylingMode: 'text',
          onClick: closeResetForm,
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: t('devextremeReset'),
          icon: 'revert',
          stylingMode: 'text',
          onClick: handleResetToDefault,
        }}
      />
    </Popup>
  );
};

export default ResetForm;
