import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  devicesActions, sessionActions,
  tailActions,
} from './store';
import { isUndefinedOrNull } from './common/utils/stringUtils';

export const eventName = Object.freeze({
  TrackDevice: 'TrackDevice',
  StopTrackingDevice: 'StopTrackingDevice',
  CloseDevice: 'CloseDevice',
  CloseAllDevices: 'CloseAllDevices',
  UpdateWebsiteSettings: 'UpdateWebsiteSettings',
});

const actions = new Map();

const initializeActions = (dispatch) => {
  // Работа с устройством
  actions.set(eventName.TrackDevice, (device) => {
    dispatch(devicesActions.select(device));
  });
  actions.set(eventName.StopTrackingDevice, () => {
    dispatch(devicesActions.deselect(null));
  });
  actions.set(eventName.CloseDevice, ({ flag, id }) => {
    if (flag) {
      dispatch(devicesActions.hide(id));
      dispatch(tailActions.changeState({ [id]: false }));
    } else {
      dispatch(devicesActions.open(id));
      dispatch(tailActions.changeState({ [id]: true }));
    }
  });
  actions.set(eventName.CloseAllDevices, (newClosedDevices) => {
    dispatch(devicesActions.turnAll(newClosedDevices));
    dispatch(tailActions.changeState(Object.keys(newClosedDevices).reduce((a, v) => ({ ...a, [v]: !newClosedDevices[v] }), {})));
  });

  // Настройки сайта
  actions.set(eventName.UpdateWebsiteSettings, (settings) => {
    dispatch(sessionActions.updateServer(settings));
  });
};

const events = new BroadcastChannel('events');

/**
 * Отправляет сообщение вкладкам.
 * @param {*} eventName Вызывает обработчик соответствующий имени.
 * @param {*} message Любой объект, строка.
 */
export const sendMessage = (eventName, message) => {
  const compressedMessage = JSON.stringify(message);
  events.postMessage({ eventName, message: compressedMessage });
};

/**
 * Возвращает обработчик соответствущий eventName.
 * @returns callback метод.
 */
export const getAction = (eventName) => actions.get(eventName);

/**
 * Отправляет сообщение вкладкам и redux.
 * @param {*} eventName Названием события для обработки сообщения.
 * @param {*} message Любой объект, строка.
 */
export const notify = (eventName, message) => {
  getAction(eventName)(message);
  sendMessage(eventName, message);
};

/**
 * Предоставляет механизм отправки уведомлений другим вкладкам.
 */
const NotificationSyncController = () => {
  const dispatch = useDispatch();

  const unpackMessageAndCallMethod = (eventName, compressedMessage) => {
    const message = typeof compressedMessage === 'string'
      ? JSON.parse(compressedMessage) : compressedMessage;
    getAction(eventName)(message);
  };

  events.onmessage = (event) => {
    const { eventName, message } = event?.data || null;
    if (isUndefinedOrNull(eventName) || !actions.has(eventName)) return;
    unpackMessageAndCallMethod(eventName, message);
  };

  useEffect(() => {
    initializeActions(dispatch);
    return () => events.close();
  }, []);

  return null;
};

export default NotificationSyncController;
