import React, { useRef } from 'react';
import Box, {
  Item,
} from 'devextreme-react/box';
import {
  Button,
} from '@material-ui/core';
import { Button as DxButton } from 'devextreme-react/button';
import {
  Popup, RadioGroup,
} from 'devextreme-react';
import { useTranslation } from '../LocalizationProvider';
import { FILTER_SAVING, NO, YES } from './constants';
import { getPropertyOrDefaultValue, isTextEqualsYes } from './funcs/utils';
import { isUndefinedOrNull } from '../../utils/stringUtils';

const SettingsForm = ({
  isShowSettingsForm, setIsShowSettingsForm, isMobile,
  openResetForm, tableSettings, isSaveSettings, enableSavingFilters,
  dataGridRef, clearFiltersIfRequired, disableSavingFiltersSwitch,
}) => {
  const t = useTranslation();

  const getValue = () => {
    const settings = tableSettings.get();

    if (enableSavingFilters && isUndefinedOrNull(settings)) return YES;

    return getPropertyOrDefaultValue(settings, FILTER_SAVING, NO);
  };

  const exportDataSettings = useRef({
    availableVariants: [YES, NO],
    selectedValue: getValue(),
  });

  const closeSettingsForm = () => setIsShowSettingsForm(false);

  const processAndSaveExportingFile = () => {
    isSaveSettings.current = isTextEqualsYes(exportDataSettings.current.selectedValue);

    const state = dataGridRef.current.instance.state();
    state[FILTER_SAVING] = exportDataSettings.current.selectedValue;
    clearFiltersIfRequired(state);
    tableSettings.save(state);

    closeSettingsForm();
  };

  const handleChangeSaveFilters = (value) => exportDataSettings.current = { ...exportDataSettings.current, selectedValue: value };

  return (
    <Popup
      visible={isShowSettingsForm}
      maxWidth={500}
      fullScreen={isMobile}
      height="auto"
      position="center"
      title={t('devextremeSettings')}
      showTitle
      shadingColor="rgba(80 80 80 / 10%)"
      dragEnabled={false}
      closeOnOutsideClick
      onHiding={closeSettingsForm}
    >
      <Box>
        <Item ratio={2}>
          <div>{t('devextremeSaveCurrentFilters')}</div>
        </Item>
        <Item ratio={1}>
          <RadioGroup
            disabled={disableSavingFiltersSwitch}
            items={exportDataSettings.current.availableVariants}
            defaultValue={exportDataSettings.current.selectedValue ?? exportDataSettings.current.availableVariants[1]}
            itemRender={(text) => t(`shared${text}`)}
            layout="horizontal"
            onValueChange={handleChangeSaveFilters}
          />
        </Item>
      </Box>
      <Box style={{ marginTop: 20 }}>
        <Item ratio={1}>
          <DxButton
            variant="outlined"
            color="default"
            icon="revert"
            text={t('devextremeResetDefaultSettings')}
            onClick={openResetForm}
          />
        </Item>
      </Box>
      <Box style={{ marginTop: 20 }} align="space-between">
        <Item ratio={1}>
          <Button variant="text" color="default" onClick={closeSettingsForm}>{t('closeTitle')}</Button>
        </Item>
        <Item ratio={1} />
        <Item ratio={1}>
          <Button variant="outlined" color="default" onClick={processAndSaveExportingFile}>
            {t('sharedSave')}
          </Button>
        </Item>
      </Box>
    </Popup>
  );
};

export default SettingsForm;
