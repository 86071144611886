import React from 'react';
import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { MoreVertOutlined } from '@material-ui/icons';
import SelectField from '../../../form/SelectField';

const TemporaryHeaderRender = ({
  handleTemporaryStatusChangeFromHeader, statusCheckboxChecker, innerClasses, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        color="secondary"
        style={statusCheckboxChecker.getStyles()}
        checked={statusCheckboxChecker.notEmpty()}
        onChange={(event, value) => handleTemporaryStatusChangeFromHeader(value)}
      />
    )}
    label={t('deviceTemporary')}
  />
);

const ShowRadarAreaHeaderRender = ({
  innerClasses, statusCheckboxChecker, handleRadarAreaStatusChangeFromHeader, t,
}) => (
  <FormControlLabel
    className={innerClasses.formControlLabel}
    control={(
      <Checkbox
        color="secondary"
        style={statusCheckboxChecker.getStyles()}
        checked={statusCheckboxChecker.notEmpty()}
        onChange={(event, value) => handleRadarAreaStatusChangeFromHeader(value)}
      />
    )}
    label={t('deviceShowRadarArea')}
  />
);

const ControlModeHeaderRender = ({
  handleCategoryParamStatusChangeFromHeader, controlModesStrizh3, t,
}) => (
  <div style={{ width: 260 }}>
    <SelectField
      margin="none"
      value=""
      emptyValue={null}
      onChange={(e) => handleCategoryParamStatusChangeFromHeader(e.target.value, 'controlMode')}
      keyGetter={(it) => it}
      data={controlModesStrizh3}
      titleGetter={(it) => t(it)}
      label={t('deviceControlMode')}
      variant="filled"
      fullWidth
      size="small"
    />
  </div>
);

const WorkModeHeaderRender = ({
  workModes, handleCategoryParamStatusChangeFromHeader, t,
}) => (
  <div style={{ width: 260 }}>
    <SelectField
      margin="none"
      value=""
      emptyValue={null}
      onChange={(e) => handleCategoryParamStatusChangeFromHeader(e.target.value, 'workMode')}
      keyGetter={(it) => it}
      data={workModes}
      titleGetter={(it) => t(it)}
      label={t('deviceWorkMode')}
      variant="filled"
      fullWidth
      size="small"
    />
  </div>
);

const ActionsMenuHeaderCellComponent = ({
  innerClasses, onMenuClick, selectedObjects,
}) => (
  <MoreVertOutlined
    className={innerClasses.pointerCursor}
    color="action"
    onClick={(event) => onMenuClick(event.currentTarget, selectedObjects.current)}
  />
);

const CustomizeCourseHeaderCellComponent = ({ dataset }) => (
  <>
    {dataset.column.caption}
    &#176;
  </>
);

export {
  TemporaryHeaderRender,
  ShowRadarAreaHeaderRender,
  ControlModeHeaderRender,
  WorkModeHeaderRender,
  ActionsMenuHeaderCellComponent,
  CustomizeCourseHeaderCellComponent,
};
