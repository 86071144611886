import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default () => {
  const t = useTranslation();
  const theme = useTheme();

  const groups = useSelector((state) => state.groups.items);

  return columnTemplates.settingsFields.devicesSettings({
    t, groups, theme,
  });
};
