import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '../../LocalizationProvider';
import ModalEdit from '../../ModalEdit';
import { useEffectAsync } from '../../../utils/reactHelper';
import logout from '../../../utils/logout';
import { savePermissions } from '../../../utils/savePermisionsFuncs';
import { deviceColor } from '../../../../map/funcs/positionSource';
import DeviceEdit from '../DeviceEdit';
import { getCategory } from '../../../utils/formatter';
import { getIsAdmin } from '../../../utils/selectors';
import validateItem from '../validateItem';

const ENDPOINT = 'devices';

const PositionDataEdit = ({
  open, onClose, deviceProperties,
}) => {
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const groups = useSelector((state) => state.groups.items);

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [errors, setErrors] = useState({});
  const [initState, setInitState] = useState({ category: 'device', model: 'default' });

  useEffectAsync(async () => {
    if (deviceProperties.deviceId) {
      const response = await fetch(`/api/${ENDPOINT}/${deviceProperties.deviceId}`);
      if (response.ok) {
        setInitState(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    } else {
      setInitState({});
    }
  }, [deviceProperties.deviceId]);

  const [progress, setProgress] = useState(0);

  const handleSave = async () => {
    setProgress(1);
    let url = `/api/${ENDPOINT}`;
    if (deviceProperties.deviceId) {
      url += `/${deviceProperties.deviceId}`;
    }
    const response = await fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(validateItem(item)),
    });

    if (response.ok) {
      try {
        const bodyResponse = await response.json();

        // Добавление свойств к устройству после успешного запроса на редактирование.
        deviceProperties.name = bodyResponse.name;
        deviceProperties.group = groups[bodyResponse.groupId]?.name;
        deviceProperties.deviceType = getCategory(bodyResponse);
        deviceProperties.status = bodyResponse.status;
        deviceProperties.icon = bodyResponse.icon;
        deviceProperties.color = deviceColor(bodyResponse);
      } catch (error) {
        console.warn('Не удалось задать свойства устройства.', error);
      }

      if (permissions) {
        Object.values(permissions).forEach((permission) => savePermissions(permission, false));
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
      setProgress(0);
    }

    setProgress(0);
    onClose();
  };

  return (
    <ModalEdit
      title={t('PositionDataEditTitle')}
      styles={
        {
          root: {
            margin: '10px 0',
          },
          data: {
            padding: '10px 0',
          },
        }
      }
      open={open}
      onAccept={handleSave}
      onClose={onClose}
      disabledSave={isAdmin && !(item && item.name && item.uniqueId && !Object.values(errors).some((v) => v))}
      progress={progress}
    >
      <DeviceEdit
        item={item}
        setItem={setItem}
        errors={errors}
        setErrors={setErrors}
        initState={initState}
        permissions={permissions}
        setPermissions={setPermissions}
        isNew={false}
      />
    </ModalEdit>
  );
};

export default PositionDataEdit;
