import { useEffect } from 'react';
import { map } from '../Map';
import { getBeforeId } from '../funcs/pathFuncs';
import { convertToConfig } from '../MapControllerDynamic';

const PointsMap = ({ points, name }) => {
  const id = `points-${name}`;

  const getProperties = (point) => {
    let icon;
    switch (name) {
      case 'flightRadarFlights':
        icon = convertToConfig({
          icon: 'plane',
          primary: '#000000',
        });
        break;
      case 'flightRadarAirports':
        // icon = 'arrow_bounce-#0000cd';
        icon = convertToConfig({
          icon: 'square',
          primary: '#383838',
        });
        break;
      default:
        icon = 'tractor-#0000cd';
        break;
    }
    return {
      icon,
      title: point.name ?? '',
      course: (point.course || 0) - 90,
    };
  };

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': '{icon}',
        'icon-rotate': { type: 'identity', property: 'course' },
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'text-field': '{title}',
        'text-anchor': 'bottom',
        'text-offset': [0, -1],
        'text-font': ['Roboto Medium'],
        'text-size': 12,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 1,
        'text-color': '#000',
      },
    }, getBeforeId(id));

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    const features = points.map((point) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.long, point.lat],
      },
      properties: getProperties(point),
    }));

    map.getSource(id).setData({
      type: 'FeatureCollection',
      features,
    });
  }, [points]);

  return null;
};

export default PointsMap;
