import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, TextField, useTheme, Typography,
  Backdrop, Fade, makeStyles, Modal, Paper,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { markersActions } from '../../store';
import { useEffectAsync } from '../../common/utils/reactHelper';
import SelectField from '../../common/form/SelectField';
import LinkField from '../../common/form/LinkField';
import { savePermissions } from '../../common/utils/savePermisionsFuncs';
import { getIsAdmin } from '../../common/utils/selectors';
import scrollStyles from '../../common/theme/scrollStyles';
// import markerCategoryParams from '../../common/static/markerCategoryParams';
// import { prefixString } from '../../common/utils/stringUtils';
// import MarkerCategoryFields from '../../views/Settings/components/MarkerCategoryFields';
import SelectMarkerIcon from '../../views/Settings/components/SelectMarkerIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '10px',
  },
  data: {
    maxHeight: '60vh',
    padding: '10px',
    overflow: 'auto',
    ...scrollStyles(5),
  },
  adornmentButton: {
    padding: 0,
  },
  iconValue: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
  buttons: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const MarkerEdit = ({
  setMarker, setEdit, latitude, longitude, marker, open,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();

  const userStorage = useSelector((state) => state.session.user);
  const isAdmin = useSelector(getIsAdmin);

  const [icons, setIcons] = useState([]);
  const [permissions, setPermissions] = useState();
  // const [categoryParams, setCategoryParams] = useState();
  const [item, setItem] = useState(marker || {
    name: '',
    color: theme.palette.markers.black,
    iconName: t('markerDefaultCat'),
    icon: 'default',
    latitude,
    longitude,
    course: 0,
  });

  const closeMark = () => {
    setItem(marker);
    if (setEdit) {
      setEdit(false);
    } else {
      setMarker({ type: 'Feature' });
    }
    /*     if (setEdit) {
          setMarker(item);
          setEdit(false);
        } else {
          setMarker({ type: 'Feature' });
        } */
  };

  const saveMark = async () => {
    const bodyRequest = {
      ...item, altitude: 0, userId: userStorage.id,
    };

    const response = await fetch(`/api/markers${setEdit ? `/${item.id}` : ''}`, {
      method: setEdit ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyRequest),
    });

    if (response.ok) {
      const data = await response.json();

      if (permissions) {
        permissions.unions.baseId = data.id;
        Object.values(permissions).forEach((permission) => savePermissions(permission, true));
      }

      dispatch(markersActions.add(data));
      setMarker(item);
      closeMark();
    }
  };

  const isNumber = (value) => {
    if (value === '' || !Number.isNaN(Number(value))) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   if (item) {
  //     if (item.category !== categoryParams?.category) {
  //       setCategoryParams(markerCategoryParams[item.category || 'marker']);
  //     }
  //   }
  // }, [item?.type]);

  // useEffect(() => {
  //   if (item && categoryParams) {
  //     setItem({ ...item, attributes: { ...item.attributes, categoryParams } });
  //   }
  // }, [categoryParams]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: null,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffect(() => {
    if (marker) {
      // setCategoryParams(marker.attributes?.categoryParams || markerCategoryParams.marker);
      setItem(marker);
    }
  }, [marker]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      setIcons(await response.json());
    } else {
      setIcons([]);
    }
  }, []);

  return (
    <>
      <Modal
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        onClose={closeMark}
      >
        <Fade in={open}>
          <Paper className={classes.modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2" align="center" className={classes.text}>
              {t('markerEditFormTitle')}
            </Typography>

            <div className={classes.root}>
              <div className={classes.data}>
                <TextField
                  fullWidth
                  value={item.name || ''}
                  label={t('markerName')}
                  onChange={(e) => e.target.value.length < 26 && setItem({ ...item, name: e.target.value })}
                  margin="dense"
                  variant="filled"
                />
                <TextField
                  fullWidth
                  value={item.description || ''}
                  label={t('markerDescription')}
                  multiline
                  onChange={(e) => e.target.value.length < 4001 && setItem({ ...item, description: e.target.value })}
                  margin="dense"
                  variant="filled"
                />
                {/* <SelectField
                  margin="dense"
                  value={item.type || ''}
                  emptyValue={null}
                  onChange={(e) => setItem({ ...item, type: e.target.value })}
                  endpoint="/api/markers/types"
                  keyGetter={(it) => it.type}
                  titleGetter={(it) => t(prefixString('marker', it?.type || it))}
                  label={t('sharedType')}
                  variant="filled"
                  fullWidth
                />
                <MarkerCategoryFields categoryParams={categoryParams} setCategoryParams={setCategoryParams} /> */}
                <TextField
                  fullWidth
                  value={item.course}
                  label={t('markerCourse')}
                  onChange={(e) => isNumber(e.target.value) && setItem({ ...item, course: e.target.value })}
                  margin="dense"
                  variant="filled"
                />

                <SelectMarkerIcon icons={icons} item={item} setItem={setItem} />

                <SelectField
                  label={t('markerColor')}
                  required
                  value={item.color}
                  keyGetter={(it) => it.toLowerCase()}
                  onChange={(e) => setItem({ ...item, color: e.target.value.toLowerCase() })}
                  titleGetter={(color) => <FiberManualRecordIcon style={{ color }} />}
                  data={Object.values(theme.palette.markers)}
                  margin="dense"
                  variant="filled"
                  emptyValue={null}
                  fullWidth
                />
                {permissions && (
                  <LinkField
                    margin="dense"
                    endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${userStorage?.id}`}`}
                    endpointLinked={`/api/unions?markerId=${item.id}`}
                    label={t('settingsUnions')}
                    variant="filled"
                    permissions={permissions}
                    setPermissions={setPermissions}
                    type="unions"
                    fullWidth
                    newItem={!item.id}
                  />
                )}
              </div>
            </div>

            <div className={classes.buttons}>
              <Button type="button" color="primary" variant="outlined" onClick={() => closeMark()}>
                {t('sharedCancel')}
              </Button>
              <Button type="button" color="primary" variant="contained" onClick={() => saveMark()}>
                {t('sharedSave')}
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default MarkerEdit;
