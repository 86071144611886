import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
    height: '310px',
    [theme.breakpoints.up('md')]: {
      height: '350px',
    },
  },
  containerFullSize: {
    height: '225px',
    [theme.breakpoints.up('lg')]: {
      height: '235px',
    },
  },
  headerRow: {
    textOverflow: 'clip',
    textWrap: 'nowrap',
  },
  inputRow: {
    width: '300px',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '10px',
      left: '332px',
      zIndex: 5,
      padding: 0,
      margin: 0,
    },
  },
  headerCell: {
    padding: '3px 0 !important',
  },
  bodyCell: {
    padding: '0 !important',
  },
  dataGridContainer: {
    height: 'calc(100% - 50px)',
    width: '100%',
  },
}));
