/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  InputAdornment, IconButton, TextField, makeStyles, List, ListItem, ListItemText, Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';

export default function SearchAddress({ collapsed, setSearchCoords }) {
  const server = useSelector((state) => state.session.server);
  const user = useSelector((state) => state.session.user);

  const t = useTranslation();

  const useStylesSearchAddress = makeStyles((theme) => ({
    searchDiv: {
      backgroundColor: 'white',
      width: '260px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 145px)',
      },
    },
    searchField: {
      backgroundColor: 'white',
      margin: theme.spacing(1.5, 0, 0, 48),
      transition: 'margin .5s ease',
      width: '100%',
    },
    searchFieldCollapsed: {
      margin: theme.spacing(1.5, 0, 0, 10),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1.5, 0, 0, 8),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(7.5, 0, 0, 0),
      },
    },
    searchButton: {
      transform: 'translateX(15px)',
    },
    list: {
      backgroundColor: 'white',
      width: '100%',
      margin: theme.spacing(0.5, 0, 0, 48),
      transition: 'margin .5s ease',
    },
    listCollapsed: {
      margin: theme.spacing(0.5, 0, 0, 10),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0.5, 0, 0, 8),
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5, 0, 0, 0),
      },
    },
    listItem: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  }));
  const classes = useStylesSearchAddress([]);

  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [isSearchOn, setSearchOn] = useState();

  useEffect(() => {
    if (server.attributes.mapGeocodeProvider === 'off' || !server.attributes.mapGeocodeProvider) {
      setSearchOn(false);
    } else {
      setSearchOn(true);
    }
  }, []);

  useEffect(() => {
    if (searchValue === '') {
      setData([]);
    }
  }, [searchValue]);

  async function onSubmit() {
    if (searchValue !== '') {
      switch (server.attributes.mapGeocodeProvider) {
        case 'yandex': {
          try {
            const response = await fetch(
              `https://geocode-maps.yandex.ru/1.x/?apikey=${server.attributes.mapGeocodeProviderApiKey}&geocode=${searchValue}&results=3&format=json&sco=latlong`,
            );

            const data = await response.json();
            if (data.response.GeoObjectCollection.featureMember.length > 0) {
              const newData = [];
              data.response.GeoObjectCollection.featureMember.map((el) => {
                const { text } = el.GeoObject.metaDataProperty.GeocoderMetaData;
                const point = el.GeoObject.Point.pos.split(' ').map(parseFloat);
                let { lowerCorner } = el.GeoObject.boundedBy.Envelope;
                let { upperCorner } = el.GeoObject.boundedBy.Envelope;

                lowerCorner = lowerCorner.split(' ');
                lowerCorner = [Number(lowerCorner[0]), Number(lowerCorner[1])];

                upperCorner = upperCorner.split(' ');
                upperCorner = [Number(upperCorner[0]), Number(upperCorner[1])];

                newData.push({ text, point, points: [lowerCorner, upperCorner] });
              });
              setData(newData);
            } else {
              setData([
                {
                  text: t('searchNotFound'),
                  points: {
                    lat: -1000,
                    lon: -1000,
                  },
                },
              ]);
              setTimeout(() => { setData([]); }, 3000);
            }
          } catch (error) {
            setData([
              {
                text: t('searchError'),
                points: {
                  lat: -1000,
                  lon: -1000,
                },
              },
            ]);
            console.error(error);
            setTimeout(() => { setData([]); }, 3000);
          }
          break;
        }
        case 'nominatim': {
          try {
            const response = await fetch(
              `${server.attributes.mapGeoсodeProviderUrl}/search?q=${searchValue}&limit=3&format=jsonv2`,
            );
            const data = await response.json();
            if (data.length > 0) {
              const newData = [];
              data.map((el) => {
                const text = el.display_name;
                let points = el.boundingbox;
                const point = [parseFloat(el.lon), parseFloat(el.lat)];
                points = [
                  [
                    Number(points[2]),
                    Number(points[0]),
                  ],
                  [
                    Number(points[3]),
                    Number(points[1]),
                  ],
                ];
                newData.push({ text, point, points });
              });
              setData(newData);
            } else {
              setData([
                {
                  text: t('searchNotFound'),
                  points: {
                    lat: -1000,
                    lon: -1000,
                  },
                },
              ]);
              setTimeout(() => { setData([]); }, 3000);
            }
          } catch (error) {
            setData([
              {
                text: t('searchError'),
                points: {
                  lat: -1000,
                  lon: -1000,
                },
              },
            ]);
            console.error(error);
            setTimeout(() => { setData([]); }, 3000);
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  return (
    <>
      {(user.attributes.showSearch && isSearchOn) ? (
        <div className={classes.searchDiv}>
          <TextField
            className={`${classes.searchField} ${collapsed && classes.searchFieldCollapsed}`}
            onChange={(e) => { setSearchValue(e.target.value); }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
            value={searchValue}
            placeholder={t('mapSearch')}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton className={`${classes.searchButton}`} onClick={() => { onSubmit(); }}>
                    <SearchIcon
                      edge="end"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {data.length > 0 ? (
            <List className={`${classes.list} ${collapsed && classes.listCollapsed}`}>
              <Divider component="li" />
              {data.map((el) => (
                <div key={el.points}>
                  <ListItem
                    className={`${classes.listItem}`}
                    onClick={(el.text === t('searchNotFound') || el.text === t('searchError')) ? () => undefined : () => {
                      setSearchCoords({
                        points: el.points,
                        point: el.point,
                      });
                      setSearchValue('');
                    }}
                  >
                    <ListItemText primary={el.text} />
                  </ListItem>
                  <Divider component="li" />
                </div>
              ))}
            </List>
          ) : <></>}
        </div>
      ) : (<></>)}
    </>
  );
}
