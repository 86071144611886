import { v4 as uuidv4 } from 'uuid';
import { tailActions } from '../../store';
import { collectReport } from './formatter';

/* eslint-disable no-await-in-loop */
const addProgress = (progress, setProgress, devices) => {
  const newProgress = progress + Math.round(99 / devices.length);
  if (progress < newProgress && newProgress < 99) {
    setProgress(newProgress);
    return newProgress;
  }
  return null;
};

export const positionHandler = (response, path, onlyPath, onBs, positions, similar, devices, tail, progress, setProgress) => {
  let partPath = [];
  let key;
  let lastPosition;
  let similarPath = [];
  response.forEach((v) => {
    if (v.longitude !== 0 && (onBs ? true : !v.attributes.approximate)) {
      if (key !== `${v.longitude}-${v.latitude}`) {
        if (similarPath.length) {
          (tail ? path : partPath).push(similarPath[similarPath.length - 1]);
          similarPath = [];
        }
        if (!tail && lastPosition) {
          if (new Date(v.fixTime).getTime() - new Date(lastPosition.fixTime).getTime() > 3600000) {
            path.push(partPath);
            path.push({ timeGap: [lastPosition, v] });
            partPath = [];
          }
        }
        (tail ? path : partPath).push(v);
      } else {
        similarPath.push(v);
      }
      lastPosition = v;
      key = `${v.longitude}-${v.latitude}`;
      if (!onlyPath) {
        positions.push(v);
        similar[key] = similar[key] ? [...similar[key], v] : [v];
      }
    }
    if (!onlyPath) {
      const prog = progress + Math.round(99 / devices.length / response.length);
      if (prog < 99) {
        progress += Math.round(99 / devices.length / response.length);
        setProgress(progress);
      }
    }
  });
  if (!tail) {
    if (partPath.length) {
      path.push(partPath);
    }
  }
};

export default async (devices, from, to, headers, states, onBs, tail, setProgress, dispatch) => {
  const indexesReady = {};
  const positionsReady = {};
  const positionsReadyPath = {};
  const positionsReadyStop = {};
  const positionsReadySimilar = {};
  let progress = 1;
  await Promise.all(devices.filter((device) => device).map(async (device) => {
    const deviceId = device.id;
    if (tail && device.lastUpdate <= from) {
      progress = addProgress(progress, setProgress, devices);
      return;
    }
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`, { headers });

    const requestName = uuidv4();
    const abortController = new AbortController();
    const { signal } = abortController;
    query.append('requestName', requestName);
    const responseStop = await fetch(`/api/reports/stops?${query.toString()}`, { headers, signal });
    if (response.ok) {
      const responseResult = await response.json();
      const clearResponse = [];
      const clearResponsePath = [];
      const responseSimilar = {};
      positionHandler(responseResult, clearResponsePath, false, onBs, clearResponse, responseSimilar,
        devices, tail, progress, setProgress);
      if (clearResponse.length || tail) {
        positionsReady[deviceId] = clearResponse;
        positionsReadyPath[deviceId] = clearResponsePath;
        positionsReadySimilar[deviceId] = responseSimilar;
        indexesReady[deviceId] = 0;
      }
      if (responseStop.ok) {
        const responseStopResult = [];
        await collectReport(responseStop, responseStopResult, null, /,{"deviceId"/);
        if (responseStopResult.length) {
          positionsReadyStop[deviceId] = responseStopResult;
        }
      }
    }
    progress = addProgress(progress, setProgress, devices);
  }));
  if (!tail) {
    states.setIndexes(indexesReady);
  }
  if (tail) {
    dispatch(tailActions.init({
      positions: positionsReady,
      positionsPath: positionsReadyPath,
      positionsSimilar: positionsReadySimilar,
      positionsStop: positionsReadyStop,
    }));
  } else {
    states.setPositions(positionsReady);
    states.setPositionsPath(positionsReadyPath);
    states.setPositionsSimilar(positionsReadySimilar);
    states.setPositionsStop(positionsReadyStop);
  }
  setProgress(99);
  if (!tail && (Object.keys(positionsReady).length || Object.keys(positionsReadyStop).length)) {
    return true;
  }
  return false;
};
