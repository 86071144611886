export default {
  piligrim: {
    // changeProxy: { type: 'changeProxy', command: '#change Proxy 192.200.22.1', description: 'Изменяет прокси на другой прокси' },
    // getGps: { type: 'getGps', command: 'give me GPS', description: 'ывывваывавыаываываыва' },
  },
  teltonika: {
    // changeProxy: { type: 'sendMessage', command: '#change Proxy 192.200.22.122', description: 'Аываыва апвап ывыавыа' },
    // getGps: { type: 'getGps', command: 'give me GPS', description: 'Фаыввауу ааап ваы' },
  },
};
