import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'file preview layout',
  initialState: {
    windows: {},
  },
  reducers: {
    add(state, action) {
      state.windows = { ...state.windows, ...action.payload };
    },
    removeByKey(state, action) {
      delete state.windows[action.payload];
    },
    clear(state) {
      state.windows = {};
    },
  },
});

export { actions as filePreviewLayoutActions };
export { reducer as filePreviewLayoutReducer };
