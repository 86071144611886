import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useTheme, Typography,
  makeStyles, TextField, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../LocalizationProvider';
import { formatPhone, setAttr } from '../../utils/formatter';
import { getIsAdmin } from '../../utils/selectors';
import SelectField from '../../form/SelectField';
import LinkField from '../../form/LinkField';
import commands from '../../static/commands';
import { prefixString } from '../../utils/stringUtils';
import DeviceCategoryFields from '../../../views/Settings/components/DeviceCategoryFields';
import theme from '../../theme';
import icons from '../../static/icons';
import SelectDeviceIcon from '../../../views/Settings/components/SelectDeviceIcon';
import categoryParamsStructure from '../../static/categoryParamsStructure';
import ControlledTextField from '../../form/ControlledTextField';
import { isDistanceValid, isGeocoordValid } from '../../utils/validators';
import SkeletonEdit from './PositionDataEdit/SkeletonEdit';
import CoordinatesBlock from '../CoordinatesBlock';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  accordionSummary: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export default function DeviceEdit({
  item, setItem, errors, setErrors, initState, permissions, setPermissions, isNew,
}) {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);

  const [initCategoryParams, setInitCategoryParams] = useState({});
  const [isReadyToShow, setIsReadyToShow] = useState(false);

  const setDefaultIcon = (icons, defaultValue) => {
    if (!icons.includes(item.icon)) {
      setItem({ ...item, icon: defaultValue });
    }
  };

  const onChangeIcon = (icon) => {
    setItem({
      ...item,
      icon,
    });
  };

  useEffect(() => {
    setInitCategoryParams({ ...initState.attributes?.categoryParams });
    setItem((item) => ({ ...item, ...initState }));
  }, [initState]);

  const changeDeviceType = (category, model = 'default') => {
    setErrors({ ...errors, categoryParamsError: false });
    setItem({ ...item, category, model });
    const defaultParams = categoryParamsStructure[category]?.model[model]?.writen || {};
    setInitCategoryParams({ ...defaultParams });
  };

  useEffect(() => {
    if (item && !(initCategoryParams && Object.keys(initCategoryParams).length > 0)) {
      setItem({ ...item, attributes: { ...item.attributes, categoryParams: {} } });
    }
  }, [initCategoryParams]);

  useEffect(() => {
    /**
     * Проверяет валидность номера телефона. Изменяет состояние errorPhone внутри errors.
     */
    const lengthPhone = item?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && item.phone.slice(0, 2) !== '+7')) {
        setErrors({ ...errors, errorPhone: true });
        return;
      }
    }
    setErrors({ ...errors, errorPhone: false });
  }, [item?.phone]);

  const updateCategoryParams = (params) => {
    if (item && params) {
      setItem({ ...item, attributes: { ...item.attributes, categoryParams: params } });
    }
  };

  const updateCategoryParamsError = (value) => {
    setErrors({ ...errors, categoryParamsError: value });
  };

  useEffect(() => {
    /**
     * Устанавливает конфигурационный файл для permissions.
     * В дальнейшем в данном словаре будут изменяться поля linked, old, в которых указаны id элементом,
     * которые будут удаляться (поле old) или добавляться (поле linked) при нажатии на кнопку Сохранить.
     */
    if (item && !permissions) {
      const permissionsDict = {
        geofences: {
          keyLink: 'geofenceId',
          baseId: item.id,
          keyBase: 'deviceId',
          linked: new Set(),
          old: new Set(),
        },
        notifications: {
          keyLink: 'notificationId',
          baseId: item.id,
          keyBase: 'deviceId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffect(() => {
    if (item?.category) {
      switch (item.category) {
        case 'radar':
          setDefaultIcon(icons, 'radar');
          break;
        case 'uav':
          setDefaultIcon(icons, 'uav');
          break;
        default:
          setDefaultIcon(icons, 'default');
          break;
      }
    }
  }, [item?.category]);

  useEffect(() => {
    if (item) {
      if (isNew) {
        setIsReadyToShow(true);
        return;
      }
      if (item?.id) {
        setIsReadyToShow(true);
      }
    }
  }, [item]);

  return (
    <>
      {isReadyToShow
        ? (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedDevice')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ControlledTextField
                  required
                  name="name"
                  disabled={!isAdmin}
                  margin="normal"
                  initValue={initState.name || ''}
                  onChange={(value) => setItem((item) => ({ ...item, name: value }))}
                  label={t('sharedName')}
                  variant="filled"
                />
                <ControlledTextField
                  required
                  name="uniqueId"
                  disabled={!isAdmin}
                  margin="normal"
                  initValue={initState.uniqueId || ''}
                  onChange={(value) => setItem((item) => ({ ...item, uniqueId: value }))}
                  label={t('deviceIdentifier')}
                  variant="filled"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!isAdmin}
                      checked={!!item.temporary}
                      onChange={(e) => setItem({ ...item, temporary: e.target.checked })}
                    />
                  )}
                  label={t('deviceTemporary')}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!isAdmin}
                      checked={!!item.disabled}
                      onChange={
                        (e) => setItem({ ...item, disabled: e.target.checked })
                      }
                    />
                  )}
                  label={t('deviceDisabled')}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {isAdmin ? t('sharedExtra') : `${t('sharedDevice')} — ${item.name}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  margin="normal"
                  disabled={!isAdmin}
                  value={item.groupId || 0}
                  onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                  endpoint="/api/groups"
                  label={t('groupParent')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  disabled={!isAdmin}
                  value={item.phone || ''}
                  onChange={(event) => formatPhone(event.target.value, item, setItem)}
                  label={t('sharedPhone')}
                  placeholder="+79XXXXXXXXX"
                  error={errors.errorPhone}
                  helperText={errors.errorPhone ? t('errorPhone') : ''}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  disabled={!isAdmin}
                  value={item.category || ''}
                  emptyValue={null}
                  onChange={(e) => changeDeviceType(e.target.value)}
                  endpoint="/api/devices/categories"
                  keyGetter={(it) => it}
                  titleGetter={(it) => t(prefixString('device', it))}
                  label={t('sharedCategory')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  disabled={!isAdmin}
                  value={item.model || ''}
                  emptyValue={null}
                  onChange={(e) => changeDeviceType(item.category, e.target.value)}
                  data={Object.keys(categoryParamsStructure[item.category]?.model || [])}
                  keyGetter={(it) => it}
                  titleGetter={(it) => t(prefixString('deviceModel', it))}
                  label={t('deviceModel')}
                  variant="filled"
                />
                <SelectDeviceIcon icons={icons} currentIcon={item.icon} onChange={onChangeIcon} />
                <SelectField
                  margin="normal"
                  disabled={!isAdmin}
                  value={item.attributes?.protocol || ''}
                  data={Object.keys(commands).map((protocol) => ({
                    id: protocol,
                    name: protocol,
                  }))}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, protocol: event.target.value } })}
                  label={t('deviceCommunicationProtocol')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  value={item.attributes?.movement || ''}
                  data={['driving', 'walking', 'cycling'].map((way) => ({
                    id: way,
                    name: t(`${way}Movement`),
                  }))}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, movement: event.target.value } })}
                  label={t('deviceMovement')}
                  variant="filled"
                />
                <SelectField
                  margin="normal"
                  value={item.attributes?.color || theme.palette.tracks.replay0}
                  emptyValue={null}
                  onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, color: event.target.value } })}
                  data={Object.keys(theme.palette.tracks).map((color) => ({
                    id: theme.palette.tracks[color],
                    name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
                  }))}
                  label={t('trackColor')}
                  variant="filled"
                />
                <CoordinatesBlock
                  latitude={initState.attributes?.latitude || ''}
                  longitude={initState.attributes?.longitude || ''}
                  setLatitude={(latitude) => setAttr(setItem, 'latitude', latitude)}
                  setLongitude={(longitude) => setAttr(setItem, 'longitude', longitude)}
                  errors={errors}
                  setErrors={setErrors}
                />
                <ControlledTextField
                  fullWidth
                  name="latitude"
                  disabled={!isAdmin}
                  initValue={initState.attributes?.latitude || ''}
                  helperText={errors.latitude && t('latitudeValidateErrorMsg')}
                  placeholder="56.018134"
                  label={t('deviceLatitude')}
                  onChange={(value) => setItem((item) => ({ ...item, attributes: { ...item.attributes, latitude: value } }))}
                  isValid={(value) => (value ? isGeocoordValid(value, 90) : true)}
                  onValidate={(value) => setErrors((errors) => ({ ...errors, latitude: value }))}
                />
                <ControlledTextField
                  fullWidth
                  name="longitude"
                  disabled={!isAdmin}
                  helperText={errors.longitude && t('longitudeValidateErrorMsg')}
                  placeholder="149.145755"
                  initValue={initState.attributes?.longitude || ''}
                  label={t('deviceLongitude')}
                  onChange={(value) => setItem((item) => ({ ...item, attributes: { ...item.attributes, longitude: value } }))}
                  isValid={(value) => (value ? isGeocoordValid(value, 180) : true)}
                  onValidate={(value) => setErrors((errors) => ({ ...errors, longitude: value }))}
                />
                <ControlledTextField
                  fullWidth
                  name="altitude"
                  disabled={!isAdmin}
                  helperText={errors.altitude && t('altitudeValidateErrorMsg')}
                  placeholder="10.5"
                  initValue={initState.attributes?.altitude || ''}
                  label={t('deviceAltitude')}
                  onChange={(value) => setItem((item) => ({ ...item, attributes: { ...item.attributes, altitude: value } }))}
                  isValid={(value) => (value ? isDistanceValid(value) : true)}
                  onValidate={(value) => setErrors((errors) => ({ ...errors, altitude: value }))}
                />
                <ControlledTextField
                  fullWidth
                  name="azimuth"
                  disabled={!isAdmin}
                  helperText={errors.azimuth && t('azimuthValidateErrorMsg')}
                  placeholder="45"
                  initValue={initState.attributes?.azimuth || ''}
                  label={t('deviceAzimuth')}
                  onChange={(value) => setItem((item) => ({ ...item, attributes: { ...item.attributes, azimuth: value } }))}
                  isValid={(value) => (value ? isGeocoordValid(value, 0, 360) : true)}
                  onValidate={(value) => setErrors((errors) => ({ ...errors, azimuth: value }))}
                />
                {!!(item.id && permissions) && (
                  <>
                    <LinkField
                      margin="normal"
                      disabled={!isAdmin}
                      endpointAll="/api/geofences"
                      endpointLinked={`/api/geofences?deviceId=${item.id}`}
                      label={t('sharedGeofences')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="geofences"
                    />
                    <LinkField
                      margin="normal"
                      disabled={!isAdmin}
                      endpointAll="/api/notifications"
                      endpointLinked={`/api/notifications?deviceId=${item.id}`}
                      titleGetter={(it) => it.attributes?.description || t(prefixString('event', it.type))}
                      label={t('sharedNotifications')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="notifications"
                    />
                  </>
                )}
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={!isAdmin}
                      checked={!!item.saveLogs}
                      onChange={
                        (e) => setItem({ ...item, saveLogs: e.target.checked })
                      }
                    />
                  )}
                  label={t('deviceSaveLogs')}
                />
              </AccordionDetails>
            </Accordion>
            {(initCategoryParams && Object.keys(initCategoryParams).length > 0) && (
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('settingsDeviceCategory')}
                  </Typography>
                </AccordionSummary>
                <DeviceCategoryFields initState={initCategoryParams} category={item.category} model={item.model} updateCategoryParams={updateCategoryParams} updateCategoryParamsError={updateCategoryParamsError} />
              </Accordion>
            )}
          </>
        )
        : (
          <SkeletonEdit />
        )}
    </>
  );
}
