/* eslint-disable indent */
/* eslint-disable object-curly-newline */
import React from 'react';
import { ShowRadarAreaCellRender } from '../../columns/cells';
import { ShowRadarAreaHeaderRender } from '../../columns/headers';
import { getStringFromBoolean } from '../../funcs/utils';
import detectedTablesSharedFields from './detectedsSharedColumns';

export default ({
  deviceListClasses, now, selectedObjects, actionMenu,
  t, theme, positions, handleRadarAreaStatusChangeFromHeader,
  innerClasses, handleRadarAreaStatusChange, statusCheckboxChecker,
}) => [
    ...detectedTablesSharedFields.calculateValues.shared({ innerClasses, actionMenu, selectedObjects, t, deviceListClasses, now, theme }),
    ...detectedTablesSharedFields.calculateValues.byCategory.radarAndAntiUav({ positions, theme, t }),
    {
      dataField: 'attributes.categoryParams.showRadarArea',
      caption: 'deviceShowRadarArea',
      dataType: 'string',
      width: 280,
      headerCellComponent: () => <ShowRadarAreaHeaderRender handleRadarAreaStatusChangeFromHeader={handleRadarAreaStatusChangeFromHeader} innerClasses={innerClasses} statusCheckboxChecker={statusCheckboxChecker} t={t} />,
      cellComponent: ({ data }) => <ShowRadarAreaCellRender dataset={data} handleRadarAreaStatusChange={handleRadarAreaStatusChange} innerClasses={innerClasses} t={t} />,
      calculateCellValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
      calculateGroupValue: (item) => getStringFromBoolean(item.attributes?.categoryParams?.showRadarArea ?? false, t),
    },
  ];
