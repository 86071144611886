import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import maplibregl from 'maplibre-gl';
import { parse } from 'wellknown';
import { map } from './Map';
import { geofencesActions } from '../store';

const SelectedGeofenceMap = () => {
  const dispatch = useDispatch();
  const itemGeofence = useSelector((state) => {
    const geofenceId = state.geofences.selectedId;
    if (geofenceId) {
      return state.geofences.items[geofenceId] || null;
    }
    return null;
  });

  useEffect(() => {
    if (itemGeofence) {
      const coordinates = parse(itemGeofence.area).coordinates[0].map((coordinate) => coordinate.reverse()) || null;
      if (coordinates && coordinates.length) {
        const bounds = coordinates
          .reduce((bounds, item) => bounds.extend(item), new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
        map.fitBounds(bounds, {
          maxZoom: 15,
          padding: {
            top: 250, bottom: 250, left: 25, right: 25,
          },
        });
      }
      dispatch(geofencesActions.select({}));
    }
  }, [itemGeofence]);

  return null;
};

export default SelectedGeofenceMap;
