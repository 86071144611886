import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';
import useStylesForDetectedTable from './common/useStylesForDetectedTable';

/**
 * Настроенный компонент таблицы радаров.
 */
const RadarsTableView = () => {
  const classesForPerfectTable = useStylesForDetectedTable();
  const getComponent = useComponentsController();

  const settings = {
    classes: classesForPerfectTable,
    small: true,
    isPage: true,
    caption: 'deviceCategoriesRadar',
    disableFilterRowTool: false,
    tableName: 'Radar_page',
  };

  return (
    <>
      {getComponent(availableComponents.RadarsTable, settings)}
    </>
  );
};

export default RadarsTableView;
