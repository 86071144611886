import React from 'react';
import {
  Grid, IconButton, Typography,
} from '@material-ui/core';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSelector } from 'react-redux';

export default function ListDataStepper({
  countPosition, positionsSimilar, classes, setNextPosition, setCountPosition, gridStyle,
}) {
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  return (
    <Grid
      style={gridStyle}
      container
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <IconButton
          disabled={countPosition === 0}
          onClick={() => {
            const pos = positionsSimilar[selectedPositionData.deviceId][`${selectedPositionData.longitude}-${selectedPositionData.latitude}`];
            setNextPosition(pos[countPosition - 1]);
            setCountPosition(countPosition - 1);
          }}
          className={classes.iconButton}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid>
        <Typography noWrap variant="body2">
          {`${countPosition + 1} - ${selectedPositionData.numberPoints}`}
        </Typography>
      </Grid>
      <Grid>
        <IconButton
          disabled={countPosition === selectedPositionData.numberPoints - 1}
          onClick={() => {
            const pos = positionsSimilar[selectedPositionData.deviceId][`${selectedPositionData.longitude}-${selectedPositionData.latitude}`];
            setNextPosition(pos[countPosition + 1]);
            setCountPosition(countPosition + 1);
          }}
          className={classes.iconButton}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
