import { toTitleCase } from '../../../utils/formatter';
import { isUndefined } from './utils';

export const translateCurrentWorkModeBase = (item, t, description, workMode) => {
  if (item.model !== 'strizh3') return '';

  if (!isUndefined(description)) return description;

  const statusText = t(workMode);

  if (isUndefined(statusText)) {
    return '';
  }

  return statusText;
};

export const translateConnectionStatusBase = (t, connectionStatus) => {
  if (connectionStatus) {
    return t(`global${toTitleCase(connectionStatus)}`);
  }
  return t('globalUnknown');
};

export const translateDeviceModel = (t, value) => t(`deviceModel${toTitleCase(value)}`) ?? '';
