import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, makeStyles, TextField, useTheme, Typography,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from '../common/components/LocalizationProvider';
import { markersActions } from '../store';
import { useEffectAsync } from '../common/utils/reactHelper';
import SelectField from '../common/form/SelectField';
import LinkField from '../common/form/LinkField';
import { savePermissions } from '../common/utils/savePermisionsFuncs';
import { getIsAdmin } from '../common/utils/selectors';
// import { prefixString } from '../common/utils/stringUtils';
// import MarkerCategoryFields from '../views/Settings/components/MarkerCategoryFields';
// import markerCategoryParams from '../common/static/markerCategoryParams';
import scrollStyles from '../common/theme/scrollStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    margin: '10px',
    maxHeight: '400px',
    overflow: 'auto',
    ...scrollStyles(5),
  },
  adornmentButton: {
    padding: 0,
  },
  iconValue: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

const MarkPopup = ({
  popup, setMarker, latitude, longitude,
}) => {
  const t = useTranslation();
  const [icons, setIcons] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector((state) => state.session.user);
  const [permissions, setPermissions] = useState();
  // const [categoryParams, setCategoryParams] = useState();
  const isAdmin = useSelector(getIsAdmin);

  const [item, setItem] = useState({
    name: '',
    color: theme.palette.markers.black,
    icon: 'default',
    latitude,
    longitude,
    course: 0,
  });

  const closeMark = () => {
    popup.remove();
    setMarker({ type: 'Feature' });
  };

  const saveMark = async () => {
    const bodyRequest = {
      ...item, altitude: 0, userId: user.id,
    };

    const response = await fetch('/api/markers', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyRequest),
    });

    if (response.ok) {
      const data = await response.json();

      if (permissions) {
        permissions.unions.baseId = data.id;
        Object.values(permissions).forEach((permission) => savePermissions(permission, true));
      }
      dispatch(markersActions.add(data));
      closeMark();
    }
  };

  const isNumber = (value) => {
    if (value === '' || !Number.isNaN(Number(value))) {
      return true;
    }
    return false;
  };

  // useEffect(() => {
  //   if (item) {
  //     if (item.category !== categoryParams?.category) {
  //       setCategoryParams(markerCategoryParams[item.category || 'marker']);
  //     }
  //   }
  // }, [item?.category]);

  // useEffect(() => {
  //   if (item && categoryParams) {
  //     setItem({ ...item, attributes: { ...item.attributes, categoryParams } });
  //   }
  // }, [categoryParams]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: null,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      setIcons(await response.json());
    } else {
      setIcons([]);
    }
  }, []);

  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        value={item.name || ''}
        label={t('markerName')}
        onChange={(e) => e.target.value.length < 26 && setItem({ ...item, name: e.target.value })}
        margin="dense"
        variant="filled"
      />
      <TextField
        fullWidth
        value={item.description || ''}
        label={t('markerDescription')}
        multiline
        onChange={(e) => e.target.value.length < 4001 && setItem({ ...item, description: e.target.value })}
        margin="dense"
        variant="filled"
      />
      {/* <SelectField
        margin="dense"
        value={item.type || ''}
        emptyValue={null}
        onChange={(e) => setItem({ ...item, type: e.target.value })}
        endpoint="/api/markers/types"
        keyGetter={(it) => it.type}
        titleGetter={(it) => t(prefixString('marker', it?.type || it))}
        label={t('sharedType')}
        variant="filled"
        fullWidth
      />
      <MarkerCategoryFields categoryParams={categoryParams} setCategoryParams={setCategoryParams} /> */}
      <TextField
        fullWidth
        value={item.course}
        label={t('markerCourse')}
        onChange={(e) => isNumber(e.target.value) && setItem({ ...item, course: e.target.value })}
        margin="dense"
        variant="filled"
      />
      {icons && (
        <FormControl fullWidth variant="filled" margin="dense" required>
          <InputLabel>{t('markerIcon')}</InputLabel>
          <Select
            value={item.iconName}
            onChange={(e) => setItem({
              ...item,
              iconName: e.target.value,
              icon: icons.find((icon) => icon.name === e.target.value)?.icon || 'default',
              color: icons.find((icon) => icon.name === e.target.value)?.color || theme.palette.markers.black,
            })}
            renderValue={() => (
              <div className={classes.iconValue}>
                <img src={`/images/icon/device/${item.icon}.svg`} alt="" />
                <Typography>{item.iconName}</Typography>
              </div>
            )}
          >
            {[{ id: 0, name: t('markerDefaultCat'), icon: 'default' }, ...icons].map((cat) => (
              <MenuItem value={cat.name} key={cat.id}>
                <ListItemIcon>
                  <img src={`/images/icon/device/${cat.icon}.svg`} alt="" />
                </ListItemIcon>
                <ListItemText>{cat.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <SelectField
        label={t('markerColor')}
        required
        value={item.color}
        keyGetter={(it) => it.toLowerCase()}
        onChange={(e) => setItem({ ...item, color: e.target.value.toLowerCase() })}
        titleGetter={(color) => <FiberManualRecordIcon style={{ color }} />}
        data={Object.values(theme.palette.markers)}
        margin="normal"
        variant="filled"
        emptyValue={null}
        fullWidth
      />
      {permissions && (
        <LinkField
          margin="dense"
          endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${user?.id}`}`}
          endpointLinked={`/api/unions?markerId=${item.id}`}
          label={t('settingsUnions')}
          variant="filled"
          permissions={permissions}
          setPermissions={setPermissions}
          type="unions"
          fullWidth
          newItem={!item.id}
        />
      )}
      <ButtonGroup size="small" fullWidth>
        <Button variant="" onClick={() => closeMark()}>{t('sharedCancel')}</Button>
        <Button variant="" color="secondary" onClick={() => saveMark()}>
          {t('sharedSave')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default MarkPopup;
