import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { formatMsToDaysText } from '../utils/formatter';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  timeExpiration: {
    bottom: 10,
    right: 10,
    position: 'absolute',
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));

const TextExpirationDay = ({ timeExpiration }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [days, setDays] = useState();
  const [daysBeforeExpiration, setDaysBeforeExpiration] = useState();

  useEffect(() => {
    if (timeExpiration) {
      const difference = new Date(timeExpiration) - new Date();
      const [daysT, daysBeforeExpirationT] = formatMsToDaysText(difference, t);
      setDays(daysT);
      setDaysBeforeExpiration(daysBeforeExpirationT);
    }
  }, [timeExpiration]);

  return (
    <>
      {!!(daysBeforeExpiration && days && days < 25) && (
        <Typography variant={days < 6 ? 'h6' : 'button'} className={classes.timeExpiration}>
          {`${t('noticeDateExpirationText')} ${daysBeforeExpiration}`}
        </Typography>
      )}
    </>
  );
};

export default TextExpirationDay;
