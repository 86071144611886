import React, { useEffect, useState } from 'react';
import {
  FormControl, Grid, InputLabel, MenuItem, Select, TextField,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import {
  deformatCoordinate, formatCoordinate, getExampleCoordinate, isValidCoordinate,
} from '../utils/formatter';
import { usePreference } from '../utils/preferences';

const CoordinatesBlock = ({
  latitude, longitude, setLatitude, setLongitude, errors, setErrors, dinamicMode,
}) => {
  const t = useTranslation();

  const coordinateFormat = usePreference('coordinateFormat');
  const [coordinateFormatSet, setCoordinateFormatSet] = useState(coordinateFormat);

  const [currentCoordinates, setCurrentCoordinates] = useState({
    lat: formatCoordinate('latitude', latitude, coordinateFormatSet),
    lng: formatCoordinate('longitude', longitude, coordinateFormatSet),
  });

  const handleOnChangeLatitude = (event) => {
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lat: event.target.value }));
  };

  const handleOnChangeLength = (event) => {
    setCurrentCoordinates((prevCoordinates) => ({ ...prevCoordinates, lng: event.target.value }));
  };

  useEffect(() => {
    if (dinamicMode) {
      setCurrentCoordinates({
        lat: formatCoordinate('latitude', latitude, coordinateFormatSet),
        lng: formatCoordinate('longitude', longitude, coordinateFormatSet),
      });
    }
  }, [latitude, longitude, coordinateFormatSet]);

  useEffect(() => {
    const newErrors = { ...errors };
    if (isValidCoordinate('latitude', currentCoordinates.lat, coordinateFormatSet)) {
      delete newErrors.latitude;
      setLatitude(deformatCoordinate(currentCoordinates.lat, coordinateFormatSet));
    } else {
      newErrors.latitude = true;
    }
    if (isValidCoordinate('longitude', currentCoordinates.lng, coordinateFormatSet)) {
      delete newErrors.longitude;
      setLongitude(deformatCoordinate(currentCoordinates.lng, coordinateFormatSet));
    } else {
      newErrors.longitude = true;
    }
    setErrors(newErrors);
  }, [currentCoordinates.lat, currentCoordinates.lng, coordinateFormatSet]);

  return (
    <>
      <FormControl variant="filled" fullWidth margin="normal">
        <InputLabel>{t('settingsCoordinateFormatInput')}</InputLabel>
        <Select
          label={t('settingsCoordinateFormat')}
          value={coordinateFormatSet || coordinateFormat}
          onChange={(e) => setCoordinateFormatSet(e.target.value)}
        >
          <MenuItem value="dd">{t('sharedDecimalDegrees')}</MenuItem>
          <MenuItem value="ddm">{t('sharedDegreesDecimalMinutes')}</MenuItem>
          <MenuItem value="dms">{t('sharedDegreesMinutesSeconds')}</MenuItem>
        </Select>
      </FormControl>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={6}>
          <TextField
            variant="filled"
            value={currentCoordinates.lat}
            label={t('markerLatitude')}
            onChange={handleOnChangeLatitude}
            error={errors?.latitude}
            helperText={errors?.latitude ? `${t('errorLatitude')}. ${t('sharedExample')}: ${getExampleCoordinate('latitude', coordinateFormatSet)}` : ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="filled"
            label={t('markerLongitude')}
            value={currentCoordinates.lng}
            onChange={handleOnChangeLength}
            error={errors?.longitude}
            helperText={errors?.longitude ? `${t('errorLongitude')}. ${t('sharedExample')}: ${getExampleCoordinate('longitude', coordinateFormatSet)}` : ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CoordinatesBlock;
