import CrosshairManager from './crosshair-manager';
import PrintableAreaManager from './printable-area-manager';
import MapGenerator, {
  Size, Format, PageOrientation, DPI, Unit,
} from './map-generator';
import { mapActions } from '../../store';

export default class MaplibreExportControl {
  constructor(options) {
    this.dispatch = null;
    this.options = {
      PageSize: Size.A4,
      PageOrientation: PageOrientation.Landscape,
      Format: Format.PDF,
      DPI: DPI[300],
      Crosshair: false,
      PrintableArea: false,
      Local: {
        PageSize: 'Размер',
        PageOrientation: 'Ориентация',
        Format: 'Формат',
        DPI: 'DPI',
        Generate: 'Создать',
      },
      AllowedSizes: Object.keys(Size),
      Filename: 'map',
    };

    if (options) {
      this.options = Object.assign(this.options, options);
    }
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  updateDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  onAdd(map) {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.exportContainer = document.createElement('div');
    this.exportContainer.classList.add('maplibregl-export-list');
    this.exportButton = document.createElement('button');
    this.exportButton.classList.add('maplibregl-ctrl-icon');
    this.exportButton.classList.add('maplibregl-export-control');
    this.exportButton.type = 'button';
    this.exportButton.addEventListener('click', () => {
      this.dispatch(mapActions.openExport(true));
      this.exportButton.style.display = 'none';
      this.exportContainer.style.display = 'block';
      this.toggleCrosshair(true);
      this.togglePrintableArea(true);
    });
    document.addEventListener('click', this.onDocumentClick);
    this.controlContainer.appendChild(this.exportButton);
    this.controlContainer.appendChild(this.exportContainer);

    const table = document.createElement('TABLE');
    table.className = 'print-table';

    const sizes = {};

    if (this.options.AllowedSizes) {
      this.options.AllowedSizes.forEach((size) => {
        const dimensions = Size[size];
        if (dimensions) {
          sizes[size] = Size[size];
        }
      });
    }
    const tr1 = this.createSelection(sizes, this.options.Local.PageSize, 'page-size', this.options.PageSize, (data, key) => JSON.stringify(data[key]));
    table.appendChild(tr1);

    const tr2 = this.createSelection(PageOrientation, this.options.Local.PageOrientation, 'page-orientation', this.options.PageOrientation, (data, key) => data[key]);
    table.appendChild(tr2);

    const tr3 = this.createSelection(Format, this.options.Local.Format, 'format-type', this.options.Format, (data, key) => data[key]);
    table.appendChild(tr3);

    const tr4 = this.createSelection(DPI, this.options.Local.DPI, 'dpi-type', this.options.DPI, (data, key) => data[key]);
    table.appendChild(tr4);

    this.exportContainer.appendChild(table);

    const generateButton = document.createElement('button');
    generateButton.type = 'button';
    generateButton.textContent = this.options.Local.Generate;
    generateButton.classList.add('generate-button');
    generateButton.addEventListener('click', () => {
      const pageSize = document.getElementById('mapbox-gl-export-page-size');
      const pageOrientation = document.getElementById('mapbox-gl-export-page-orientation');
      const formatType = document.getElementById('mapbox-gl-export-format-type');
      const dpiType = document.getElementById('mapbox-gl-export-dpi-type');
      const orientValue = pageOrientation.value;
      let pageSizeValue = JSON.parse(pageSize.value);
      if (orientValue === PageOrientation.Portrait) {
        pageSizeValue = pageSizeValue.reverse();
      }
      const mapGenerator = new MapGenerator(
        map,
        pageSizeValue,
        Number(dpiType.value),
        formatType.value,
        Unit.mm,
        this.options.Filename,
      );
      mapGenerator.generate();
      this.dispatch(mapActions.openExport(false));
      this.exportContainer.style.display = 'none';
      this.exportButton.style.display = 'block';
      this.toggleCrosshair(false);
      this.togglePrintableArea(false);
    });
    this.exportContainer.appendChild(generateButton);
    return this.controlContainer;
  }

  createSelection(
    data,
    title,
    type,
    defaultValue,
    converter,
  ) {
    const label = document.createElement('label');
    label.textContent = title;

    const content = document.createElement('select');
    content.setAttribute('id', `mapbox-gl-export-${type}`);
    content.style.width = '100%';
    Object.keys(data).forEach((key) => {
      const optionLayout = document.createElement('option');
      optionLayout.setAttribute('value', converter(data, key));
      optionLayout.appendChild(document.createTextNode(key));
      optionLayout.setAttribute('name', type);
      if (defaultValue === data[key]) {
        optionLayout.selected = true;
      }
      content.appendChild(optionLayout);
    });
    content.addEventListener('change', () => { this.updatePrintableArea(); });

    const tr1 = document.createElement('TR');
    const tdLabel = document.createElement('TD');
    const tdContent = document.createElement('TD');
    tdLabel.appendChild(label);
    tdContent.appendChild(content);
    tr1.appendChild(tdLabel);
    tr1.appendChild(tdContent);
    return tr1;
  }

  onRemove() {
    if (!this.controlContainer
      || !this.controlContainer.parentNode
      || !this.map
      || !this.exportButton) {
      return;
    }
    this.exportButton.removeEventListener('click', this.onDocumentClick);
    this.controlContainer.parentNode.removeChild(this.controlContainer);
    document.removeEventListener('click', this.onDocumentClick);

    if (this.crosshair !== undefined) {
      this.crosshair.destroy();
      this.crosshair = undefined;
    }

    this.map = undefined;
  }

  onDocumentClick(event) {
    if (
      this.controlContainer
      && !this.controlContainer.contains(event.target)
      && this.exportContainer
      && this.exportButton) {
      this.dispatch(mapActions.openExport(false));
      this.exportContainer.style.display = 'none';
      this.exportButton.style.display = 'block';
      this.toggleCrosshair(false);
      this.togglePrintableArea(false);
    }
  }

  toggleCrosshair(state) {
    if (this.options.Crosshair === true) {
      if (state === false) {
        if (this.crosshair !== undefined) {
          this.crosshair.destroy();
          this.crosshair = undefined;
        }
      } else {
        this.crosshair = new CrosshairManager(this.map);
        this.crosshair.create();
      }
    }
  }

  togglePrintableArea(state) {
    if (this.options.PrintableArea === true) {
      if (state === false) {
        if (this.printableArea !== undefined) {
          this.printableArea.destroy();
          this.printableArea = undefined;
        }
      } else {
        this.printableArea = new PrintableAreaManager(this.map);
        this.updatePrintableArea();
      }
    }
  }

  updatePrintableArea() {
    if (this.printableArea === undefined) {
      return;
    }
    const pageSize = document.getElementById('mapbox-gl-export-page-size');
    const pageOrientation = document.getElementById('mapbox-gl-export-page-orientation');
    const orientValue = pageOrientation.value;
    let pageSizeValue = JSON.parse(pageSize.value);
    if (orientValue === PageOrientation.Portrait) {
      pageSizeValue = pageSizeValue.reverse();
    }
    this.printableArea.updateArea(pageSizeValue[0], pageSizeValue[1]);
  }
}
