import React from 'react';
import {
  makeStyles, Paper, Tooltip,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CachedIcon from '@material-ui/icons/Cached';
import { useDispatch } from 'react-redux';
import { useTranslation } from './LocalizationProvider';
import { mapActions } from '../../store';
import { map } from '../../map/Map';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(25),
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
  eye: {
    color: '#E32636',
    animation: '$color 10s infinite alternate',
  },
  '@keyframes color': {
    '50%': {
      color: '#ebebeb',
    },
  },
}));

const UpdatingNeedableButton = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const handleButton = () => {
    dispatch(mapActions.updateZoomInitialized({
      center: [map.getCenter().lng, map.getCenter().lat],
      zoom: map.getZoom(),
    }));
    window.location.reload();
  };

  return (
    <>
      <Paper className={classes.trackingMode} elevation={3}>
        <Tooltip title={t('updateDataButton')}>
          <Avatar
            onClick={() => handleButton()}
            className={classes.avatar}
          >
            <CachedIcon className={classes.eye} />
          </Avatar>
        </Tooltip>
      </Paper>
    </>
  );
};

export default UpdatingNeedableButton;
