export default (size) => ({
  '&::-webkit-scrollbar': {
    width: size || 10,
    height: size || 10,
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0,0,0,0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.2)',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0,0,0,0.3)',
  },
  '&::-webkit-scrollbar-thumb:active': {
    background: 'rgba(0,0,0,.4)',
  },
});
