import { getSortedArray } from './formatter';

export const getIsAdmin = (state) => state.session.user?.administrator;

export const getUserId = (state) => state.session.user?.id;

const getSortedByStatus = (a, b) => {
  if (a.status === 'online') {
    return -1;
  }
  if (b.status === 'online') {
    return 1;
  }
  return 0;
};

export const getDevices = {
  sortByStatus: (a, b) => getSortedByStatus(a, b),
  sortByStatusReverse: (a, b) => getSortedByStatus(a, b),
  sortByGroup: (a, b) => getSortedArray(a.groupId, b.groupId),
  sortByGroupReverse: (a, b) => getSortedArray(a.groupId, b.groupId, true),
  sortByName: (a, b) => getSortedArray(a.name?.toLowerCase(), b.name?.toLowerCase()),
  sortByNameReverse: (a, b) => getSortedArray(a.name.toLowerCase(), b.name.toLowerCase(), true),
  sortByLastUpdate: (a, b) => getSortedArray(a.lastUpdate, b.lastUpdate, true),
  sortByLastUpdateReverse: (a, b) => getSortedArray(a.lastUpdate, b.lastUpdate),
};

export const getPosition = (id) => (state) => id && state.positions.items[id];

export const getIconSrc = (device) => `/images/icon/device/${device.icon || 'unknown'}.svg`;

export const sortPeriods = (a, b) => {
  const [valueA, timeA] = a.split('_');
  const [valueB, timeB] = b.split('_');
  if (timeA === timeB) {
    const numA = Number(valueA);
    const numB = Number(valueB);
    if (numA < numB) {
      return -1;
    }
    if (numA > numB) {
      return 1;
    }
    return 0;
  }
  if (timeA === 's') {
    return -1;
  }
  if (timeB === 's') {
    return 1;
  }
  if (timeA === 'h') {
    return 1;
  }
  return -1;
};
