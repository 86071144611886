import React from 'react';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { toTitleCase } from '../../../utils/formatter';

const TextIcon = ({ value, panel, onMouseEnter }) => {
  const getColorTextIcon = () => {
    if (panel[`on${toTitleCase(value)}`]) {
      return '#198cff';
    }
    return onMouseEnter === value ? '#000' : '#4d4d4d';
  };

  return <TextFieldsIcon style={{ color: getColorTextIcon() }} />;
};

export default TextIcon;
