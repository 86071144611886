import { useEffect, useRef } from 'react';

const useBufferedArray = (func, timeout = 1000, key = 'id') => {
  const timer = useRef();
  const isTimeout = useRef(true);
  const isFirstRender = useRef(true);
  const buffer = useRef([]);

  const delay = (source) => {
    buffer.current = [...buffer.current, ...source];

    if (buffer.current.length > 0 && isTimeout.current) {
      isTimeout.current = false;

      timer.current = setTimeout(() => {
        try {
          const lastUniqueValuesArray = new Map(buffer.current.map((item) => [item[key], item]));
          const isUpdate = source.length === 0 && buffer.current.length > 0;

          if (isUpdate) {
            func([...lastUniqueValuesArray.values()]);
            buffer.current = [];
          }

          isTimeout.current = true;
          delay([]);
        } catch (error) {
          clearTimeout(timer);
          console.warn('Ошибка при кэшировании при обработке кэшированных данных.', error);
        }
      }, isFirstRender.current ? 0 : timeout);

      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
    }
  };

  useEffect(() => () => clearTimeout(timer), []);

  return delay;
};

export default useBufferedArray;
