export const DELAY_TO_CHECK_OF_REQUIRED_RESOURCES = 300;
export const PATH_DEFAULT_TO_ICONS = '/images/icon/device/{name}.svg';

export const TYPE_SETTINGS = {
  Color: 'settings_1',
  ColorAndBackground: 'settings_2',
  ColorAndDefaultBackground: 'settings_3',
  ColorAndStrokeAndDefaultBackground: 'settings_4',
};

/**
 * Список типов-категорий, где тип - это обработчик с настройками, категории - это массив к которым применять настройки.
 * По умолчанию применяется TYPE_SETTINGS.Color.
 */
export const TYPES_EQUAL_TO_CATEGORIES = [
  [
    TYPE_SETTINGS.Color,
    ['marker', 'detected_object'],
  ],
  [
    TYPE_SETTINGS.ColorAndBackground,
    [],
  ],
  [
    TYPE_SETTINGS.ColorAndDefaultBackground,
    [],
  ],
  [
    TYPE_SETTINGS.ColorAndStrokeAndDefaultBackground,
    ['radar', 'uav', 'device', 'anti_uav'],
  ],
];
