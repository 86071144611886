/* eslint-disable class-methods-use-this */
import { jsPDF } from 'jspdf';
import { Map as MaplibreMap } from 'maplibre-gl';

export const Format = {
  JPEG: 'jpg',
  PNG: 'png',
  PDF: 'pdf',
  SVG: 'svg',
};

export const Unit = {
  // don't use inch unit. because page size setting is using mm unit.
  in: 'in',
  mm: 'mm',
};

export const Size = {
  A4: [297, 210],
  A5: [210, 148],
  A6: [148, 105],
  B5: [250, 176],
  B6: [176, 125],

};

export const PageOrientation = {
  Landscape: 'landscape',
  Portrait: 'portrait',
};

export const DPI = {
  72: 72,
  96: 96,
  200: 200,
  300: 300,
  400: 400,
};

export default class MapGenerator {
  /**
   * Constructor
   * @param map MaplibreMap object
   * @param size layout size. default is A4
   * @param dpi dpi value. deafult is 300
   * @param format image format. default is PNG
   * @param unit length unit. default is mm
   * @param fileName file name. default is 'map'
   */
  constructor(
    map,
    size = Size.A4,
    dpi = 300,
    format = Format.PNG.toString(),
    unit = Unit.mm,
    fileName = 'map',
  ) {
    this.map = map;
    this.width = size[0];
    this.height = size[1];
    this.dpi = dpi;
    this.format = format;
    this.unit = unit;
    this.fileName = fileName;
  }

  /**
   * Generate and download Map image
   */
  generate() {
    const this_ = this;

    // see documentation for JS Loading Overray library
    // https://js-loading-overlay.muhdfaiz.com
    // @ts-ignore

    // Calculate pixel ratio
    const actualPixelRatio = window.devicePixelRatio;
    Object.defineProperty(window, 'devicePixelRatio', {
      get() { return this_.dpi / 96; },
    });
    // Create map container
    const hidden = document.createElement('div');
    hidden.className = 'hidden-map';
    document.body.appendChild(hidden);
    const container = document.createElement('div');
    container.style.width = this.toPixels(this.width);
    container.style.height = this.toPixels(this.height);
    hidden.appendChild(container);

    const style = this.map.getStyle();
    if (style && style.sources) {
      const { sources } = style;
      Object.keys(sources).forEach((name) => {
        const src = sources[name];
        Object.keys(src).forEach((key) => {
          // delete properties if value is undefined.
          // for instance, raster-dem might has undefined value in "url" and "bounds"
          if (!src[key]) delete src[key];
        });
      });
    }

    // Render map
    const renderMap = new MaplibreMap({
      container,
      style,
      center: this.map.getCenter(),
      zoom: this.map.getZoom(),
      bearing: this.map.getBearing(),
      pitch: this.map.getPitch(),
      interactive: false,
      preserveDrawingBuffer: true,
      fadeDuration: 0,
      attributionControl: false,
      // hack to read transfrom request callback function
      transformRequest: (this.map)._requestManager._transformRequestFn,
    });

    // @ts-ignore
    const images = (this.map.style.imageManager || {}).images || [];
    Object.keys(images).forEach((key) => {
      renderMap.addImage(key, images[key].data);
    });

    renderMap.once('idle', () => {
      const canvas = renderMap.getCanvas();
      const fileName = `${this.fileName}.${this_.format}`;
      switch (this_.format) {
        case Format.PNG:
          this_.toPNG(canvas, fileName);
          break;
        case Format.JPEG:
          this_.toJPEG(canvas, fileName);
          break;
        case Format.PDF:
          this_.toPDF(renderMap, fileName);
          break;
        case Format.SVG:
          this_.toSVG(canvas, fileName);
          break;
        default:
          console.error(`Invalid file format: ${this_.format}`);
          break;
      }

      renderMap.remove();
      if (hidden.parentNode) {
        hidden.parentNode.removeChild(hidden);
      }
      Object.defineProperty(window, 'devicePixelRatio', {
        get() { return actualPixelRatio; },
      });

      // @ts-ignore
    });
  }

  /**
   * Convert canvas to PNG
   * @param canvas Canvas element
   * @param fileName file name
   */
  toPNG(canvas, fileName) {
    const a = document.createElement('a');
    a.href = canvas.toDataURL();
    a.download = fileName;
    a.click();
    a.remove();
  }

  /**
   * Convert canvas to JPEG
   * @param canvas Canvas element
   * @param fileName file name
   */
  toJPEG(canvas, fileName) {
    const uri = canvas.toDataURL('image/jpeg', 0.85);
    // @ts-ignore
    if (canvas.msToBlob) {
      // for IE11
      const blob = this.toBlob(uri);
      (window.navigator).msSaveBlob(blob, fileName);
    } else {
      // for other browsers except IE11
      const a = document.createElement('a');
      a.href = uri;
      a.download = fileName;
      a.click();
      a.remove();
    }
  }

  /**
   * Convert Map object to PDF
   * @param map Map object
   * @param fileName file name
   */
  toPDF(map, fileName) {
    const canvas = map.getCanvas();
    const pdf = new jsPDF({
      orientation: this.width > this.height ? 'l' : 'p',
      unit: this.unit,
      compress: true,
    });

    pdf.addImage(canvas.toDataURL('image/png'), 'png', 0, 0, this.width, this.height, undefined, 'FAST');

    const { lng, lat } = map.getCenter();
    pdf.setProperties({
      title: map.getStyle().name,
      subject: `center: [${lng}, ${lat}], zoom: ${map.getZoom()}`,
      creator: 'Mapbox GL Export Plugin',
      author: '(c)Mapbox, (c)OpenStreetMap',
    });

    pdf.save(fileName);
  }

  /**
   * Convert canvas to SVG
   * @param canvas Canvas element
   * @param fileName file name
   */
  toSVG(canvas, fileName) {
    const uri = canvas.toDataURL('image/png');

    const pxWidth = Number(this.toPixels(this.width, this.dpi).replace('px', ''));
    const pxHeight = Number(this.toPixels(this.height, this.dpi).replace('px', ''));

    const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink" 
      version="1.1" 
      width="${pxWidth}" 
      height="${pxHeight}" 
      viewBox="0 0 ${pxWidth} ${pxHeight}" 
      xml:space="preserve">
        <image style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  
      xlink:href="${uri}" width="${pxWidth}" height="${pxHeight}"></image>
    </svg>`;

    const a = document.createElement('a');
    a.href = `data:application/xml,${encodeURIComponent(svg)}`;
    a.download = fileName;
    a.click();
    a.remove();
  }

  /**
   * Convert mm/inch to pixel
   * @param length mm/inch length
   * @param conversionFactor DPI value. default is 96.
   */
  toPixels(length, conversionFactor = 96) {
    if (this.unit === Unit.mm) {
      conversionFactor /= 25.4;
    }
    return `${conversionFactor * length}px`;
  }

  /**
   * Convert base64 to Blob
   * @param base64 string value for base64
   */
  toBlob(base64) {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i += 1) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], { type: 'image/png' });
    return blob;
  }
}
