import React from 'react';
import {
  AccordionDetails, Checkbox, FormControlLabel, makeStyles, useTheme, Typography, Button, ButtonGroup, Tooltip,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { useTranslation } from './LocalizationProvider';
import SelectField from '../form/SelectField';
import categoryParamsStructure from '../static/categoryParamsStructure';
import { getCategory } from '../utils/formatter';
import { getButtonControlModeColor } from './SmartTable/funcs/calculateCellValue';
import { getPosition } from '../utils/selectors';
import { devicesActions, tailActions } from '../../store';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const ExtraSettings = ({
  item, categoryParams, setCategoryParams, setIsChanged,
}) => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const devicesParams = useSelector((state) => state.devices.itemsParams);
  const tailSelectedDevices = useSelector((state) => state.tail.selectedDevices);
  const tailSelectedCategories = useSelector((state) => state.tail.selectedCategories);
  const position = useSelector(getPosition(item?.id));
  const device = useSelector((state) => state.devices.items[position?.deviceId]);

  const getDefaultValue = (field) => categoryParams[field]
    ?? categoryParamsStructure[getCategory(item)].model[item.model || 'default'].writen[field];

  const getIconTextButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (devicesParams[position?.deviceId]?.iconText ?? null) === val ? '#deefd8' : '',
  });

  const getTailButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (tailSelectedDevices[position?.deviceId] ?? null) === val ? '#deefd8' : '',
  });

  const changeVisibleIconText = (val) => {
    dispatch(devicesActions.changeItemParam({ [position.deviceId]: { iconText: val } }));
  };

  const changeTail = (val) => {
    dispatch(tailActions.changeDevice({ [position.deviceId]: val }));
    if (val === false || (val === null && tailSelectedCategories[getCategory(device)] === false)) {
      dispatch(tailActions.remove([position.deviceId]));
    } else {
      dispatch(tailActions.changeState({[ position.deviceId]: true }));
    }
  };

  switch (item?.category) {
    case 'radar':
      return (
        <AccordionDetails className={classes.details}>
          <Typography style={{ marginBottom: '10px' }}>{item.name}</Typography>
          <SelectField
            margin="normal"
            value={getDefaultValue('radarColor')}
            emptyValue={null}
            onChange={(event) => {
              setCategoryParams({ ...categoryParams, radarColor: event.target.value });
              setIsChanged(true);
            }}
            data={Object.keys(theme.palette.tracks).map((color) => ({
              id: theme.palette.tracks[color],
              name: <FiberManualRecordIcon style={{ color: theme.palette.tracks[color] }} />,
            }))}
            label={t('radarColor')}
            variant="filled"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!getDefaultValue('showRadarArea')}
                onChange={
                  (e) => {
                    setCategoryParams({ ...categoryParams, showRadarArea: e.target.checked });
                    setIsChanged(true);
                  }
                }
              />
            )}
            label={t('deviceShowRadarArea')}
          />
          <Typography style={{ marginTop: '10px' }}>{t('panelTextPositions')}</Typography>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => changeVisibleIconText(null)}>
              <Typography variant="caption">{t('auto')}</Typography>
            </Button>
            <Tooltip title={t('sharedShow')}>
              <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => changeVisibleIconText(true)}>
                <TextFieldsIcon style={{ color: '#198cff' }} size="small" />
              </Button>
            </Tooltip>
            <Tooltip title={t('sharedHide')}>
              <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => changeVisibleIconText(false)}>
                <TextFieldsIcon size="small" />
              </Button>
            </Tooltip>
          </ButtonGroup>

          <Typography style={{ marginTop: '10px' }}>{t('tail')}</Typography>
          <ButtonGroup fullWidth variant="outlined" size="small">
            <Button size="small" style={getTailButtonStyle(null)} onClick={() => changeTail(null)}>
              <Typography variant="caption">{t('auto')}</Typography>
            </Button>
            <Tooltip title={t('sharedShow')}>
              <Button size="small" style={getTailButtonStyle(true)} onClick={() => changeTail(true)}>
                <img
                  src="/images/icon/switch/tail-1.svg"
                  alt=""
                />
              </Button>
            </Tooltip>
            <Tooltip title={t('sharedHide')}>
              <Button size="small" style={getTailButtonStyle(false)} onClick={() => changeTail(false)}>
                <img
                  src="/images/icon/switch/tail-off.svg"
                  alt=""
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </AccordionDetails>
      );

    case 'anti_uav':
      switch (item.model) {
        case 'strizh3': {
          return (
            <>
              <Typography style={{ marginBottom: '10px' }}>{item.name}</Typography>
              <Typography>{t('deviceControlMode')}</Typography>
              <ToggleButtonGroup
                style={{ width: '100%', display: 'flex', justifyItems: 'center' }}
                exclusive
                value={categoryParams.controlMode}
                onChange={(e) => {
                  setCategoryParams({ ...categoryParams, controlMode: e.target.closest('button').value });
                  setIsChanged(true);
                }}
              >
                <ToggleButton
                  value="manual"
                  style={{ background: (categoryParams.controlMode === 'manual' ? getButtonControlModeColor(categoryParams.controlMode) : ' '), flexGrow: 1, width: '30%' }}
                >
                  {t('controlModeManual')}
                </ToggleButton>

                <ToggleButton
                  value="auto"
                  style={{ background: (categoryParams.controlMode === 'auto' ? getButtonControlModeColor(categoryParams.controlMode) : ' '), flexGrow: 1, width: '30%' }}
                >
                  {t('controlModeAutoShort')}
                </ToggleButton>

                <ToggleButton
                  value="auto_with_delay"
                  style={{ background: (categoryParams.controlMode === 'auto_with_delay' ? getButtonControlModeColor(categoryParams.controlMode) : ' '), flexGrow: 1, width: '40%' }}
                >
                  {t('controlModeAutoWithDelayShort')}
                </ToggleButton>
              </ToggleButtonGroup>
              {categoryParams.controlMode === 'manual'
                ? (
                  <>
                    <Typography style={{ marginTop: '10px' }}>{t('deviceWorkMode')}</Typography>
                    <ToggleButtonGroup
                      style={{ width: '100%', display: 'flex', justifyItems: 'center' }}
                      value={categoryParams.workMode}
                      exclusive
                      onChange={(e) => {
                        setCategoryParams({ ...categoryParams, workMode: e.target.closest('button').value });
                        setIsChanged(true);
                      }}
                    >
                      <ToggleButton
                        value="idle"
                        style={{ background: (categoryParams.workMode === 'idle' ? getButtonControlModeColor(categoryParams.workMode) : ' '), flexGrow: 1 }}
                      >
                        {t('idle')}
                      </ToggleButton>

                      <ToggleButton
                        value="detection"
                        style={{ background: (categoryParams.workMode === 'detection' ? getButtonControlModeColor(categoryParams.workMode) : ' '), flexGrow: 1 }}
                      >
                        {t('workModeDetection')}
                      </ToggleButton>

                      <ToggleButton
                        value="suppression"
                        style={{ background: (categoryParams.workMode === 'suppression' ? getButtonControlModeColor(categoryParams.workMode) : ' '), flexGrow: 1 }}
                      >
                        {t('workModeSuppression')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </>
                )
                : (<></>)}

              <Typography style={{ marginTop: '10px' }}>{t('panelTextPositions')}</Typography>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => changeVisibleIconText(null)}>
                  <Typography variant="caption">{t('auto')}</Typography>
                </Button>
                <Tooltip title={t('sharedShow')}>
                  <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => changeVisibleIconText(true)}>
                    <TextFieldsIcon style={{ color: '#198cff' }} size="small" />
                  </Button>
                </Tooltip>
                <Tooltip title={t('sharedHide')}>
                  <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => changeVisibleIconText(false)}>
                    <TextFieldsIcon size="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Typography style={{ marginTop: '10px' }}>{t('tail')}</Typography>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={getTailButtonStyle(null)} onClick={() => changeTail(null)}>
                  <Typography variant="caption">{t('auto')}</Typography>
                </Button>
                <Tooltip title={t('sharedShow')}>
                  <Button size="small" style={getTailButtonStyle(true)} onClick={() => changeTail(true)}>
                    <img
                      src="/images/icon/switch/tail-1.svg"
                      alt=""
                    />
                  </Button>
                </Tooltip>
                <Tooltip title={t('sharedHide')}>
                  <Button size="small" style={getTailButtonStyle(false)} onClick={() => changeTail(false)}>
                    <img
                      src="/images/icon/switch/tail-off.svg"
                      alt=""
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </>
          );
        }
        default:
          return (
            <>
              <Typography style={{ marginBottom: '10px' }}>{item.name}</Typography>
              <Typography>{t('deviceWorkMode')}</Typography>
              <ToggleButtonGroup
                style={{ width: '100%', display: 'flex', justifyItems: 'center' }}
                value={categoryParams.workMode}
                exclusive
                onChange={(e) => {
                  setCategoryParams({ ...categoryParams, workMode: e.target.closest('button').value });
                  setIsChanged(true);
                }}
              >
                <ToggleButton
                  value="detection"
                  style={{ background: (categoryParams.workMode === 'detection' ? getButtonControlModeColor(categoryParams.workMode) : ' '), flexGrow: 1 }}
                >
                  {t('workModeDetection')}
                </ToggleButton>

                <ToggleButton
                  value="suppression"
                  style={{ background: (categoryParams.workMode === 'suppression' ? getButtonControlModeColor(categoryParams.workMode) : ' '), flexGrow: 1 }}
                >
                  {t('workModeSuppression')}
                </ToggleButton>
              </ToggleButtonGroup>

              <Typography style={{ marginTop: '10px' }}>{t('panelTextPositions')}</Typography>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => changeVisibleIconText(null)}>
                  <Typography variant="caption">{t('auto')}</Typography>
                </Button>
                <Tooltip title={t('sharedShow')}>
                  <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => changeVisibleIconText(true)}>
                    <TextFieldsIcon style={{ color: '#198cff' }} size="small" />
                  </Button>
                </Tooltip>
                <Tooltip title={t('sharedHide')}>
                  <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => changeVisibleIconText(false)}>
                    <TextFieldsIcon size="small" />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <Typography style={{ marginTop: '10px' }}>{t('tail')}</Typography>
              <ButtonGroup fullWidth variant="outlined" size="small">
                <Button size="small" style={getTailButtonStyle(null)} onClick={() => changeTail(null)}>
                  <Typography variant="caption">{t('auto')}</Typography>
                </Button>
                <Tooltip title={t('sharedShow')}>
                  <Button size="small" style={getTailButtonStyle(true)} onClick={() => changeTail(true)}>
                    <img
                      src="/images/icon/switch/tail-1.svg"
                      alt=""
                    />
                  </Button>
                </Tooltip>
                <Tooltip title={t('hideTitle')}>
                  <Button size="small" style={getTailButtonStyle(false)} onClick={() => changeTail(false)}>
                    <img
                      src="/images/icon/switch/tail-off.svg"
                      alt=""
                    />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </>
          );
      }
    default:
      return null;
  }
};

export default ExtraSettings;
