import React from 'react';
import SubjectIcon from '@material-ui/icons/Subject';
import { Avatar, Paper, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(24),
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
  },
  icon: {
    color: '#000',
  },
}));

const PositionDataButton = ({ setPositionDataVisible }) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <Paper className={classes.trackingMode} elevation={3}>
      <Tooltip title={t('positionDataButtonTitle')}>
        <Avatar
          onClick={() => setPositionDataVisible(true)}
          className={classes.avatar}
        >
          <SubjectIcon className={classes.icon} />
        </Avatar>
      </Tooltip>
    </Paper>
  );
};

export default PositionDataButton;
