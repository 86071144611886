const createBody = (linkIds, permission, reverse) => {
  const body = [];
  linkIds.forEach((linkId) => {
    const item = {};
    if (reverse || permission.reverse) {
      item[permission.keyLink] = linkId;
      item[permission.keyBase] = permission.baseId;
    } else {
      item[permission.keyBase] = permission.baseId;
      item[permission.keyLink] = linkId;
    }
    body.push(item);
  });
  return body;
};

// eslint-disable-next-line import/prefer-default-export
export const savePermissions = async (permission, reverse) => {
  const oldValue = permission.old;
  const newValue = permission.linked;
  fetch('/api/permissions', {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(createBody(Array.from(oldValue).filter((it) => !newValue.has(it)), permission, reverse)),
  });
  fetch('/api/permissions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(createBody(Array.from(newValue).filter((it) => !oldValue.has(it)), permission, reverse)),
  });
  // await Promise.all(results);
};
