import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PinDropIcon from '@material-ui/icons/PinDrop';
import OpacityIcon from '@material-ui/icons/Opacity';
import StraightenIcon from '@material-ui/icons/Straighten';
import { toTitleCase } from '../../../utils/formatter';

const ComponentIcon = ({
  panel, value, onMouseEnter, addMarkerMode, rulerMode, item, parrent, child,
}) => {
  const getIconSrc = (value, check) => {
    let iconName;
    if (child && child === check) {
      iconName = `${value}-1`;
    } else if (check && !child) {
      iconName = `${value}-${Number(check)}`;
    } else if (onMouseEnter === value) {
      iconName = `${value}-off-enter`;
    } else {
      iconName = `${value}-off`;
    }
    return `/images/icon/switch/${iconName}.svg`;
  };

  const getIconAddMarkerSrc = () => {
    let iconName;
    if (addMarkerMode) {
      iconName = 'marker-add-1';
    } else if (onMouseEnter === 'markerAdd') {
      iconName = 'marker-add-off-enter';
    } else {
      iconName = 'marker-add-off';
    }
    return `/images/icon/switch/${iconName}.svg`;
  };

  const getColorRulerIcon = () => {
    if (rulerMode) {
      return '#198cff';
    }
    return onMouseEnter === 'ruler' ? '#000' : '#4d4d4d';
  };

  const getColorExtraLayersIcon = () => (onMouseEnter === 'extraLayers' ? '#000' : '#4d4d4d');

  const getColorOpacityMapsIcon = () => (onMouseEnter === 'opacityMaps' ? '#000' : '#4d4d4d');

  switch (value) {
    case 'ruler':
      return <StraightenIcon style={{ color: getColorRulerIcon() }} />;
    case 'tail':
      return (
        <img
          src={getIconSrc(value, item)}
          alt=""
        />
      );
    case 'extra':
      return <MoreHorizIcon style={{ color: '#4d4d4d' }} />;
    case 'opacityMaps':
      return <OpacityIcon style={{ color: getColorOpacityMapsIcon() }} />;
    case 'extraLayers':
      return <PinDropIcon style={{ color: getColorExtraLayersIcon() }} />;
    case 'markerAdd':
      return (
        <img
          src={getIconAddMarkerSrc()}
          alt=""
        />
      );
    default:
      return (
        <img
          src={getIconSrc(value, panel[`on${toTitleCase(parrent || value)}`])}
          alt=""
        />
      );
  }
};

export default ComponentIcon;
