import { useEffect, useRef, useState } from 'react';
import { length } from '@turf/turf';
import { map } from './Map';
import { getBeforeId } from './funcs/pathFuncs';

const RulerMap = ({ rulerMode, setDistance }) => {
  const id = 'measure';
  const idPoints = `${id}-points`;
  const idLines = `${id}-lines`;

  const [features, setFeatures] = useState([]);
  const featuresRef = useRef(features);

  useEffect(() => {
    if (!rulerMode) {
      map.getCanvas().style.cursor = 'grab';
    }
  }, [rulerMode]);

  useEffect(() => {
    if (!rulerMode) {
      setDistance(null);
      setFeatures([]);
    }
  }, [rulerMode]);

  useEffect(() => {
    featuresRef.current = features;
  }, [features]);

  const handleClick = (e) => {
    const geojson = {
      type: 'FeatureCollection',
      features: [...featuresRef.current],
    };
    const linestring = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    };

    const featureslayer = map.queryRenderedFeatures(e.point, {
      layers: [idPoints],
    });

    // Remove the linestring from the group
    // so we can redraw it based on the points collection.
    if (geojson.features.length > 1) geojson.features.pop();

    // Clear the distance container to populate it with a new value.
    setDistance(null);

    // If a feature was clicked, remove it from the map.
    if (featureslayer.length) {
      const idPoint = featureslayer[0].properties.id;
      geojson.features = geojson.features.filter(
        (point) => point.properties.id !== idPoint,
      );
    } else {
      const point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        properties: {
          id: String(new Date().getTime()),
        },
      };

      geojson.features.push(point);
    }

    if (geojson.features.length > 1) {
      linestring.geometry.coordinates = geojson.features.map(
        (point) => point.geometry.coordinates,
      );

      geojson.features.push(linestring);
      setDistance(length(linestring));
    }
    setFeatures([...geojson.features]);
  };

  const handleMouseMove = (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [idPoints],
    });
    // Change the cursor to a pointer when hovering over a point on the map.
    // Otherwise cursor is a crosshair.
    map.getCanvas().style.cursor = features.length
      ? 'pointer'
      : 'crosshair';
  };

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      id: idPoints,
      type: 'circle',
      source: id,
      paint: {
        'circle-radius': 3.5,
        'circle-color': '#fff',
        'circle-stroke-width': 1.5,
        'circle-stroke-color': '#000',
      },
      filter: ['in', '$type', 'Point'],
    }, getBeforeId(idPoints));
    map.addLayer({
      id: idLines,
      type: 'line',
      source: id,
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#767676',
        'line-width': 2.5,
      },
      filter: ['in', '$type', 'LineString'],
    }, getBeforeId(idLines));

    if (rulerMode) {
      map.on('click', handleClick);
    }
    if (rulerMode) {
      map.on('mousemove', handleMouseMove);
    }

    return () => {
      if (rulerMode) {
        map.off('mousemove', handleMouseMove);
      }
      if (rulerMode) {
        map.off('click', handleClick);
      }
      if (map.getLayer(idLines)) {
        map.removeLayer(idLines);
      }
      if (map.getLayer(idPoints)) {
        map.removeLayer(idPoints);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [rulerMode]);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: [...featuresRef.current],
    });
  }, [features]);

  return null;
};

export default RulerMap;
