export const EMPTY = 0;
export const FULL = 1;
export const NOT_FULL = 2;

/**
 * Время сколько нужно удерживать нажатым клик на категории, чтобы появился режим изменения порядка категорий устройств.
 */
export const CLICK_HOLDING_TIME = 650;

export const DEVICES_BUFFERING_TIME = 50;
export const DELAY_SEARCHING_TIME = 350;

// Настройки компонента виртуального списка.
export const DEVICE_HEADER_HEIGHT = 58;
export const DEVICE_ROW_HEIGHT = 64;
/**
 * Количество элементов, которые будут отображаться за пределами видимости списка.
 */
export const OVERSCAN_COUNT = 10;

/**
 * Содержит список условий для определения категории устройства.
 * Добавление в указанный заголовок, ограничено списком категорий (devices.categories).
 * По умолчанию берет категорию устройства, если категория отсутствует, то пропускает добавление.
 */
export const HANDLERS_OF_DEVICE_LIST_STRUCTURE = [
  ['device', (item) => (item.category === null)],
];

export const LINKS_TO_CATEGORIES_FOR_HEADERS = {
  anti_uav: '/objects/antiuavs',
  detected_object: '/objects/detected',
  radar: '/objects/radars',
};

export const HEADER_MENU_SETTINGS = {
  Y: {
    Full: 70,
    Tablet: 66,
  },
  X: {
    Full: 0,
    Tablet: 350,
  },
};
