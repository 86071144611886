import React from 'react';
import { IconButton } from '@material-ui/core';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  superCheckbox: {
    padding: theme.spacing(0.5),
  },
}));

const SuperMenuItem = ({ all }) => {
  const classes = useStyles();

  return all ? (
    <IconButton className={classes.superCheckbox}>
      <LibraryAddCheckIcon />
    </IconButton>
  ) : (
    <IconButton className={classes.superCheckbox}>
      <LibraryAddCheckOutlinedIcon />
    </IconButton>
  );
};

export default SuperMenuItem;
