import categoryParamsStructure from '../../static/categoryParamsStructure';

export default function validateItem(data) {
  if (!data.attributes) {
    data.attributes = {};
  }
  data.attributes.source = 'SmartTrack';
  if (data.attributes.latitude) {
    data.attributes.latitude = Number(data.attributes.latitude);
  }
  if (data.attributes.longitude) {
    data.attributes.longitude = Number(data.attributes.longitude);
  }
  if (data.attributes.altitude) {
    data.attributes.altitude = Number(data.attributes.altitude);
  }
  if (data.attributes.azimuth) {
    data.attributes.azimuth = Number(data.attributes.azimuth);
  }

  if (data.attributes?.categoryParams) {
    let type;
    Object.keys(data.attributes.categoryParams).forEach((key) => {
      if (key.startsWith('ip')) {
        type = categoryParamsStructure[data.category].model[data.model || 'default'].fields.ip;
      } else if (key.startsWith('port')) {
        type = categoryParamsStructure[data.category].model[data.model || 'default'].fields.port;
      } else if (key.startsWith('radius')) {
        type = categoryParamsStructure[data.category].model[data.model || 'default'].fields.radius;
      } else if (key.startsWith('azimuth')) {
        type = categoryParamsStructure[data.category].model[data.model || 'default'].fields.azimuth;
      } else {
        type = categoryParamsStructure[data.category].model[data.model || 'default'].fields[key];
      }
      switch (type) {
        case 'number':
          data.attributes.categoryParams[key] = Number(data.attributes?.categoryParams[key]);
          break;
        case 'string':
          data.attributes.categoryParams[key] = String(data.attributes?.categoryParams[key]);
          break;
        case 'boolean':
          data.attributes.categoryParams[key] = Boolean(data.attributes?.categoryParams[key]);
          break;
        default:
      }
    });
  }
  return data;
}
