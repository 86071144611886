import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { map } from './Map';
import { geofenceToFeature, geometryToArea } from './mapUtil';
import { useTranslation } from '../common/components/LocalizationProvider';
import logout from '../common/utils/logout';
import mapboxTheme from '../common/theme/mapboxTheme';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  userProperties: true,
  styles: [...mapboxTheme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

const GeofenceEditMap = ({ setSaveMode, refreshGeofences }) => {
  const geofences = useSelector((state) => Object.values(state.geofences.items));
  const stateSyncGeofences = useSelector((state) => state.geofences.stateSyncGeofences);
  const currentGeofences = useRef(geofences || []);
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const create = async (event) => {
    const feature = event.features[0];
    const newItem = {
      name: t('defaultGeofenceName'),
      area: geometryToArea(feature.geometry),
    };
    draw.delete(feature.id);
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      setSaveMode(item.id);
      refreshGeofences();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const update = async (event) => {
    const feature = event.features[0];
    const item = currentGeofences.current.find((i) => i.id === feature?.id);
    if (item) {
      const updatedItem = {
        ...item,
        area: geometryToArea(feature.geometry),
      };
      const response = await fetch(`/api/geofences/${feature.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedItem),
      });
      if (response.ok) {
        refreshGeofences();
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  };

  const deleteG = async (event) => {
    const feature = event.features[0];
    const response = await fetch(`/api/geofences/${feature.id}`, { method: 'DELETE' });
    if (response.ok) {
      refreshGeofences();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  useEffect(() => currentGeofences.current = geofences, [stateSyncGeofences]);

  useEffect(() => {
    refreshGeofences();

    map.addControl(draw, 'top-right');

    document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon')
      .setAttribute('title', t('createGeofenceTitle'));
    document.querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash')
      .setAttribute('title', t('deleteGeofenceTitle'));

    map.on('draw.create', create);

    map.on('draw.delete', deleteG);

    map.on('draw.update', update);

    return () => {
      map.off('draw.create', create);

      map.off('draw.delete', deleteG);

      map.off('draw.update', update);
      map.removeControl(draw);
    };
  }, []);

  useEffect(() => {
    draw.deleteAll();
    geofences.forEach((geofence) => {
      draw.add(geofenceToFeature(geofence));
    });
  }, [stateSyncGeofences]);

  return null;
};

export default GeofenceEditMap;
