import { useEffect, useState } from 'react';

export default (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (stickyValue) {
      try {
        return JSON.parse(stickyValue);
      } catch (SyntaxError) {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
