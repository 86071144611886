import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default () => {
  const t = useTranslation();

  return columnTemplates.settingsFields.notificationsSettings({
    t,
  });
};
