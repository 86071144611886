export default function useDevextremeTablesSettings(settingsName) {
  const LOCAL_STORAGE_NAME_TABLES_SETTINGS = 'devextremeTablesSettings';

  const getSettingsObject = () => {
    try {
      const settings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME_TABLES_SETTINGS));
      return settings || {};
    } catch (error) {
      console.warn(`Ошибка при получении настроек devextreme таблицы ${settingsName}.`, error);
      return {};
    }
  };

  const setSettingsObject = (devextremeTablesSettings) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_NAME_TABLES_SETTINGS, JSON.stringify(devextremeTablesSettings));
    } catch (error) {
      console.warn(`Ошибка при сохранении настроек devextreme для таблицы ${settingsName}.`, error);
    }
  };

  const get = () => {
    const settings = getSettingsObject();
    return settings[settingsName];
  };

  const save = (settings) => {
    const devextremeTablesSettings = getSettingsObject();
    devextremeTablesSettings[settingsName] = settings;
    setSettingsObject(devextremeTablesSettings);
  };

  const clear = () => {
    const devextremeTablesSettings = getSettingsObject();
    delete devextremeTablesSettings[settingsName];
    setSettingsObject(devextremeTablesSettings);
  };

  return {
    get, save, clear,
  };
}
