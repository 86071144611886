import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { makeStyles } from '@material-ui/core';

export default function TimeLineFragment({
  time, sliderZoomValue, isLast, isHavePoint,
}) {
  const useStylesTimeLimeFragment = makeStyles(() => ({
    timeLineFragmentHorizontalLine: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: isHavePoint.all ? '#92c1fd' : '#303030',
      width: '144px',
      height: '2px',
    },
    timeLineFragmentStartVericalLine: {
      backgroundColor: '#303030',
      width: '2px',
      height: '25px',
      marginTop: '-10px',
      position: 'relative',
    },
    timeLineFragmentStartText: {
      width: '75px',
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '25px',
      marginLeft: '-37px',
      userSelect: 'none',
    },
    timeLineFragmentVericalLine: {
      backgroundColor: isHavePoint.all ? '#92c1fd' : '#303030',
      width: '1px',
      height: '10px',
      position: 'relative',
    },
    timeLineFragmentStartWithPoint: {
      backgroundColor: '#0370f9',
      width: '24px',
      height: '2px',
      position: 'absolute',
      top: '10px',
      left: '2px',
    },
    timeLineFragmentLineWithPoint: {
      backgroundColor: '#0370f9',
      width: '24px',
      height: '2px',
      position: 'absolute',
      top: 0,
      left: '0px',
    },
  }));

  const classes = useStylesTimeLimeFragment([]);

  const timeText = moment(time, 'YYYY-MM-DD HH:mm:ss').format(`MMM D ${(sliderZoomValue === 1) || (sliderZoomValue === 0) ? '' : 'HH:mm'}`);
  let timeTextLast = 0;
  if (isLast) {
    switch (sliderZoomValue) {
      case 0:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(1, 'week').format('MMM D');
        break;
      case 1:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').format('MMM D');
        break;
      case 2:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(12, 'hour').format('MMM D HH:mm');
        break;
      case 3:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('MMM D HH:mm');
        break;
      case 4:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(2, 'hour').format('MMM D HH:mm');
        break;
      case 5:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(1, 'hour').format('MMM D HH:mm');
        break;
      case 6:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(30, 'minute').format('MMM D HH:mm');
        break;
      case 7:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(10, 'minute').format('MMM D HH:mm');
        break;
      case 8:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(5, 'minute').format('MMM D HH:mm');
        break;
      case 9:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(1, 'minute').format('MMM D HH:mm');
        break;
      default:
        timeTextLast = moment(time, 'YYYY-MM-DD HH:mm:ss').add(1, 'day').format('MMM D');
        break;
    }
  }
  return (
    <>
      {isLast
        ? (
          <div className={classes.timeLineFragmentHorizontalLine}>
            <div className={classes.timeLineFragmentStartVericalLine}>
              <div className={`${isHavePoint[1] ? classes.timeLineFragmentStartWithPoint : ''}`} />
              <p className={classes.timeLineFragmentStartText}>{timeText}</p>
            </div>

            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[2] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[3] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[4] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[5] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[6] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>

            <div className={classes.timeLineFragmentStartVericalLine}>
              <p className={classes.timeLineFragmentStartText}>{timeTextLast}</p>
            </div>
          </div>
        )
        : (
          <div className={classes.timeLineFragmentHorizontalLine}>
            <div className={classes.timeLineFragmentStartVericalLine}>
              <div className={`${isHavePoint[1] ? classes.timeLineFragmentStartWithPoint : ''}`} />
              <p className={classes.timeLineFragmentStartText}>{timeText}</p>
            </div>

            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[2] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[3] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[4] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[5] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div className={classes.timeLineFragmentVericalLine}>
              <div className={`${isHavePoint[6] ? classes.timeLineFragmentLineWithPoint : ''}`} />
            </div>
            <div> </div>

          </div>
        )}
    </>

  );
}
