import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import {
  devicesActions, tailActions,
} from './store';
import lifetimes from './common/static/lifetimes';
import { filterDevices } from './views/Main/filterDevices';
import { getCategory } from './common/utils/formatter';

const ClosedDeviceInitialization = ({ closedDevicesInited, setClosedDevicesInited, groupInited }) => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => Object.values(state.devices.items));
  const groups = useSelector((state) => state.groups.items);

  const categoryLifetime = useSelector((state) => state.session.server?.attributes.categoryLifetime ?? lifetimes.categories);
  const temporaryLifetime = useSelector((state) => state.session.server?.attributes.temporaryLifetime ?? lifetimes.temporary);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const deviceInited = useSelector((state) => state.devices.deviceInited);

  useEffect(() => {
    if (!closedDevicesInited) {
      if (deviceInited && groupInited) {
        const newClosedDevices = { ...closedDevices };
        const tailQueue = {};
        devices.forEach((device) => {
          try {
            if (newClosedDevices[device.id] === undefined) {
              if (filterDevices(device, { title: getCategory(device.category) }, groups, '', categoryLifetime, temporaryLifetime)) {
                if (newClosedDevices[device.id] === true) {
                  tailQueue[device.id] = true
                }
                newClosedDevices[device.id] = false;
              } else {
                if (newClosedDevices[device.id] === false) {
                  tailQueue[device.id] = false
                }
                newClosedDevices[device.id] = true;
              }
            }
          } catch (error) {
            console.log(error);
            console.log(device);
          }
        });
        dispatch(devicesActions.turnAll(newClosedDevices));
        dispatch(tailActions.changeState(tailQueue));
        setClosedDevicesInited(true);
      }
    }
  }, [deviceInited, groupInited]);

  return null;
};

export default ClosedDeviceInitialization;
