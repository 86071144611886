import React, { useState } from 'react';
import {
  Badge, CircularProgress, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Paper,
  Popover, Tooltip, Typography,
} from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from './LocalizationProvider';
import { smsActions } from '../../store';
import { formatDate } from '../utils/formatter';
import logout from '../utils/logout';

const useStyles = makeStyles((theme) => ({
  trackingMode: {
    borderRadius: '10px',
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(17),
    minWidth: 0,
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
    cursor: 'pointer',
    margin: theme.spacing(1),
  },
  eye: {
    color: '#E32636',
  },
  listItem: {
    backgroundColor: 'white',
    width: '100%',
  },
  list: {
    width: theme.spacing(40),
    maxWidth: '90vh',
    maxHeight: '50%',
  },
  secondaryItem: {
    color: '#737373',
    wordWrap: 'break-word',
  },
  dateText: {
    top: theme.spacing(3.5),
    right: 0,
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: '100%',
  },
  progress: {
    margin: theme.spacing(1.5),
  },
  hideButton: {
    color: '#FFA07A',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}));

const NewMessages = ({ newSms }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const devices = useSelector((state) => state.devices.items);
  const dispatch = useDispatch();
  const history = useHistory();
  const [progress, setProgress] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickItem = async (messageId) => {
    const response = await fetch(`/api/smsstatus/gotten/${messageId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    });
    if (response.ok) {
      dispatch(smsActions.remove(messageId));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const reloadSmsList = async () => {
    setProgress(true);
    const response = await fetch('/api/sms/new');
    if (response.ok) {
      dispatch(smsActions.updateNewSmsInitialized(false));
      dispatch(smsActions.clear(null));
      dispatch(smsActions.update(await response.json()));
      dispatch(smsActions.updateNewSmsInitialized(true));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Paper aria-describedby={id} className={classes.trackingMode} elevation={3} onClick={handleClick}>
        <Tooltip title={t('smsNew')}>
          <Badge badgeContent={newSms.length} color="primary" className={classes.avatar}>
            <SmsIcon className={classes.eye} />
          </Badge>
        </Tooltip>
      </Paper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" className={classes.title}>
            {t('smsNew')}
          </Typography>
          {progress ? (
            <CircularProgress size={24} className={classes.progress} />
          ) : (
            <Tooltip title={t('requestSmsListTitle')}>
              <IconButton onClick={reloadSmsList}>
                <CachedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <List disablePadding className={classes.list}>
          {newSms.length ? newSms.slice(0).reverse().map((item) => (
            <ListItem
              key={item.id}
              className={classes.listItem}
              divider
            >
              <ListItemText
                primary={devices[item.deviceId]?.name || t('smsDeviceUnknown')}
                secondary={(
                  <Typography component="span" variant="body2" className={classes.secondaryItem}>
                    {item.message}
                  </Typography>
                )}
              />
              <ListItemSecondaryAction className={classes.dateText}>
                <Typography component="span" variant="body2" className={classes.secondaryItem}>
                  {formatDate(item.smsTime, 'DD.MM.YYYY HH:mm', true)}
                </Typography>
                <Tooltip title={t('hideTitle')}>
                  <IconButton size="small" onClick={() => clickItem(item.id)} className={classes.hideButton}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          )) : (
            <Typography component="span" variant="body2" align="center">
              {t('smsNo')}
            </Typography>
          )}
        </List>
      </Popover>
    </>
  );
};

export default NewMessages;
