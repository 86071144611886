import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default (devicesObject, reportName) => {
  const t = useTranslation();
  const theme = useTheme();

  const groups = useSelector((state) => state.groups.items);
  const positions = useSelector((state) => state.positions.items);
  const sources = useSelector((state) => state.devices.sources);
  const devices = useSelector((state) => state.devices.items);

  const reportNames = {
    radar: 'radarReport',
    anti_uav: 'antiUavReport',
    detected_object: 'detectedObjectsReport',
  };

  return columnTemplates.reportFields[reportNames[reportName]]({
    devicesObject, groups, t, theme, sources, positions, devices,
  });
};
