import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import StopIcon from '@material-ui/icons/Stop';
import {
  getButtonControlModeColor, getColorConnectionStatus, toFixedWithConvertToNumber,
} from '../funcs/calculateCellValue';
import { handleClickByObject, isUndefined } from '../funcs/utils';

export const CustomizeCourseRenderBase = ({ value }) => (
  <>
    {toFixedWithConvertToNumber(value)}
    &#176;
  </>
);

export const ColorConnectionCellRenderBase = ({
  theme, connectionStatus,
}) => (
  <StopIcon
    style={{
      color: getColorConnectionStatus(connectionStatus, theme),
    }}
  />
);

export const VisibleCellRenderBase = ({
  isChosen,
}) => (
  <>
    {isChosen ? (
      <VisibilityOffOutlined />
    ) : (
      <VisibilityOutlined />
    )}
  </>
);

export const NameCellRenderBase = ({
  dataset, innerClasses, isChosen, chosenId,
}) => (
  <Typography
    className={`${innerClasses.pointerCursor} ${isChosen && innerClasses.highlight}`}
    variant="body2"
    onClick={() => handleClickByObject(dataset.data, chosenId)}
  >
    {dataset.data.name}
  </Typography>
);

export const StateCellRenderBase = ({
  dataset, t, description, workMode,
}) => {
  if (dataset.data.model !== 'strizh3') {
    return (<></>);
  }

  const currentState = t(workMode);

  if (isUndefined(currentState) && isUndefined(description)) {
    return (<></>);
  }

  const descriptionOrCurrentState = isUndefined(description) ? currentState : description;

  return (
    <ToggleButton
      size="small"
      value="0"
      style={{
        background: getButtonControlModeColor(workMode),
        width: '100%',
        cursor: 'default',
      }}
    >
      <Typography variant="caption" noWrap>
        {descriptionOrCurrentState}
      </Typography>
    </ToggleButton>
  );
};
