import { useCallback, useEffect } from 'react';

const debounce = (func, wait = 100) => {
  let timeout;
  function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
  executedFunction.cancel = () => {
    clearTimeout(timeout);
  };
  return executedFunction;
};

export default function useDebounceWithCancel(callback, delay = 100, deps = []) {
  const debouncedCallback = useCallback(debounce(callback, delay), [delay, ...deps]);

  useEffect(() => () => {
    debouncedCallback.cancel();
  }, [delay, ...deps]);

  return debouncedCallback;
}
