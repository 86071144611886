import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HelpIcon from '@material-ui/icons/Help';
import { SvgIcon } from '@material-ui/core';
import { getIsAdmin, getUserId } from '../../../common/utils/selectors';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { ReactComponent as LogoGeofence } from './geofence.svg';
import { ReactComponent as LogoMarker } from './marker.svg';
import { ReactComponent as LogoMarkerIcon } from './marker-icon.svg';

const downloadPDF = async () => {
  const response = await fetch('/api/instruction', { Accept: 'application/pdf' });
  window.open('someLink', '_blank').location.assign(window.URL.createObjectURL(await response.blob()));
};

const useAdminRoutes = (t) => useMemo(() => [
  { subheader: t('userAdmin') },
  {
    name: t('settingsServer'),
    href: '/admin/server',
    icon: <StorageIcon />,
  },
  {
    name: t('settingsUsers'),
    href: '/admin/users',
    icon: <PeopleIcon />,
  },
  {
    match: 'group',
    name: t('settingsGroups'),
    href: '/settings/groups',
    icon: <GroupWorkIcon />,
  },
  {
    match: 'union',
    name: t('settingsUnions'),
    href: '/settings/unions',
    icon: <PeopleIcon />,
  },
  {
    name: t('settingsMarkercats'),
    href: '/settings/icons',
    icon: <LogoMarkerIcon />,
  },
], [t]);

const useMainRoutes = (t, userId) => useMemo(() => [
  {
    name: t('settingsUser'),
    href: `/user/${userId}`,
    icon: <PersonIcon />,
  },
  {
    match: 'geofence',
    name: t('sharedGeofences'),
    href: '/geofences',
    icon: <SvgIcon><LogoGeofence /></SvgIcon>,
  },
  {
    match: 'device',
    name: t('sharedDevices'),
    href: '/settings/devices',
    icon: <PhonelinkRingIcon />,
  },
  {
    name: t('settingsMarkers'),
    href: '/settings/markers',
    icon: <LogoMarker />,
  },
  {
    match: 'notification',
    name: t('sharedNotifications'),
    href: '/settings/notifications',
    icon: <NotificationsIcon />,
  },
  {
    match: 'instruction',
    name: t('settingsInstruction'),
    onClick: downloadPDF,
    icon: <HelpIcon />,
  },
], [t, userId]);

export default () => {
  const t = useTranslation();

  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector(getUserId);

  const mainRoutes = useMainRoutes(t, userId);
  const adminRoutes = useAdminRoutes(t);

  return useMemo(() => [...mainRoutes, ...(isAdmin ? adminRoutes : [])], [
    mainRoutes, isAdmin, adminRoutes,
  ]);
};
