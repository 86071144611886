import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Paper,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';

const PageProgress = () => {
  const t = useTranslation();
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState('');
  const initMap = useSelector((state) => state.map.init);
  const initBscoder = useSelector((state) => state.bs.init);
  const bscoderUrl = useSelector((state) => state.session.server?.attributes.services?.bscoder?.url);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (initMap) {
      handleClose();
    }
    if ((!initBscoder && bscoderUrl) || !initMap) {
      setTitle(t('mapLoading'));
    } else {
      setTitle('');
    }
  }, [initMap, initBscoder]);
  
  if (open) {
    return (
      <Dialog
        open={open}
      >
        <DialogActions>
          <IconButton size="small" onClick={handleClose}><CloseIcon size="small" /></IconButton>
        </DialogActions>
        <DialogTitle style={{ paddingTop: 0 }}>{title}</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Dialog>
    );
  }

  if (!initMap) {
    return (
      <Paper style={{ position: 'absolute', top: '50%', 'left': '50%' }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <LinearProgress color="secondary" />
        </DialogContent>
      </Paper>
    );
  }
  return null;
};

export default PageProgress;
