import { secondsToMilliseconds } from '../../utils/datetimeHelper';

export const ICON_NAME_SEPARATOR = '┆';
export const STRING_SETTINGS_PRESENCE_SIGN = '𖤐';

export const TABLE_UPDATE_FREQUENCY = 1000;

export const FILTER_SAVING = 'saveFilters';
export const YES = 'Yes';
export const NO = 'No';

export const TIME_FOR_FIRST_LOAD_DATA_TO_TABLES = secondsToMilliseconds(1.8);

export const TEMPORARY_PROPERTY = Object.freeze({
  ConnectionStatus: 'TF_ConnectionStatus',
  GroupName: 'TF_GroupName',
  IsClosed: 'TF_IsClosed',
  CurrentWorkMode: 'TF_CurrentWorkMode',
  WorkModeDescription: 'TF_WorkModeDescription',
  IsChosen: 'TF_IsChosenObject',
  ChosenId: 'TF_ChosenObjectId',
  SourceId: 'TF_SourceObjectId',
  CategoryParams_dist: 'TF_CategoryParams_dist',
  CategoryParams_height: 'TF_CategoryParams_height',
  CategoryParams_elevation_angle: 'TF_CategoryParams_elevation_angle',
  CategoryParams_sector: 'TF_CategoryParams_sector',
  CategoryParams_center_frequency_hz: 'TF_CategoryParams_frequency_hz',
  CategoryParams_bandwidth_hz: 'TF_CategoryParams_bandwidth_hz',
  CategoryParams_average_snr_db: 'TF_CategoryParams_average_snr_db',
  CategoryParams_comment_string: 'TF_CategoryParams_comment_string',
  CategoryParams_peleng: 'TF_CategoryParams_peleng',
  Latitude: 'TF_Latitude',
  Longitude: 'TF_Longitude',
  Speed: 'TF_Speed',
  Course: 'TF_Course',
});
