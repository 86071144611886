import React from 'react';
import {
  makeStyles, Slide, Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  foundNothing: {
    maxWidth: '100%',
    zIndex: 1310,
  },
}));

const SmsAlerts = ({
  smsSent, setSmsSent, smsArrived, setSmsArrived,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  return (
    <>
      {!!smsSent && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!smsSent}
          autoHideDuration={3000}
          onClose={() => setSmsSent(false)}
          TransitionComponent={(props) => (<Slide {...props} direction="up" />)}
          transitionDuration={500}
          className={classes.foundNothing}
        >
          <Alert
            onClose={() => setSmsSent(false)}
            severity={smsSent === 'sent' ? 'success' : 'warning'}
            sx={{ width: '100%' }}
          >
            {smsSent === 'sent' ? t('commandSent') : t('commandNoSent')}
          </Alert>
        </Snackbar>
      )}
      {!!smsArrived && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!smsArrived}
          autoHideDuration={3000}
          onClose={() => setSmsArrived(false)}
          TransitionComponent={(props) => (<Slide {...props} direction="up" />)}
          transitionDuration={500}
          className={classes.foundNothing}
        >
          <Alert
            onClose={() => setSmsArrived(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {t('smsNewAlert')}
          </Alert>
        </Snackbar>
      )}
    </>

  );
};

export default SmsAlerts;
