export const prefixString = (prefix, value) => prefix + value.charAt(0).toUpperCase() + value.slice(1);

export const unprefixString = (prefix, value) => value.charAt(prefix.length).toLowerCase() + value.slice(prefix.length + 1);

export const concatenateWithSeparator = (separator, stringsArray) => stringsArray.join(separator);

export const isUndefinedOrNull = (value) => value === undefined || value === null;

export const convertToBoolean = (value) => {
  if (isUndefinedOrNull(value) || typeof value !== 'string') return undefined;
  return value.toLocaleLowerCase() === 'true';
};

export const convertHtmlCodesToString = (value) => value.replace(/&amp;/g, '&');

// eslint-disable-next-line global-require
export const getRandomString = () => require('crypto').randomBytes(64).toString('hex').replace(/\d/g, '');
