import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import EditItemView from '../components/EditItemView';
import MapBlock from './MapBlock';
import ConnectionBlock from './ConnectionBlock';
import SettingsBlock from './SettingsBlock';
import DevicesBlock from './DevicesBlock';

const UserPage = () => {
  const adminEnabled = useSelector((state) => state.session.user?.administrator);
  const [item, setItem] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [confirmationTrouble, setConfirmationTrouble] = useState(false);
  const [permissions, setPermissions] = useState();
  const { id } = useParams();
  const [errorPhone, setErrorPhone] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const lengthPhone = item?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && item.phone.slice(0, 2) !== '+7')) {
        setErrorPhone(true);
        return;
      }
    }
    setErrorPhone(false);
  }, [item?.phone]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        devices: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'deviceId',
          linked: new Set(),
          old: new Set(),
          groupReserve: new Set(),
        },
        groups: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'groupId',
          linked: new Set(),
          old: new Set(),
        },
        unions: {
          baseId: item.id,
          keyBase: 'userId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
          reverse: true,
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffect(() => {
    if (!item?.password) {
      setConfirmPassword(item?.password);
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="users"
      item={item}
      setItem={setItem}
      permissions={permissions}
      disabledSave={!(item && item.name && item.login && ((item.password && confirmPassword) || id) && !errorPhone && !Object.keys(errors).length)}
      confirmPassword={confirmPassword}
      setConfirmationTrouble={setConfirmationTrouble}
    >
      {item
        && (
          <>
            <SettingsBlock
              key={`settings_${item.id}`}
              item={item}
              setItem={setItem}
              setConfirmPassword={setConfirmPassword}
              confirmationTrouble={confirmationTrouble}
              setConfirmationTrouble={setConfirmationTrouble}
            />
            <MapBlock key={`settingsMap_${item.id}`} item={item} setItem={setItem} errors={errors} setErrors={setErrors} />
            <DevicesBlock key={`settingsDevices_${item.id}`} item={item} setItem={setItem} errors={errors} setErrors={setErrors} />
            {!!(item.id && adminEnabled && permissions)
              && (
                <ConnectionBlock key={`settingsConnection_${item.id}`} item={item} permissions={permissions} setPermissions={setPermissions} />
              )}
          </>
        )}
    </EditItemView>
  );
};

export default UserPage;
