import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import ConfirmDelete from '../../common/components/ConfirmDelete';
import MarkerBody from './MarkerBody';
import { markersActions, positionsActions } from '../../store';
import MarkerHeader from './MarkerHeader';
import MarkerEdit from './MarkerEdit';
import markerStatus from '../../common/constants/markerStatus';

const MarkerInfo = ({
  marker, setPositionDataVisible, setNextPosition, setPositionsBS,
  setMoveableMarker, setMarker, setSelectedMarkerComponents, markerState,
}) => {
  const dispatch = useDispatch();

  const [isMarkerEdit, setIsMarkerEdit] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const deletePopup = useRef();

  const handleMove = () => {
    markerState.current = { ...markerState.current, actionType: markerStatus.Move };
    setMoveableMarker(marker);
  };

  // Передает в бокове меню информацию о метке при нажатии на метку у родителя.
  useEffect(() => {
    setSelectedMarkerComponents({
      callbackDeletePopup: deletePopup,
      sidebarHeader: (
        <MarkerHeader
          handleMove={handleMove}
          setEdit={setIsMarkerEdit}
          setNextPosition={setNextPosition}
          setOpenConfirmDelete={setIsOpenConfirmDelete}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          deletePopup={deletePopup}
        />
      ),
      compactHeader: (
        <MarkerHeader
          handleMove={handleMove}
          setEdit={setIsMarkerEdit}
          setNextPosition={setNextPosition}
          setOpenConfirmDelete={setIsOpenConfirmDelete}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          deletePopup={deletePopup}
          isSmall
        />
      ),
      bodyContent: (
        <MarkerBody
          marker={marker}
          setMoveableMarker={setMoveableMarker}
        />
      ),
    });

    dispatch(positionsActions.selectPosition({
      latitude: marker.latitude,
      longitude: marker.longitude,
      objClass: 'marker',
      name: '',
    }));
    dispatch(positionsActions.needToShowDataPanel(true));
  }, [marker]);

  const handleMarkerDelete = async () => {
    const response = await fetch(`/api/markers/${marker.id}`, { method: 'DELETE' });
    if (response.ok) {
      // Убирает обводку на карте у метки.
      dispatch(positionsActions.unselectPosition());
      // Удаляет popup при нажатии удаления.
      if (deletePopup.current) {
        deletePopup.current();
      }

      setIsOpenConfirmDelete(false);
      dispatch(markersActions.remove([marker.id]));
    }
  };

  return (
    <>
      <ConfirmDelete handleDelete={handleMarkerDelete} open={isOpenConfirmDelete} setOpen={setIsOpenConfirmDelete} />

      <MarkerEdit
        open={isMarkerEdit}
        setOpen={setIsMarkerEdit}
        marker={marker}
        setMarker={setMarker}
        setEdit={setIsMarkerEdit}
        latitude={marker.latitude}
        longitude={marker.longitude}
      />
    </>
  );
};

export default MarkerInfo;
