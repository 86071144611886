import { getPositionExtraProperties } from './positionSource';

export const getExtraPositionProperties = async (properties, data, groups, devices) => {
  if (data?.name === 'stop') return {};

  try {
    const response = await fetch(`/api/positions?id=${properties.id}`);

    if (response.ok) {
      const position = await response.json();
      if (position.length) {
        const device = devices[position[0].deviceId];
        return getPositionExtraProperties(position[0], device, data, groups);
      }
    }

    throw new Error(response.message);
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Получит дополнительную информацию для cids.
 * @param {*} successfulFn если запрос успешный, вызывает этот метод и передает полученный ответ.
 * @param {*} unsuccessfulFn если запрос неуспешный, вызывает этот метод.
 */
export const getExtraCoordinatesBSData = async (cids, successfulFn, unsuccessfulFn) => {
  const query = new URLSearchParams({});

  cids.forEach((it) => {
    query.append('lacCid', it);
  });

  const response = await fetch(`/api/bscoder/coordinates?${query.toString()}`);

  if (response.ok && response.status === 200) {
    successfulFn(await response.json());
  } else if (response.status === 401) {
    unsuccessfulFn(response.message);
  }
};
