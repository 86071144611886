import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, ButtonGroup,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VpnKey from '@material-ui/icons/VpnKey';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { formatPhone } from '../../../common/utils/formatter';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const SettingsBlock = ({
  item, setItem, setConfirmPassword, confirmationTrouble, setConfirmationTrouble,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const adminEnabled = useSelector((state) => state.session.user?.administrator);
  const mapGeocodeProviderType = useSelector((state) => state.session.server.attributes.mapGeocodeProvider);
  const [isSearchOn, setSearchOn] = useState();
  const [tokenTrouble, setTokenTrouble] = useState(false);
  const { id } = useParams();
  const [errorPhone, setErrorPhone] = useState(false);

  useEffect(() => {
    if (mapGeocodeProviderType === 'off' || !mapGeocodeProviderType) {
      setSearchOn(false);
    } else {
      setSearchOn(true);
    }
  }, []);

  useEffect(() => {
    const lengthPhone = item?.phone?.length;
    if (lengthPhone) {
      if (lengthPhone !== 12 || (lengthPhone > 1 && item.phone.slice(0, 2) !== '+7')) {
        setErrorPhone(true);
        return;
      }
    }
    setErrorPhone(false);
  }, [item?.phone]);

  const generateToken = () => {
    const newToken = uuidv4();
    setItem({ ...item, token: newToken });
    setTokenTrouble(false);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('settingsUser')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <TextField
          required
          disabled={!adminEnabled}
          margin="normal"
          value={item.login || ''}
          onChange={(event) => setItem({ ...item, login: event.target.value })}
          label={t('userLogin')}
          variant="filled"
        />
        <TextField
          required
          disabled={!adminEnabled}
          margin="normal"
          value={item.name || ''}
          onChange={(event) => setItem({ ...item, name: event.target.value })}
          label={t('sharedName')}
          variant="filled"
        />
        <TextField
          margin="normal"
          value={item.email || ''}
          onChange={(event) => setItem({ ...item, email: event.target.value })}
          label={t('userEmail')}
          variant="filled"
        />
        <TextField
          margin="normal"
          type="password"
          required={!id}
          error={confirmationTrouble}
          onChange={(event) => {
            setItem({ ...item, password: event.target.value });
            if (confirmationTrouble) {
              setConfirmationTrouble(false);
            }
          }}
          label={t('userPassword')}
          variant="filled"
        />
        <TextField
          margin="normal"
          type="password"
          required={!id}
          error={confirmationTrouble}
          helperText={confirmationTrouble && t('userConfirmationTrouble')}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
            if (confirmationTrouble) {
              setConfirmationTrouble(false);
            }
          }}
          label={t('userConfirmPassword')}
          variant="filled"
        />
        <ButtonGroup>
          <TextField
            margin="normal"
            value={item.token || ''}
            onChange={(event) => {
              const { value } = event.target;
              setItem({ ...item, token: value });
              if (value && !value.match(/^[a-zA-Z0-9-]{16,}$/g)) {
                setTokenTrouble(true);
              } else {
                setTokenTrouble(false);
              }
            }}
            label={t('userToken')}
            error={tokenTrouble}
            helperText={tokenTrouble ? t('userTokenTrouble') : ''}
            variant="filled"
            style={{ flexGrow: '1' }}
          />
          <IconButton
            title={t('generateUserToken')}
            variant="outlined"
            onClick={generateToken}
            style={{
              width: '42px', height: '42px', marginTop: '16px', borderRadius: '4px',
            }}
          >
            <VpnKey />
          </IconButton>
        </ButtonGroup>
        <TextField
          margin="normal"
          value={item.phone || ''}
          onChange={(event) => (formatPhone(event.target.value, item, setItem))}
          label={t('sharedPhone')}
          placeholder="+79XXXXXXXXX"
          error={errorPhone}
          helperText={errorPhone ? t('errorPhone') : ''}
          variant="filled"
        />
        <TextField
          margin="normal"
          value={item.attributes?.telegramChatId || ''}
          onChange={(event) => setItem({ ...item, attributes: { telegramChatId: event.target.value } })}
          label={t('sharedTelegram')}
          variant="filled"
        />

        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel>{t('settingsSpeedUnit')}</InputLabel>
          <Select
            value={item.attributes?.speedUnit ?? ''}
            onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, speedUnit: event.target.value } })}
          >
            <MenuItem value="">{t('sharedNotChosen')}</MenuItem>
            <MenuItem value="kmh">{t('sharedKmh')}</MenuItem>
            <MenuItem value="mph">{t('sharedMph')}</MenuItem>
            <MenuItem value="kn">{t('sharedKn')}</MenuItem>
          </Select>
          {isSearchOn ? (
            <FormControlLabel
              control={<Checkbox />}
              label={t('mapGeoсodeNeedToShow')}
              checked={item.attributes?.showSearch}
              onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, showSearch: event.target.checked } })}
            />
          ) : (<></>)}
        </FormControl>
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel>{t('settingsCoordinateFormat')}</InputLabel>
          <Select
            label={t('settingsCoordinateFormat')}
            value={item.coordinateFormat || 'dd'}
            onChange={(e) => setItem({ ...item, coordinateFormat: e.target.value })}
          >
            <MenuItem value="dd">{t('sharedDecimalDegrees')}</MenuItem>
            <MenuItem value="ddm">{t('sharedDegreesDecimalMinutes')}</MenuItem>
            <MenuItem value="dms">{t('sharedDegreesMinutesSeconds')}</MenuItem>
          </Select>
        </FormControl>
        {adminEnabled && (
          <FormControlLabel
            control={(
              <Checkbox
                checked={item.administrator}
                onChange={(event) => setItem({
                  ...item,
                  administrator: event.target.checked,
                  deviceLimit: event.target.checked ? -1 : 0,
                })}
              />
            )}
            label={t('userAdmin')}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SettingsBlock;
