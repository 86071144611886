import React, { useEffect, useState } from 'react';
import { makeStyles, Slide, Snackbar } from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  foundNothing: {
    maxWidth: '100%',
    zIndex: 1310,
  },
}));

const BadResponse = ({ status, setStatus }) => {
  const t = useTranslation();
  const classes = useStyles();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (status === 504) {
      setMessage(t('serverNotAvailableError'));
    }
  }, [status]);

  return status && message ? (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!status}
      autoHideDuration={3000}
      onClose={() => {
        setStatus(null);
        setMessage('');
      }}
      message={message}
      TransitionComponent={(props) => (<Slide {...props} direction="up" />)}
      transitionDuration={500}
      className={classes.foundNothing}
    />
  ) : null;
};

export default BadResponse;
