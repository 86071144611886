import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'map',
  initialState: {
    styleOpen: false,
    exportOpen: false,
    deviceToolOff: JSON.parse(localStorage.getItem('deviceToolOff')) || [],
    zoomInitialized: JSON.parse(localStorage.getItem('zoomInitialized')) || null,
    init: false,
  },
  reducers: {
    openStyles(state, action) {
      state.styleOpen = action.payload;
    },
    openExport(state, action) {
      state.exportOpen = action.payload;
    },
    updateDeviceToolOff(state, action) {
      state.deviceToolOff = action.payload;
      window.localStorage.setItem('deviceToolOff', JSON.stringify(state.deviceToolOff));
    },
    updateZoomInitialized(state, action) {
      state.zoomInitialized = action.payload;
      window.localStorage.setItem('zoomInitialized', JSON.stringify(state.zoomInitialized));
    },
    init(state, action) {
      state.init = action.payload;
    },
    selectStyle(state, action) {
      state.selectStyle = action.payload;
    },
  },
});

export { actions as mapActions };
export { reducer as mapReducer };
