import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAttributePreference } from '../../utils/preferences';
import { useTranslation } from '../LocalizationProvider';
import usePersistedState from '../../utils/usePersistedState';
import { formatPeriod } from '../../utils/formatter';
import DataPanel from './DataPanel';

const PositionData = ({
  setNextPosition, countPosition, setCountPosition, nextPosition, popup, setMoveableDevice,
  setPositionDataVisible, setPositionsBS, setPopup, selectedMarkerComponents,
}) => {
  const t = useTranslation();
  const speedUnit = useAttributePreference('speedUnit');
  const [openExtraSettings, setOpenExtraSettings] = useState(false);
  const [openChoice, setOpenChoice] = useState(false);
  const [redFields, setRedFields] = useState([]);
  const properties = useSelector((state) => state.positions.selectedPositionData);
  const positionsSimilar = useSelector((state) => state.tail.positionsSimilar);

  const getHideProperties = () => {
    if (properties.startTime) {
      return popup ? 'hidePropertiesStopPopup' : 'hidePropertiesStop';
    }
    return popup ? 'hidePropertiesMainPopup' : 'hidePropertiesMain';
  };

  const [off, setOff] = usePersistedState(getHideProperties(), {});

  const includeProperties = (category, property) => {
    let updatedOff;
    if (off[category]?.includes(property)) {
      updatedOff = off[category].filter((item) => item !== property);
    } else {
      updatedOff = [...(off[category] || []), property];
    }
    setOff({ ...off, [category]: updatedOff });
  };

  const propertyNames = {
    stop: {
      name: { title: t('sharedDevice') },
      deviceCategory: { title: t('sharedDeviceCategory') },
      group: { title: t('groupParent') },
      duration: { title: t('positionDuration') },
      startTime: { title: t('positionStartTime') },
      endTime: { title: t('positionEndTime') },
      address: { title: t('positionAddress') },
      coordinates: { title: t('positionCoordinate') },
    },
    main: {
      name: { title: t('sharedDevice') },
      deviceCategory: { title: t('sharedDeviceCategory') },
      group: { title: t('groupParent') },
      serverTime: { title: t('positionServerTime') },
      fixTime: { title: t('positionFixTime') },
      numberPoints: { title: t('positionNumberPoints') },
      period: {
        title: t('reportPeriod'),
        value: properties.numberPoints > 1 && positionsSimilar[properties.deviceId]
          ? formatPeriod(positionsSimilar[properties.deviceId][`${properties.longitude}-${properties.latitude}`])
          : '',
      },
      status: { title: t('deviceStatus') },
      address: { title: t('positionAddress') },
      coordinates: { title: t('positionCoordinate') },
      speed: { title: t('positionSpeed') },
      course: { title: t('positionCourse') },
      sat: { title: t('positionSat') },
      operator: { title: t('positionOperator') },
      bs: { title: t('positionBaseStation') },
      cid: { title: t('positionCID') },
      accuracy: { title: t('positionAccuracy') },
      motion: { title: t('positionMotion') },
      power: { title: t('positionPower') },
      batteryLevel: { title: t('positionBatteryLevel') },
      protocol: { title: t('positionProtocol') },
      message: { title: t('commandMessage') },
      categoryParams: { title: t('sharedCategoryParams') },
      extraAttributes: { title: t('sharedExtraAttributes') },
    },
  };

  const icon = properties.startTime ? 'stop' : 'main';
  const data = properties.startTime ? propertyNames.stop : propertyNames.main;

  useEffect(() => {
    if (icon === 'main') {
      const newFields = [];
      if (properties.numberPoints > 1) {
        newFields.push('numberPoints');
        newFields.push('period');
      }
      setRedFields(newFields);
    }
  }, [properties.numberPoints, properties.course]);

  useEffect(() => {
    if (properties?.numberPoints > 1 && !nextPosition && countPosition === 0) {
      setNextPosition(positionsSimilar[properties.deviceId][`${properties.longitude}-${properties.latitude}`][0]);
    }
  }, [properties]);

  const getTitle = () => {
    if (properties.startTime) {
      return t('positionDataStop');
    } if (!properties.status) {
      return t('positionData');
    } if (properties.startPoint) {
      return t('positionStart');
    } if (properties.finishPoint) {
      return t('positionFinish');
    }
    return t('positionNow');
  };

  return (
    <DataPanel
      isCompactPanel={popup}
      setNextPosition={setNextPosition}
      countPosition={countPosition}
      setCountPosition={setCountPosition}
      data={data}
      redFields={redFields}
      getTitle={getTitle}
      openExtraSettings={openExtraSettings}
      setOpenExtraSettings={setOpenExtraSettings}
      openChoice={openChoice}
      off={off}
      includeProperties={includeProperties}
      setOpenChoice={setOpenChoice}
      speedUnit={speedUnit}
      positionsSimilar={positionsSimilar}
      setPositionDataVisible={setPositionDataVisible}
      setPositionsBS={setPositionsBS}
      setPopup={setPopup}
      popup={popup}
      selectedMarkerComponents={selectedMarkerComponents}
      setMoveableDevice={setMoveableDevice}
    />
  );
};

export default PositionData;
