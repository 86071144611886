import React from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import LinkField from '../../../common/form/LinkField';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const ConnectionBlock = ({ item, permissions, setPermissions }) => {
  const classes = useStyles();
  const t = useTranslation();
  const groups = useSelector((state) => state.groups.items);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedConnections')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <LinkField
          margin="normal"
          endpointAll="/api/unions?all=true"
          endpointLinked={`/api/unions?userId=${item.id}`}
          label={t('settingsUnions')}
          variant="filled"
          permissions={permissions}
          setPermissions={setPermissions}
          type="unions"
        />
        <LinkField
          margin="normal"
          endpointAll="/api/groups?all=true"
          endpointLinked={`/api/groups?userId=${item.id}`}
          label={t('settingsGroups')}
          variant="filled"
          permissions={permissions}
          setPermissions={setPermissions}
          type="groups"
        />
        <LinkField
          margin="normal"
          endpointAll="/api/devices?all=true&excludeDetected=true"
          endpointLinked={`/api/devices?userId=${item.id}&excludeDetected=true`}
          label={t('deviceTitle')}
          variant="filled"
          subtitleGetter={(i) => groups[i.groupId]?.name}
          permissions={permissions}
          setPermissions={setPermissions}
          type="devices"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ConnectionBlock;
