import { useTheme } from '@material-ui/core';
import { useTranslation } from '../../../../LocalizationProvider';
import columnTemplates from '../../../columnTemplates';

export default () => {
  const t = useTranslation();
  const theme = useTheme();

  return columnTemplates.settingsFields.markersSettings({
    t, theme,
  });
};
