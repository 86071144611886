import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  collectReport,
} from '../../common/utils/formatter';
import usePersistedState from '../../common/utils/usePersistedState';
import { positionHandler } from '../../common/utils/requestRoute';
import logout from '../../common/utils/logout';
import ReportSmartTemplate from './components/ReportSmartTemplate';
import ReplayPathMap from '../../map/ReplayPathMap';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'radarReport';

// Список колонок [имя поля в json с бэкенда, имя поля в файле переводчике, возможность сортировки (0 - нет, 1 - да)]
const columnsArray = [];

const RadarReportPage = () => {
  const category = 'radar';
  const classes = useReportStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);

  const [devicesObject, setDevicesObject] = useState({});
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [positionsPath, setPositionsPath] = useState({});
  const [progressReport, setProgressReport] = useState(0);

  const templateField = useTemplatesFieldsPresetForReports(devicesObject, category);
  const reportColumns = useAutoCompleteColumns(templateField);

  const requestDevices = async () => {
    const response = await fetch('/api/devices?excludeDetected=true');
    if (response.ok) {
      const body = (await response.json())
        .filter((obj) => obj.category === category)
        .reduce((acc, next) => ({ ...acc, [next.id]: next }), {});
      setDevicesObject(body);
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const handleSubmit = async (_, from, to, headers, hideLoadingForm) => {
    await requestDevices();

    setProgress(1);
    const query = new URLSearchParams({
      from, to, category,
    });
    const response = await fetch(`/api/reports/detected?${query.toString()}`, { headers });
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {
        if (contentType === 'application/json') {
          const result = [];
          await collectReport(response, result, setProgressReport);
          hideLoadingForm();
          setItems(result);
        } else {
          window.location.assign(window.URL.createObjectURL(await response.blob()));
        }
      }
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setProgress(100);
    setProgress(0);
  };

  useEffect(() => {
    if (selectedItem && !Object.keys(positionsPath).length) {
      const path = items.reduce((a, v) => ({ ...a, [v.deviceId]: a[v.deviceId] ? [...a[v.deviceId], v] : [v] }), {});
      const deviceIds = Object.keys(path);
      const result = {};
      for (let i = 0; i < deviceIds.length; i += 1) {
        const deviceId = deviceIds[i];
        const clearResponsePath = [];
        positionHandler(path[deviceId], clearResponsePath, true);
        result[deviceId] = clearResponsePath;
      }
      setPositionsPath(result);
    }
  }, [selectedItem]);

  return (
    <ReportSmartTemplate
      items={items}
      progress={progress}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="fixTime"
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      positionsPath={positionsPath}
      setPositionsPath={setPositionsPath}
      breadcrumbs={['reportTitle', 'reportRadars']}
      devicesObject={devicesObject}
      withoutDevices
      progressReport={progressReport}
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <ReplayPathMap positions={positionsPath} devices={devicesObject} />
            <PositionsMap
              positions={{ [selectedItem.deviceId]: [selectedItem] }}
              devices={devicesObject}
              data={positionsTypes.current}
              noClick
            />
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default RadarReportPage;
