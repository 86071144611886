import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';

/**
 * Настроенный компонент страницы обнаруженных объектов, с заголовком и сменой фреймов для избежания утечек памяти.
 */
const DetectedObjectsPageView = () => {
  const getComponent = useComponentsController();

  return (
    <>
      {getComponent(availableComponents.DetectedObjectsPage, {})}
    </>
  );
};

export default DetectedObjectsPageView;
