export default {
  device: {
    title: 'device', expanded: true, order: 0, iconText: null, iconCluster: true,
  },
  uav: {
    title: 'uav', expanded: true, order: 1, iconText: null, iconCluster: true,
  },
  radar: {
    title: 'radar', expanded: true, order: 2, iconText: null, iconCluster: true,
  },
  anti_uav: {
    title: 'anti_uav', expanded: true, order: 3, iconText: null, iconCluster: true,
  },
  detected_object: {
    title: 'detected_object', expanded: true, order: 4, iconText: null, iconCluster: true,
  },
  vcam: {
    title: 'vcam', expanded: true, order: 5, iconText: null, iconCluster: true,
  },
};
