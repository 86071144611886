import React, { useEffect } from 'react';
import {
  Drawer, Grid, Paper, makeStyles,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import ComponentIcon from './icons/CommonIcon';
import getExtraLayers, { updatePoints, updateStatus } from '../../../map/funcs/getExtraLayers';
import extraLayersItems from './extraLayersItems';
import { useCatch } from '../../utils/reactHelper';
import { toTitleCase } from '../../utils/formatter';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: 300,
    borderRadius: '7px 7px 0px 0px',
  },
  checkboxToggle: {
    borderRadius: '10px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220,220,220,0.8)',
    },
  },
  avatar: {
    backgroundColor: 'rgba(255,255,255,0)',
  },
}));

const ExtraLayersDrawer = ({
  buttonsDrawerOpen, setButtonsDrawerOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const extraLayersPanel = useSelector((state) => state.extraLayers.panel);

  const handleButton = useCatch(async (button) => {
    updateStatus(dispatch, button, extraLayersPanel[`on${toTitleCase(button)}`] ? 0 : 1);
  });

  const handleStatus = (title, interval = 0) => {
    if (extraLayersPanel[`on${toTitleCase(title)}`] === 1) {
      getExtraLayers(title, dispatch);
      if (interval) {
        return setInterval(() => getExtraLayers(title, dispatch), interval);
      }
    } else if (extraLayersPanel[`on${toTitleCase(title)}`] === 0) {
      updatePoints(dispatch, title, []);
      updateStatus(dispatch, title, 0);
    }
    return null;
  };

  useEffect(() => {
    const flightInterval = handleStatus('flightRadarFlights', 60000);
    return () => {
      if (flightInterval) {
        clearInterval(flightInterval);
      }
    };
  }, [extraLayersPanel.onFlightRadarFlights]);

  useEffect(() => {
    handleStatus('flightRadarAirports');
  }, [extraLayersPanel.onFlightRadarAirports]);

  return (
    <Drawer
      anchor="bottom"
      classes={{ paper: classes.root }}
      open={buttonsDrawerOpen}
      onClose={() => setButtonsDrawerOpen(false)}
    >
      <Grid container>
        {extraLayersItems.map((button) => (
          <Grid item key={button} style={{ margin: 5 }}>
            <Paper
              className={classes.checkboxToggle}
              elevation={3}
              onClick={() => handleButton(button, dispatch, extraLayersPanel)}
            >
              <Avatar className={classes.avatar}>
                <ComponentIcon
                  panel={extraLayersPanel}
                  value={button}
                />
              </Avatar>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

export default ExtraLayersDrawer;
