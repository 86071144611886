import { useDispatch, useSelector } from 'react-redux';
import React, { memo } from 'react';
import {
  Tooltip, Paper, IconButton, makeStyles, Grow,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { useStylesMainPanel } from '../MainPage/MainPage.styles';
import useComponentsController from '../../../common/hooks/useComponentsController';
import { universalPanelActions } from '../../../store/universalPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 'auto',
    left: 0,
    bottom: 76,
    zIndex: 2,
    width: '100%',
    height: 450,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      bottom: 65,
      width: 'calc(100% - 16px)',
      left: 8,
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 390px)',
      left: 370,
      bottom: 0,
    },
  },
  paper: {
    height: 55,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding: {
    padding: theme.spacing(0, 1),
  },
  textNoWrap: {
    textWrap: 'nowrap',
  },
}));

const UniversalPanel = () => {
  const classes = useStyles();
  const classesPanel = useStylesMainPanel();
  const t = useTranslation();
  const getComponent = useComponentsController();
  const dispatch = useDispatch();

  const panelState = useSelector((state) => state.universalPanel);

  const handleOnClickClose = (event) => {
    event.preventDefault();
    dispatch(universalPanelActions.changeVisibility(false));
  };

  return (
    <>
      <Grow in={panelState.isShow}>
        <Paper className={`${classesPanel.sidebar} ${classes.root}`}>
          {panelState.isShow && (
            <>
              {/* Шапка */}
              <Paper className={`${classesPanel.paper} ${classes.paper} ${classes.padding}`} square elevation={0}>
                <div>
                  {panelState.header && (<>{getComponent(panelState.header, panelState.settings)}</>)}
                </div>
                <div>
                  <Tooltip title={t('closeTitle')}>
                    <IconButton onClick={handleOnClickClose}>
                      <CloseRounded />
                    </IconButton>
                  </Tooltip>
                </div>
              </Paper>
              {/* Тело */}
              {panelState.body && (<>{getComponent(panelState.body, panelState.settings)}</>)}
            </>
          )}
        </Paper>
      </Grow>
    </>
  );
};

export default memo(() => <UniversalPanel />);
