import React, { useEffect, useState } from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel,
  Checkbox, Modal, Backdrop, Fade, Paper, FormControl, ListItem, ListItemText, List,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import { prefixString } from '../../common/utils/stringUtils';
import SelectField from '../../common/form/SelectField';
import { getAttr, setAttr } from '../../common/utils/formatter';
import { playSound } from '../../SocketController';
import sounds from '../../common/static/sounds/sounds';
import LinkField from '../../common/form/LinkField';
import { useSelector } from 'react-redux';
import { getIsAdmin } from '../../common/utils/selectors';
import ValidatedTextField from '../../common/form/ValidatedTextField';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
  },
  checkbox: {
    marginTop: 12,
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
}));

const NotificationPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);

  const [item, setItem] = useState();
  const { id } = useParams();
  const [permissions, setPermissions] = useState();
  const [openSounds, setOpenSounds] = useState(false);
  const [errors, setErrors] = useState({});

  const handleToggle = (val) => {
    setAttr(setItem, 'sound', val);
    setOpenSounds(false);
  };

  useEffect(() => {
    /**
     * Устанавливает конфигурационный файл для permissions.
     * В дальнейшем в данном словаре будут изменяться поля linked, old, в которых указаны id элементом,
     * которые будут удаляться (поле old) или добавляться (поле linked) при нажатии на кнопку Сохранить.
     */
    if (item && !permissions) {
      const permissionsDict = {
        groups: {
          baseId: id ?? null,
          keyLink: 'groupId',
          keyBase: 'notificationId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  return (
    <EditItemView
      endpoint="notifications"
      item={item}
      setItem={setItem}
      permissions={permissions}
      permissionsReverse
      disabledSave={!(item && item.type && item.notificators?.length) || Object.values(errors).some((v) => v)}
    >
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedNotification')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <ValidatedTextField
                  fullWidth
                  initState={id ? getAttr(item, 'description') : ''}
                  multiple
                  helperText="Слишком длинное описание (не более 45 символов)" // вынести в t()?
                  label={t('sharedDescription')}
                  onChange={(value, isValid) => {
                    setAttr(setItem, 'description', value);
                    setErrors({ ...errors, description: !isValid });
                  }}
                  validator={(value) => value.length <= 45}
                />
                <SelectField
                  margin="normal"
                  value={item.type || ''}
                  emptyValue={null}
                  onChange={(e) => setItem({ ...item, type: e.target.value })}
                  endpoint="/api/notifications/types"
                  keyGetter={(it) => it.type}
                  titleGetter={(it) => t(prefixString('event', it?.type || it))}
                  label={t('sharedType')}
                  variant="filled"
                />
                <SelectField
                  multiple
                  margin="normal"
                  value={item.notificators ? item.notificators.split(/[, ]+/) : []}
                  onChange={(e) => setItem({ ...item, notificators: e.target.value.join() })}
                  endpoint="/api/notifications/notificators"
                  keyGetter={(it) => it.type}
                  titleGetter={(it) => t(prefixString('notificator', it?.type || it))}
                  label={t('notificationNotificators')}
                  variant="filled"
                />

                <Button
                  onClick={() => setOpenSounds(true)}
                  fullWidth
                  variant="contained"
                  required
                >
                  {`${t('sharedSound')}: ${t(prefixString('event', item.attributes?.sound || 'default'))}`}
                </Button>

                <Modal
                  open={openSounds}
                  onClose={() => setOpenSounds(false)}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openSounds}>
                    <Paper className={classes.modal}>
                      <Typography id="modal-modal-title" variant="h6" component="h2" align="center" className={classes.text}>
                        {t('sharedSound')}
                      </Typography>
                      <FormControl fullWidth margin="normal">
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          {sounds.map((value) => (
                            <ListItem
                              key={value}
                              style={{ background: (item.attributes?.sound || 'default') === value ? '#198cffa4' : '#fff' }}
                            >
                              <ListItemIcon onClick={() => playSound(value)}>
                                <PlayArrowIcon />
                              </ListItemIcon>
                              <ListItemText style={{ cursor: 'pointer' }} primary={t(prefixString('event', value))} onClick={() => handleToggle(value)} />
                            </ListItem>
                          ))}
                        </List>
                      </FormControl>
                    </Paper>
                  </Fade>
                </Modal>

                <FormControlLabel
                  className={classes.checkbox}
                  control={(
                    <Checkbox
                      checked={item.always}
                      onChange={(event) => setItem({ ...item, always: event.target.checked })}
                    />
                  )}
                  label={t('notificationAlways')}
                />
              </AccordionDetails>
            </Accordion>
            {permissions && (
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('sharedConnections')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkField
                    margin="normal"
                    endpointAll={`/api/groups?${isAdmin ? 'all=true' : `userId=${userId}`}`}
                    endpointLinked={`/api/groups/extra?notificationId=${item.id}`}
                    label={t('settingsGroups')}
                    variant="filled"
                    permissions={permissions}
                    setPermissions={setPermissions}
                    type="groups"
                    fullWidth
                    newItem={!item.id}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
    </EditItemView>
  );
};

export default NotificationPage;
