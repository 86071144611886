import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices panel',
  initialState: {
    isReorderingMode: false,
  },
  reducers: {
    change(state, action) {
      state.isReorderingMode = action.payload;
    },
  },
});

export { actions as devicesPanelActions };
export { reducer as devicesPanelReducer };
