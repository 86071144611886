const IP_PATTERN = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const PORT_PATTERN = /^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3})$/;
const DISTANCE_PATTERN = /^\d+(\.\d*)?$/;

export const isGeocoordValid = (value, limit, upperLimit = undefined) => Boolean(value && !Number.isNaN(value) && (upperLimit ? limit <= Number(value) && Number(value) <= upperLimit : -limit <= Number(value) && Number(value) <= limit));

export const isIpValid = (value) => IP_PATTERN.test(value);

export const isPortValid = (value) => PORT_PATTERN.test(value);

export const isDistanceValid = (value) => DISTANCE_PATTERN.test(value);
