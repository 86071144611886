import detectedObjectsTable from './detectedTable/detectedObjectsTable';
import antiUavTable from './detectedTable/antiUavTable';
import radarsTable from './detectedTable/radarsTable';

import radarReport from './report/radarReport';
import antiUavReport from './report/antiUavReport';
import detectedObjectsReport from './report/detectedObjectsReport';

import devicesSettings from './settings/devicesSettings';
import markersSettings from './settings/markersSettings';
import notificationsSettings from './settings/notificationsSettings';
import usersSettings from './settings/usersSettings';
import devicesGroupsSettings from './settings/devicesGroupsSettings';
import usersGroupsSettings from './settings/usersGroupsSettings';
import iconsSettings from './settings/iconsSettings';

export default {
  detectedTable: {
    detectedObjectsTable,
    antiUavTable,
    radarsTable,
  },
  reportFields: {
    radarReport,
    detectedObjectsReport,
    antiUavReport,
  },
  settingsFields: {
    devicesSettings,
    markersSettings,
    notificationsSettings,
    usersSettings,
    devicesGroupsSettings,
    usersGroupsSettings,
    iconsSettings,
  },
};
