/* eslint-disable object-curly-newline */

import presetsColumns from '../shared/presetsColumns';
import settingsSharedFields from './settingsSharedColumns';

export default ({ t }) => [
  ...settingsSharedFields.common,
  presetsColumns.calculateValues.isAdmin({ t }),
  presetsColumns.common.login,
  presetsColumns.common.email,
];
