/* eslint-disable no-await-in-loop */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-new */
import 'maplibre-gl/dist/maplibre-gl.css';
import './switcher/switcher.css';
import { prepareIcon, loadImage } from './mapUtil';
import palette from '../common/theme/palette';
import { map } from './Map';
import {
  DELAY_TO_CHECK_OF_REQUIRED_RESOURCES, PATH_DEFAULT_TO_ICONS, TYPE_SETTINGS, TYPES_EQUAL_TO_CATEGORIES,
} from '../common/constants/mapControllerDynamic';
import { isUndefinedOrNull } from '../common/utils/stringUtils';

const requiredResources = new Map();
const typesSettings = new Map();
// const loadedRequiredResources = new Set();
let isRun = false;

const convertToObject = (background, icon, config) => ({ background, icon, config });
const getPath = (name) => PATH_DEFAULT_TO_ICONS.replace('{name}', name);

const getConfig = (value) => JSON.parse(value);
const convertToConfig = (obj) => JSON.stringify(obj);

const addImageToMap = (name, resource) => {
  const {
    background, config, icon,
  } = resource;

  map.addImage(
    name,
    prepareIcon(background, icon, config),
    { pixelRatio: window.devicePixelRatio },
  );
};

const waitForIsRunTrue = () => (
  new Promise((resolve) => {
    const checkIsRun = () => {
      if (isRun) {
        resolve();
      } else {
        setTimeout(checkIsRun, 100);
      }
    };
    checkIsRun();
  })
);

const waitForCountRequiredResourcesMoreZero = () => (
  new Promise((resolve) => {
    const checkIsRun = () => {
      if (requiredResources.size > 0 || !isRun) {
        resolve();
      } else {
        setTimeout(checkIsRun, DELAY_TO_CHECK_OF_REQUIRED_RESOURCES);
      }
    };
    checkIsRun();
  })
);

const runLoadingResources = () => {
  isRun = true;
};

const closeLoadingResources = () => {
  isRun = false;
};

/* const loadResource = (name) => {
  try {
    if (!isRun || name === 'null' || loadedRequiredResources.has(name)) return;
    const metadata = stringToObject(name);
    requiredResources.set(name, metadata);
  } catch (error) {
    console.warn(`Ошибка при загрузке ресурса с именем: ${name}.`, error);
  }
}; */

// Проблема с загрузкой некоторых имён иконок, когда карта не загружена полностью.
map.on('styleimagemissing', (e) => {
  if (e.id) {
    try {
      const resourceName = e.id;

      if (resourceName === 'null') return;

      const config = getConfig(resourceName);

      requiredResources.set(resourceName, config);
    } catch (error) {
      console.warn(`Ошибка при регистрации ресурса с именем: ${e?.id}.`, error);
    }
  }
});

const createImagePromise = (path) => loadImage(path);

const createBackgroundPromise = (value) => (
  new Promise((res) => {
    addImageToMap(value.name, { ...value });
    res();
  })
);

const createMapOfTypesToCategories = () => {
  for (const [key, values] of TYPES_EQUAL_TO_CATEGORIES) {
    for (const value of values) {
      typesSettings.set(value, key);
    }
  }
};

const getSettings = async (dataset, background) => {
  let settings = {};

  const {
    icon, primary, secondary, colorStroke, category,
  } = dataset;

  const imageElement = await loadImage(getPath(icon));

  const type = typesSettings.get(category);

  switch (type) {
    case TYPE_SETTINGS.ColorAndBackground:
      settings = convertToObject(background, imageElement, { color: '#fff', colorBac: secondary });
      break;

    case TYPE_SETTINGS.ColorAndDefaultBackground:
      settings = convertToObject(background, imageElement, { color: palette.common[primary] });
      break;

    case TYPE_SETTINGS.ColorAndStrokeAndDefaultBackground:
      // eslint-disable-next-line no-case-declarations
      const foundColor = palette.common[primary];
      // eslint-disable-next-line no-case-declarations
      const newColor = isUndefinedOrNull(foundColor) ? primary : foundColor;
      settings = convertToObject(background, imageElement, { color: newColor, colorStroke });
      break;

    case TYPE_SETTINGS.Color:
    default:
      settings = convertToObject(null, imageElement, { color: primary, downSize: true });
      break;
  }

  return { ...settings };
};

const getImages = () => (
  [
    '/images/background.svg',
    '/images/icon/position/start.svg',
    '/images/icon/position/finish.svg',
    '/images/icon/position/parking.svg',
    '/images/icon/position/arrow.svg',
    '/images/icon/position/bs.svg',
    '/images/icon/position/point.svg',
    '/images/icon/position/tower-bs.svg',
    '/images/course.svg',
  ].map((path) => createImagePromise(path))
);

const loadBackgroundsResources = (
  background, start, finish, parking, arrow, bs, point, towerBs,
) => {
  const backgroundsResources = [
    { name: 'background', ...convertToObject(background) },
    { name: 'background-beige', ...convertToObject(background, null, { colorBac: palette.common.beige }) },
    { name: 'background-parking', ...convertToObject(background, null, { colorBac: palette.common.blue }) },
    { name: 'background-selected', ...convertToObject(background, null, { colorBac: palette.common.red }) },
    { name: 'emptymoon', ...convertToObject(background, start, { color: palette.common.burgundy, downSize: true }) },
    { name: 'fullmoon', ...convertToObject(background, finish, { color: palette.common.burgundy }) },
    { name: 'parking', ...convertToObject(background, parking, { downSize: true }) },
    { name: 'arrow', ...convertToObject(background, arrow, { color: palette.common.black }) },
    { name: 'arrow-hot', ...convertToObject(background, arrow, { color: palette.common.black, colorBac: palette.common.red }) },
    { name: 'bs', ...convertToObject(background, bs, { color: palette.common.black }) },
    { name: 'bs-hot', ...convertToObject(background, bs, { color: palette.common.black, colorBac: palette.common.red }) },
    { name: 'point', ...convertToObject(background, point, { color: palette.common.black }) },
    { name: 'point-hot', ...convertToObject(background, point, { color: palette.common.black, colorBac: palette.common.red }) },
    { name: 'tower-bs', ...convertToObject(background, towerBs, { color: palette.common.blue2 }) },
  ].map((value) => createBackgroundPromise(value));

  return Promise.all(backgroundsResources);
};

map.on('load', () => {
  // Фоновый поток загружающий ресурсы для иконок и обрабатывающий поступающие запросы на загрузку иконки.
  new Promise(async () => {
    createMapOfTypesToCategories();

    await waitForIsRunTrue();

    const [
      background, start, finish, parking, arrow, bs, point, towerBs, course,
    ] = await Promise.all(getImages());

    await loadBackgroundsResources(background, start, finish, parking, arrow, bs, point, towerBs);

    map.addImage('course', prepareIcon(null, course, { downSize: true }), { pixelRatio: window.devicePixelRatio });

    while (isRun) {
      await waitForCountRequiredResourcesMoreZero();

      const resources = [...requiredResources.entries()];

      for (const [name, dataset] of resources) {
        try {
          const settings = await getSettings(dataset, background);

          addImageToMap(name, settings);

          // loadedRequiredResources.add(name);
        } catch (error) {
          console.warn(`Ошибка при обработке иконки с именем: ${name}.`, error);
        } finally {
          requiredResources.delete(name);
        }
      }
    }
  }).catch((error) => error('Ошибка при загрузке карты!', error));
});

export {
  runLoadingResources, closeLoadingResources, getConfig, convertToConfig,
};
