import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import LinkField from '../../common/form/LinkField';
import { getIsAdmin } from '../../common/utils/selectors';
import { useSelector } from 'react-redux';
import { prefixString } from '../../common/utils/stringUtils';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const GroupPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    /**
     * Устанавливает конфигурационный файл для permissions.
     * В дальнейшем в данном словаре будут изменяться поля linked, old, в которых указаны id элементом,
     * которые будут удаляться (поле old) или добавляться (поле linked) при нажатии на кнопку Сохранить.
     */
    if (item && !permissions) {
      const permissionsDict = {
        notifications: {
          keyLink: 'notificationId',
          baseId: item.id,
          keyBase: 'groupId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  return (
    <EditItemView endpoint="groups" item={item} setItem={setItem} disabledSave={!item?.name} permissions={permissions}>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('settingsGroup')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  required
                  margin="normal"
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                  variant="filled"
                />

                {!!(item.id && permissions) && (
                  <>
                    <LinkField
                      margin="normal"
                      disabled={!isAdmin}
                      endpointAll="/api/notifications"
                      endpointLinked={`/api/notifications?groupId=${item.id}`}
                      titleGetter={(it) => it.attributes?.description || t(prefixString('event', it.type))}
                      label={t('sharedNotifications')}
                      variant="filled"
                      permissions={permissions}
                      setPermissions={setPermissions}
                      type="notifications"
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
    </EditItemView>
  );
};

export default GroupPage;
