import React from 'react';
import { useSelector } from 'react-redux';
import MarkerMovingModeOn from './MarkerMovingModeOn';
import TrackingModeOn from './TrackingModeOn';
import PositionDataButton from './PositionDataButton';
import NewMessages from './NewMessages';
import UpdatingNeedableButton from './UpdatingNeedableButton';
import EventNotifications from './EventNotifications';

const NotificationButtons = ({
  moveableMarker, setMoveableMarker, positionDataVisible, setPositionDataVisible, newSms,
}) => {
  const socketOpened = useSelector((state) => state.session.socketOpened);
  const deviceSelected = useSelector((state) => state.devices.selectedId);
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);

  return (
    <>
      {moveableMarker ? (
        <MarkerMovingModeOn moveableMarker={moveableMarker} setMoveableMarker={setMoveableMarker} />
      ) : (
        <>
          {!!(!positionDataVisible && selectedPositionData) && (
            <PositionDataButton setPositionDataVisible={setPositionDataVisible} />
          )}
          {!!newSms.length && (
            <NewMessages newSms={newSms} />
          )}
          {!socketOpened && (
            <UpdatingNeedableButton />
          )}
        </>
      )}
      {deviceSelected && (
        <TrackingModeOn deviceSelected={deviceSelected} />
      )}
      <EventNotifications />
    </>
  );
};

export default NotificationButtons;
