import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControl, InputLabel, LinearProgress, ListItemText, MenuItem, Select, Typography, useTheme,
} from '@material-ui/core';
import PageLayout from '../../../common/components/PageLayout';
import useReportStyles from '../hooks/useReportStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SuperMenuItem from '../../../common/components/SuperMenuItem';
import ReportSmartFilter from './ReportSmartFilter';
import ReportsMenu from './ReportsMenu';
import SmartTable from '../../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../../common/settings/settingsPage';

const ReportTemplate = ({
  items, progress, progressDevices, setSelectedItem, columnsArray, handleSubmit,
  typeReport, offColumns, setOffColumns, setPositionsPath, progressReport,
  chooseEvents, eventTypes, events, breadcrumbs, map, withoutDevices, devicesList,
  reportColumns,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const classes = useReportStyles();

  const [isLoading, setIsLoading] = useState(false);

  const showLoadingForm = () => setIsLoading(true);
  const hideLoadingForm = () => setIsLoading(false);

  const handleSubmitWithWrap = (deviceIds, from, to, accept) => {
    if (isLoading) return;
    showLoadingForm();
    handleSubmit(deviceIds, from, to, accept, hideLoadingForm);
  };

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      <div className={classes.container}>
        {map}
        <div className={classes.containerMain}>
          <div className={classes.header}>
            <ReportSmartFilter
              progress={progress}
              progressDevices={progressDevices}
              handleSubmit={handleSubmitWithWrap}
              columns={columnsArray}
              typeReport={typeReport}
              off={offColumns}
              setOff={setOffColumns}
              setSelectedItem={setSelectedItem}
              setPositionsPath={setPositionsPath}
              withoutDevices={withoutDevices}
              devicesList={devicesList}
              showOnly
            >
              {events && (
                <div className={classes.filterItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel>{t('reportEventTypes')}</InputLabel>
                    <Select
                      value={eventTypes}
                      onChange={(e) => chooseEvents(e.target.value)}
                      multiple
                      renderValue={(selected) => {
                        const eventsSelected = selected.map((selectedItem) => t(events[selectedItem]));
                        return eventsSelected.join(', ');
                      }}
                      MenuProps={theme.overrides.MenuProps}
                    >
                      <MenuItem value="all">
                        <SuperMenuItem all={eventTypes.length} />
                      </MenuItem>
                      {Object.keys(events).map((key) => (
                        <MenuItem value={key} style={{ padding: theme.spacing(0, 1) }} key={key}>
                          <Checkbox checked={eventTypes.includes(key)} />
                          <ListItemText primary={t(events[key])} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </ReportSmartFilter>
          </div>

          {isLoading ? (
            <>
              <Typography>{`${t('sharedFoundObjects')}: ${progressReport} ${t('sharedNumbers')}`}</Typography>
              <LinearProgress />
            </>
          ) : (
            <SmartTable
              enableRepaintChangesOnly
              tableName={typeReport}
              fileExportingName={typeReport}
              allowedPageSizes={allowedPageSizes}
              currentPageSize={currentPageSize}
              arrayDependencies={[items]}
              dataSource={items}
              classes={classes}
            >
              {reportColumns()}
            </SmartTable>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ReportTemplate;

ReportTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  progress: PropTypes.number.isRequired,
  columnsArray: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  typeReport: PropTypes.string.isRequired,
  offColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOffColumns: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedItem: PropTypes.func,
  setPositionsPath: PropTypes.func,
  chooseEvents: PropTypes.func,
  eventTypes: PropTypes.array,
  events: PropTypes.objectOf(PropTypes.string),
  map: PropTypes.element,
  withoutDevices: PropTypes.bool,
};
