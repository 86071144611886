import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Column } from 'devextreme-react/data-grid';
import {
  abortRequest,
  collectReport,
  formatPosition, getCategoryParam,
} from '../../common/utils/formatter';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { positionHandler } from '../../common/utils/requestRoute';
import logout from '../../common/utils/logout';
import ReportSmartTemplate from './components/ReportSmartTemplate';
import ReplayPathMap from '../../map/ReplayPathMap';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { getGroupName } from '../../common/components/SmartTable/funcs/calculateCellValue';
import { errorsActions } from '../../store';
import useTemplatesFieldsPresetForReports from '../../common/components/SmartTable/hooks/columnTemplates/reportTables/useTemplatesFieldsPresetForReports';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'detectedObjectReport';

const columnsArray = [];

const DetectedObjectsReportPage = () => {
  const category = 'detected_object';
  const t = useTranslation();
  const classes = useReportStyles();
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const history = useHistory();
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups.items);

  const [devicesObject, setDevicesObject] = useState({});
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [positionsPath, setPositionsPath] = useState({});
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const reportAbortController = useRef();
  const devicesAbortController = useRef();

  const templateField = useTemplatesFieldsPresetForReports(devicesObject, category);
  const reportColumns = useAutoCompleteColumns(templateField);

  const requestDevices = async (signal) => {
    const requestName = uuidv4();
    try {
      const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
      if (response.ok) {
        const result = [];
        await collectReport(response, result, setCountProgress);

        const resultObj = {};
        result.forEach((item) => resultObj[item.id] = item);
        setDevicesObject(resultObj);
      } else if (response.status === 401) {
        if (response.status === 401) {
          logout(history, dispatch);
        }
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        dispatch(errorsActions.push(error.message));
      } else {
        await abortRequest(requestName);
      }
      throw Error(error);
    }
  };

  const handleSubmit = async (deviceId, from, to, headers, hideLoadingForm) => {
    devicesAbortController.current = new AbortController();
    const signalDevices = devicesAbortController.current.signal;

    try {
      await requestDevices(signalDevices);
    } catch (error) {
      return;
    }
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    setProgress(1);

    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to, category,
        });
        const response = await fetch(`/api/reports/detected?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              const result = [];
              await collectReport(response, result, setProgressReport);
              hideLoadingForm();
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
      }
    };
    await apiCall();
    setProgress(100);
    setProgress(0);
  };

  const formatSmartValue = (key, caption, devices) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="datetime"
            caption={t(caption)}
          />
        );
      case 'uniqueId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.uniqueId}
            caption={t(caption)}
          />
        );
      case 'sourceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getCategoryParam(item, key)}
            caption={t(caption)}
          />
        );
      case 'deviceId':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => devices[item[key]]?.name}
            caption={t(caption)}
          />
        );
      case 'group':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => getGroupName(devices[item.deviceId] ?? {}, groups)}
            caption={t(caption)}
          />
        );
      case 'longitude':
      case 'latitude':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'address':
        return (
          <Column
            key={key}
            name={key}
            dataField={key}
            dataType="string"
            calculateCellValue={(item) => formatPosition(item[key], key, t)}
            caption={t(caption)}
          />
        );
      case 'sector':
      case 'centerFrequency':
      case 'bandwidth':
      case 'averageSnr':
      case 'comment':
      case 'peleng':
      case 'dist':
      case 'zone':
        return (
          <Column
            key={key}
            name={`attributes.categoryParams.${key}`}
            dataField={`attributes.categoryParams.${key}`}
            calculateCellValue={(item) => (item.attributes.categoryParams ? (item.attributes.categoryParams[key] ?? '') : '')}
            caption={t(caption)}
          />
        );
      default:
        return (
          <Column
            dataField={key}
            caption={t(caption)}
            dataType="string"
          />
        );
    }
  };

  useEffect(() => {
    if (selectedItem && !Object.keys(positionsPath).length) {
      const path = items.reduce((a, v) => ({ ...a, [v.deviceId]: a[v.deviceId] ? [...a[v.deviceId], v] : [v] }), {});
      const deviceIds = Object.keys(path);
      const result = {};
      for (let i = 0; i < deviceIds.length; i += 1) {
        const deviceId = deviceIds[i];
        const clearResponsePath = [];
        positionHandler(path[deviceId], clearResponsePath, true);
        result[deviceId] = clearResponsePath;
      }
      setPositionsPath(result);
    }
  }, [selectedItem]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => () => {
    if (devicesAbortController.current) {
      devicesAbortController.current.abort(); // Cancel the request
    }
  }, []);

  return (
    <ReportSmartTemplate
      items={items}
      progress={progress}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      typeSortingDefault="fixTime"
      formatValue={formatSmartValue}
      reportColumns={reportColumns}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      positionsPath={positionsPath}
      setPositionsPath={setPositionsPath}
      breadcrumbs={['reportTitle', 'reportDetectedObject']}
      devicesObject={devicesObject}
      withoutDevices
      countProgress={countProgress}
      progressReport={progressReport}
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <ReplayPathMap positions={positionsPath} devices={devicesObject} />
            <PositionsMap
              positions={{ [selectedItem.deviceId]: [selectedItem] }}
              devices={devicesObject}
              data={positionsTypes.current}
              noClick
            />
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default DetectedObjectsReportPage;
