import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getIsAdmin } from '../../common/utils/selectors';
import EditItemView from './components/EditItemView';
import DeviceEdit from '../../common/components/PositionData/DeviceEdit';

const DevicePage = () => {
  const isAdmin = useSelector(getIsAdmin);
  const { id } = useParams();

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [errors, setErrors] = useState({});
  const [initState, setInitState] = useState({ category: 'device', model: 'default' });

  return (
    <EditItemView
      endpoint="devices"
      item={item}
      setItem={setInitState}
      permissions={permissions}
      disabledSave={isAdmin && !(item && item.name && item.uniqueId && !Object.values(errors).some((v) => v))}
    >
      <DeviceEdit
        item={item}
        setItem={setItem}
        errors={errors}
        setErrors={setErrors}
        initState={initState}
        permissions={permissions}
        setPermissions={setPermissions}
        isNew={!id}
      />
    </EditItemView>
  );
};

export default DevicePage;
