import {
  Backdrop, Fade, makeStyles, Modal, Paper, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyle = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    backgroundColor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  text: {
    marginTop: theme.spacing(1.5),
  },
  version: {
    marginLeft: theme.spacing(1),
  },
}));

const VersionInfo = ({ open, setOpen }) => {
  const classes = useStyle();
  const t = useTranslation();

  const servicesVersions = useSelector((state) => state.session.servicesVersions);

  const [versions, setVersions] = useState(servicesVersions);

  useEffect(() => {
    setVersions(servicesVersions);
  }, [servicesVersions]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.modal}>
          <Typography variant="inherit" component="h6" align="center" className={classes.text}>
            {t('globalVersion')}
          </Typography>
          <Typography variant="caption" component="h6" align="center">
            {`web: ${versions.website}`}
          </Typography>
          <Typography variant="caption" component="h6" align="center">
            {`server: ${servicesVersions.server}`}
          </Typography>
          {servicesVersions.bscoder && (
            <Typography variant="caption" component="h6" align="center">
              {`bscoder: ${servicesVersions.bscoder}`}
            </Typography>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default VersionInfo;
