import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';

/**
 * Настроенный компонент страницы радаров, с заголовком и сменой фреймов для избежания утечек памяти.
 */
const RadarsPageView = () => {
  const getComponent = useComponentsController();

  return (
    <>
      {getComponent(availableComponents.RadarsPage, {})}
    </>
  );
};

export default RadarsPageView;
