/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
import './miniMap.css';
import maplibregl from 'maplibre-gl';

export default function Minimap(options) {
  Object.assign(this.options, options);

  this.ticking = false;
  this.lastMouseMoveEvent = null;
  this.parentMap = null;
  this.isDragging = false;
  this.isCursorOverFeature = false;
  this.previousPoint = [0, 0];
  this.currentPoint = [0, 0];
  this.trackingRectCoordinates = [[[], [], [], [], []]];
}

Minimap.prototype = {
  ...maplibregl.NavigationControl.prototype,
  options: {
    id: 'maplibregl-minimap',
    width: '320px',
    height: '200px',
    center: [0, 0],
    zoom: 0,

    zoomAdjust: null,

    // if parent map zoom >= 18 and minimap zoom >= 13, set minimap zoom to 14
    zoomLevels: [
      [18, 13, 14],
      [17, 12, 13],
      [16, 11, 12],
      [15, 10, 11],
      [14, 9, 10],
      [13, 8, 9],
      [12, 7, 8],
      [11, 6, 7],
      [10, 5, 6],
      [9, 4, 5],
      [8, 3, 4],
      [7, 2, 3],
      [6, 1, 2],
      [5, 0, 1],
      [4, 0, 0],
      [3, 0, 0],
      [2, 0, 0],
      [1, 0, 0],
    ],

    lineColor: '#08F',
    lineWidth: 1,
    lineOpacity: 1,

    fillColor: '#F80',
    fillOpacity: 0.25,

    dragPan: false,
    scrollZoom: true,
    boxZoom: false,
    dragRotate: false,
    keyboard: false,
    doubleClickZoom: false,
    touchZoomRotate: false,
  },

  onAdd(parentMap) {
    this.parentMap = parentMap;

    const opts = this.options;
    const container = this.container = this.createContainer(parentMap);
    const miniMap = this.miniMap = new maplibregl.Map({
      attributionControl: false,
      container,
      style: opts.style,
      zoom: opts.zoom,
      center: opts.center,
    });

    container.style.border = '2px solid black';
    container.classList.add('maplibregl-canvas-container-minimap');

    if (opts.maxBounds) miniMap.setMaxBounds(opts.maxBounds);

    miniMap.on('load', this.load.bind(this));
    return this.container;
  },

  newStyle(style) {
    const opts = this.options;

    const intervalID = setInterval(() => {
      if (!(this.miniMap === undefined)) {
        this.miniMap.setStyle(style);
        this.miniMap.once('styledata', () => {
          if (this.miniMap.getSource('trackingRect') === undefined) {
            this.miniMap.addSource('trackingRect', {
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {
                  name: 'trackingRect',
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: this.trackingRectCoordinates,
                },
              },
            });
          }
          if (this.miniMap.getLayer('trackingRectOutline') === undefined) {
            this.miniMap.addLayer({
              id: 'trackingRectOutline',
              type: 'line',
              source: 'trackingRect',
              layout: {},
              paint: {
                'line-color': opts.lineColor,
                'line-width': opts.lineWidth,
                'line-opacity': opts.lineOpacity,
              },
            });
          }

          if (this.miniMap.getLayer('trackingRectFill') === undefined) {
            this.miniMap.addLayer({
              id: 'trackingRectFill',
              type: 'fill',
              source: 'trackingRect',
              layout: {},
              paint: {
                'fill-color': opts.fillColor,
                'fill-opacity': opts.fillOpacity,
              },
            });
          }

          this.trackingRect = this.miniMap.getSource('trackingRect');

          this.parentMap.on('move', this.update.bind(this));

          this.miniMap.on('mousemove', this.mouseMove.bind(this));
          this.miniMap.on('mousedown', this.mouseDown.bind(this));
          this.miniMap.on('mouseup', this.mouseUp.bind(this));

          this.miniMap.on('touchmove', this.mouseMove.bind(this));
          this.miniMap.on('touchstart', this.mouseDown.bind(this));
          this.miniMap.on('touchend', this.mouseUp.bind(this));

          this.miniMapCanvas = this.miniMap.getCanvasContainer();
          this.miniMapCanvas.addEventListener('wheel', this.preventDefault);
          this.miniMapCanvas.addEventListener('mousewheel', this.preventDefault);

          clearInterval(intervalID);
        });
      }
    }, 100);
  },

  load() {
    const opts = this.options;
    const { miniMap } = this;
    const interactions = [
      'dragPan', 'scrollZoom', 'boxZoom', 'dragRotate',
      'keyboard', 'doubleClickZoom', 'touchZoomRotate',
    ];
    interactions.forEach((i) => {
      if (opts[i] !== true) {
        miniMap[i].disable();
      }
    });

    if (typeof opts.zoomAdjust === 'function') {
      this.options.zoomAdjust = opts.zoomAdjust.bind(this);
    } else if (opts.zoomAdjust === null) {
      this.options.zoomAdjust = this.zoom_Adjust.bind(this);
    }

    const bounds = miniMap.getBounds();

    this.convertBoundsToPoints(bounds);
    this.update();
  },

  mouseDown(e) {
    if (this.isCursorOverFeature) {
      this.isDragging = true;
      this.previousPoint = this.currentPoint;
      this.currentPoint = [e.lngLat.lng, e.lngLat.lat];
    }
  },

  mouseMove(e) {
    this.ticking = false;

    const { miniMap } = this;
    const features = miniMap.queryRenderedFeatures(e.point, {
      layers: ['trackingRectFill'],
    });

    // don't update if we're still hovering the area
    if (!(this.isCursorOverFeature && features.length > 0)) {
      this.isCursorOverFeature = features.length > 0;
      this.miniMapCanvas.style.cursor = this.isCursorOverFeature ? 'move' : '';
    }

    if (this.isDragging) {
      this.previousPoint = this.currentPoint;
      this.currentPoint = [e.lngLat.lng, e.lngLat.lat];

      const offset = [
        this.previousPoint[0] - this.currentPoint[0],
        this.previousPoint[1] - this.currentPoint[1],
      ];

      const newBounds = this.moveTrackingRect(offset);

      this.parentMap.fitBounds(newBounds, {
        duration: 80,
        noMoveStart: true,
      });
    }
  },

  mouseUp() {
    this.isDragging = false;
    this.ticking = false;
  },

  moveTrackingRect(offset) {
    const source = this.trackingRect;
    const data = source._data;
    const { bounds } = data.properties;

    bounds._ne.lat -= offset[1];
    bounds._ne.lng -= offset[0];
    bounds._sw.lat -= offset[1];
    bounds._sw.lng -= offset[0];

    this.convertBoundsToPoints(bounds);
    source.setData(data);
    return bounds;
  },

  setTrackingRectBounds(bounds) {
    const source = this.trackingRect;
    const data = source._data;

    data.properties.bounds = bounds;
    this.convertBoundsToPoints(bounds);
    source.setData(data);
  },

  convertBoundsToPoints(bounds) {
    const ne = bounds._ne;
    const sw = bounds._sw;
    const trc = this.trackingRectCoordinates;

    trc[0][0][0] = ne.lng;
    trc[0][0][1] = ne.lat;
    trc[0][1][0] = sw.lng;
    trc[0][1][1] = ne.lat;
    trc[0][2][0] = sw.lng;
    trc[0][2][1] = sw.lat;
    trc[0][3][0] = ne.lng;
    trc[0][3][1] = sw.lat;
    trc[0][4][0] = ne.lng;
    trc[0][4][1] = ne.lat;
  },

  update() {
    if (this.isDragging) {
      return;
    }

    const parentBounds = this.parentMap.getBounds();

    this.setTrackingRectBounds(parentBounds);

    if (typeof this.options.zoomAdjust === 'function') {
      this.options.zoomAdjust();
    }
  },

  zoom_Adjust() {
    const { miniMap } = this;
    const { parentMap } = this;
    const miniZoom = 18;
    const parentZoom = parseInt(parentMap.getZoom(), 10);
    const levels = this.options.zoomLevels;
    let found = false;

    levels.forEach((zoom) => {
      if (!found && parentZoom >= zoom[0]) {
        if (miniZoom >= zoom[1]) {
          miniMap.setZoom(zoom[2]);
        }

        miniMap.setCenter(parentMap.getCenter());
        found = true;
      }
    });

    if (!found && miniZoom !== this.options.zoom) {
      if (typeof this.options.bounds === 'object') {
        miniMap.fitBounds(this.options.bounds, { duration: 50 });
      }

      miniMap.setZoom(this.options.zoom);
    }
  },

  createContainer(parentMap) {
    const opts = this.options;
    const container = document.createElement('div');

    container.className = 'maplibregl-ctrl-minimap maplibregl-ctrl';
    container.setAttribute('style', `width: ${opts.width}; height: ${opts.height};`);
    container.addEventListener('contextmenu', this.preventDefault);

    parentMap.getContainer().appendChild(container);

    if (opts.id !== '') {
      container.id = opts.id;
    }

    return container;
  },

  preventDefault(e) {
    e.preventDefault();
  },
};

maplibregl.Minimap = Minimap;
