import { TEMPORARY_PROPERTY } from '../constants';
import { translateConnectionStatusBase, translateCurrentWorkModeBase } from './sharedCalculateCellValue';
import { parseSettingsFromString } from './utils';

export const translateCurrentWorkModeForTemporaryField = (item, t) => {
  const [workMode, description] = parseSettingsFromString(item[TEMPORARY_PROPERTY.CurrentWorkMode]);
  return translateCurrentWorkModeBase(item, t, description, workMode);
};

export const translateConnectionStatusForTemporaryField = (item, t) => {
  const connectionStatus = item[TEMPORARY_PROPERTY.ConnectionStatus];
  return translateConnectionStatusBase(t, connectionStatus);
};
