import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PositionsMap from '../../../map/PositionsMap';
import BSMap from '../../../map/BSMap';
import GeofenceMap from '../../../map/GeofenceMap';
import SelectedDeviceMap from '../../../map/SelectedDeviceMap';
import SelectedPositionMap from '../../../map/SelectedPositionMap';
import CurrentPositionsMap from '../../../map/CurrentPositionsMap';
import Map, { map } from '../../../map/Map';
import positionsTypes from '../../../common/static/positionsTypes';
import ReplayPathMap from '../../../map/ReplayPathMap';
import MarkerOneMap from '../../../map/MarkerOneMap';
import MarkersMap from '../../../map/MarkersMap';
import RulerMap from '../../../map/RulerMap';
import CurrentLocationMap from '../../../map/CurrentLocationMap';
import MapCamera from '../../../map/MapCamera';
import { runLoadingResources } from '../../../map/MapControllerDynamic';
import ExtraLayers from '../../../map/ExtraLayers';
import ExtraMap from '../../../map/ExtraMap';

const MainMap = ({
  item, panel, setNewMarkerCoordinates, setSelectedMarkerComponents,
  nextPosition, setCountPosition, setNextPosition, statePositions, needMoveMap, addMarkerMode, setAddMarkerMode,
  moveableMarker, setMoveableMarker, rulerMode, setRulerDistance, setPositionDataVisible, setPositionsBS,
  markerState, clearMarkerState, moveableDevice, setMoveableDevice, opacityMaps, searchCoords,
}) => {
  const devices = useSelector((state) => state.devices.items);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const stateSyncDevices = useSelector((state) => state.devices.stateSyncDevices);
  const stateSyncClosedDevices = useSelector((state) => state.devices.stateSyncClosedDevices);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const stateSyncCurrentPositions = useSelector((state) => state.positions.stateSyncCurrentPositions);

  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  const needToShowDataPanel = useSelector((state) => state.positions.needToShowDataPanel);

  const positions = useSelector((state) => state.tail.positions);
  const positionsPath = useSelector((state) => state.tail.positionsPath);
  const positionsStop = useSelector((state) => state.tail.positionsStop);
  const positionsSimilar = useSelector((state) => state.tail.positionsSimilar);
  const pathMatching = useSelector((state) => state.tail.pathMatching);

  const stateSyncTail = useSelector((state) => state.tail.stateSyncTail);

  // Запуск инициализации ресурсов карты.
  useEffect(() => runLoadingResources(), []);

  useEffect(() => {
    /**
     * Изменение стиля курсора при использовании инструмента Маркер
     */
    if (addMarkerMode || moveableMarker || moveableDevice) {
      map.getCanvas().style.cursor = 'crosshair';
    } else {
      map.getCanvas().style.cursor = 'grab';
    }
  }, [addMarkerMode, moveableMarker, moveableDevice, map.getCanvas().style.cursor]);

  return (
    <Map needMoveMap={needMoveMap} searchCoords={searchCoords}>
      <ExtraMap opacityMaps={opacityMaps} />
      {item && (
        <>
          {panel.onLine === 1 && (
            <ReplayPathMap positions={positionsPath} devices={devices} stateSyncDevices={stateSyncDevices} stateSyncPositions={stateSyncTail} tail />
          )}
          {panel.onLine === 2 && (
            <ReplayPathMap positions={pathMatching} devices={devices} stateSyncDevices={stateSyncDevices} stateSyncPositions={stateSyncTail} tail matching />
          )}
          {panel.onPositions && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              positionsSimilar={positionsSimilar}
              data={positionsTypes.arrow}
              onClusters={panel.onClustersPositions}
              text={panel.onTextPositions}
              nextPosition={nextPosition}
              setCountPosition={setCountPosition}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {panel.onSides && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.startPoint}
              onClusters={panel.onClustersSides}
              text={panel.onTextSides}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {!!(panel.onSides && !panel.onCurrent) && (
            <PositionsMap
              positions={positions}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.finishPoint}
              onClusters={panel.onClustersSides}
              text={panel.onTextSides}
              setNextPosition={setNextPosition}
              setPositionsBS={statePositions.setPositionsBS}
              onTower={panel.onTower}
              noClick={addMarkerMode || rulerMode}
            />
          )}
          {panel.onStops && (
            <PositionsMap
              positions={positionsStop}
              devices={devices}
              stateSyncDevices={stateSyncDevices}
              stateSyncPositions={stateSyncTail}
              stateSyncClosedDevices={stateSyncClosedDevices}
              closedDevices={closedDevices}
              data={positionsTypes.stop}
              setNextPosition={setNextPosition}
              onClusters={panel.onClustersStops}
              text={panel.onTextStops}
              setPositionsBS={statePositions.setPositionsBS}
              noClick={addMarkerMode || rulerMode}
            />
          )}
        </>
      )}
      {!!(statePositions.positionsBS.length && panel.onTower) && (
        <BSMap
          positions={statePositions.positionsBS}
          data={positionsTypes.bs}
          onTower={panel.onTower}
        />
      )}
      {panel.onGeofence && (
        <GeofenceMap />
      )}
      {/* {panel.onCurrent && (
        <AccuracyMap closedDevices={closedDevices} />
      )} */}
      <SelectedDeviceMap />
      {!!(Object.keys(selectedPositionData).length && (selectedDeviceId || needToShowDataPanel)) && <SelectedPositionMap position={selectedPositionData} />}

      {Object.keys(selectedPositionData).length && <MapCamera latitude={selectedPositionData.latitude} longitude={selectedPositionData.longitude} />}
      <CurrentPositionsMap
        setNextPosition={setNextPosition}
        onClusters={panel.onClustersCurrent}
        text={panel.onTextCurrent}
        visibility="hidden"
        onCurrent={panel.onCurrent}
        setPositionsBS={statePositions.setPositionsBS}
        onTower={panel.onTower}
        noClick={addMarkerMode || rulerMode}
        stateSyncPositions={stateSyncCurrentPositions}
        moveableDevice={moveableDevice}
        setMoveableDevice={setMoveableDevice}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
      />
      <MarkerOneMap
        addMarkerMode={addMarkerMode}
        setAddMarkerMode={setAddMarkerMode}
        markerState={markerState}
        clearMarkerState={clearMarkerState}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
      />
      {panel.onMarkers && (
        <MarkersMap
          noClick={addMarkerMode || rulerMode}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          setNextPosition={setNextPosition}
          setNewMarkerCoordinates={setNewMarkerCoordinates}
          moveableMarker={moveableMarker}
          setMoveableMarker={setMoveableMarker}
          onClusters={panel.onClustersMarkers}
          text={panel.onTextMarkers}
          markerState={markerState}
          clearMarkerState={clearMarkerState}
          setSelectedMarkerComponents={setSelectedMarkerComponents}
        />
      )}
      <RulerMap rulerMode={rulerMode} setDistance={setRulerDistance} />
      <CurrentLocationMap />
      <ExtraLayers />
    </Map>
  );
};

export default MainMap;
