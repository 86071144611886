import React, { useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, ButtonGroup, IconButton, Typography,
} from '@material-ui/core';
import RemoveDialog from './common/components/RemoveDialog';
import { useTranslation } from './common/components/LocalizationProvider';
import { devicesActions, tailActions } from './store';
import { getPosition } from './common/utils/selectors';
import { getCategory } from './common/utils/formatter';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    boxShadow: 'none',
    zIndex: 2,
  },
  menuEditRemove: {
    zIndex: -1,
    position: 'relative',
  },
  stickyTop: {
    top: theme.spacing(9),
    right: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },
}));

const EditCollectionView = ({
  content,
  editPath,
  endpoint,
  disableAdd,
  handleClose,
  setSaveMode,
  onTail,
  sortValue,
  setCommandsPanelDevice,
  progress,
  searchName,
  mainPage,
  forseAdd,
  textOfAddButton,
  stickyTop,
  calculatePopupPosition,
  hideButtonAdd,
  removeEnable,
}) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const [selectedId, setSelectedId] = useState(null);
  const [item, setItem] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());

  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const devicesParams = useSelector((state) => state.devices.itemsParams);
  const tailSelectedDevices = useSelector((state) => state.tail.selectedDevices);
  const tailSelectedCategories = useSelector((state) => state.tail.selectedCategories);
  const adminEnabled = useSelector((state) => state.session.user && state.session.user.administrator);
  const position = useSelector(getPosition(item?.id));
  const device = useSelector((state) => state.devices.items[position?.deviceId]);

  const isDesktopAndHaveText = useMediaQuery(theme.breakpoints.up('lg')) && textOfAddButton;

  const popupPosition = useRef({ top: 0, left: 0 });

  const menuShow = (anchorId, itemId, item) => {
    if (calculatePopupPosition) {
      try {
        // Получении позиции боковых троеточий у виртуального элемента.
        const position = document.getElementById(item.id).getBoundingClientRect();
        popupPosition.current = { top: position.y, left: position.left - 145 };
      } catch (error) {
        console.warn('Ошибка при получении позиции виртуального элемента для настройки позиционирования всплывающего меню.', error);
      }
    }

    setSelectedId(itemId);
    setItem(item);
    if (anchorId) {
      setSelectedAnchorEl(anchorId);
    } else {
      setRemoveDialogShown(true);
    }
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  };

  const handleEdit = () => {
    if (endpoint === 'geofences') {
      setSaveMode(selectedId);
    } else {
      history.push(`${editPath}/${selectedId}`);
    }
    menuHide();
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleCommands = () => {
    setCommandsPanelDevice(item);
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  };

  const changeVisibleDevice = () => {
    if (!closedDevices[position.deviceId]) {
      dispatch(devicesActions.hide(position.deviceId));
      dispatch(tailActions.changeState({ [position.deviceId]: false }));
    } else {
      dispatch(devicesActions.open(position.deviceId));
      dispatch(tailActions.changeState({ [position.deviceId]: true }));
    }
    menuHide();
  };

  const changeVisibleIconText = (val) => {
    dispatch(devicesActions.changeItemParam({ [position.deviceId]: { iconText: val } }));
  };

  const changeTail = (val) => {
    dispatch(tailActions.changeDevice({ [position.deviceId]: val }));
    if (val === false || (val === null && tailSelectedCategories[getCategory(device)] === false)) {
      dispatch(tailActions.remove([position.deviceId]));
    } else {
      dispatch(tailActions.changeState({[ position.deviceId]: true }));
    }
  };

  const getIconTextButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (devicesParams[position?.deviceId]?.iconText ?? null) === val ? '#deefd8' : '',
  });

  const getTailButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (tailSelectedDevices[position?.deviceId] ?? null) === val ? '#deefd8' : '',
  });

  const Content = content;

  return (
    <>
      <Content
        updateTimestamp={updateTimestamp}
        onMenuClick={menuShow}
        handleClose={handleClose}
        onTail={onTail}
        sortValue={sortValue}
        progress={progress}
        searchName={searchName}
        onAddNewRow={handleAdd}
        selectedAnchorEl={selectedAnchorEl}
      />
      {!!((adminEnabled || forseAdd) && !disableAdd && !hideButtonAdd) && (
        <Fab
          className={`${classes.fab} ${stickyTop && classes.stickyTop}`}
          title={textOfAddButton}
          variant={isDesktopAndHaveText ? 'extended' : 'circular'}
          size="small"
          color="default"
          onClick={handleAdd}
        >
          <AddIcon />
          {isDesktopAndHaveText && (textOfAddButton)}
        </Fab>
      )}
      {mainPage ? (
        <Menu
          style={calculatePopupPosition && {
            left: popupPosition.current.left,
            top: popupPosition.current.top,
          }}
          open={!!selectedAnchorEl}
          anchorEl={selectedAnchorEl}
          onClose={menuHide}
          className={classes.menuEditRemove}
        >
          <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
          <MenuItem onClick={() => changeVisibleDevice()} disabled={!position || !!progress}>
            {!closedDevices[position?.deviceId] ? t('sharedHide') : t('sharedShow')}
          </MenuItem>
          <MenuItem disabled={!position || !!progress} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ButtonGroup fullWidth variant="outlined" size="small">
              <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => changeVisibleIconText(null)}>
                <Typography variant="caption">{t('auto')}</Typography>
              </Button>
              <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => changeVisibleIconText(true)}>
                <IconButton style={{ color: '#198cff' }} size="small"><TextFieldsIcon size="small" /></IconButton>
              </Button>
              <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => changeVisibleIconText(false)}>
                <IconButton size="small"><TextFieldsIcon size="small" /></IconButton>
              </Button>
            </ButtonGroup>
          </MenuItem>
          <MenuItem disabled={!position || !!progress} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ButtonGroup fullWidth variant="outlined" size="small">
              <Button size="small" style={getTailButtonStyle(null)} onClick={() => changeTail(null)}>
                <Typography variant="caption">{t('auto')}</Typography>
              </Button>
              <Button size="small" style={getTailButtonStyle(true)} onClick={() => changeTail(true)}>
                <img
                  src="/images/icon/switch/tail-1.svg"
                  alt=""
                />
              </Button>
              <Button size="small" style={getTailButtonStyle(false)} onClick={() => changeTail(false)}>
                <img
                  src="/images/icon/switch/tail-off.svg"
                  alt=""
                />
              </Button>
            </ButtonGroup>
          </MenuItem>
          {device?.phone && (
            <MenuItem onClick={handleCommands}>{t('commandSendSms')}</MenuItem>
          )}
        </Menu>
      ) : (
        <Menu
          open={!!selectedAnchorEl}
          anchorEl={selectedAnchorEl}
          onClose={menuHide}
          className={classes.menuEditRemove}
        >
          <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
          {(adminEnabled || removeEnable) && (
            <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
          )}
        </Menu>
      )}
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
    </>
  );
};

export default EditCollectionView;
