import React, { useState } from 'react';
import {
  Box, Checkbox, DialogTitle, FormControl, Grid, ListItemIcon, MenuItem, Modal, Select, TextField, Typography, Button,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions } from '../../../store';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { toTitleCase } from '../../../common/utils/formatter';

export default function PeriodModal({ modalOpen, setModalOpen, category }) {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const timesList = ['second', 'minute', 'hour', 'day', 'week', 'month'];

  const dispatch = useDispatch();
  const t = useTranslation();
  const devices = useSelector((state) => state.devices.items);
  const closedDevices = useSelector((state) => state.devices.closedDevices);

  const [modalInput, setModalInput] = useState();
  const [modalCheckbox, setModalCheckbox] = useState(false);
  const [modalSelect, setModalSelect] = useState('day');

  function isDateInRange(dateObject) {
    const dateToCheck = moment().subtract(modalInput, modalSelect);
    const date = moment(dateObject);
    return date.isBetween(dateToCheck, moment(), null, '[]');
  }

  function isDeviceCategory(deviceCategorie) {
    if (!category) {
      return true;
    }
    if (deviceCategorie === category) {
      return true;
    }
    return false;
  }

  const handleChangeModalInput = (e) => {
    if (e.target.value > 0 && e.target.value <= 500) {
      setModalInput(e.target.value);
    }
  };

  const handleClickModalCheckbox = () => {
    setModalCheckbox(!modalCheckbox);
  };

  const handleChangeModalSelect = (e) => {
    setModalSelect(e.target.value);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalInput();
    setModalCheckbox(false);
    setModalSelect('day');
  };

  const handleSubmitModal = () => {
    const result = { ...closedDevices };

    Object.keys(result).forEach((deviceId) => {
      if (isDateInRange(devices[deviceId]?.lastUpdate) && isDeviceCategory(devices[deviceId]?.category)) {
        if (modalOpen === 'show') {
          result[deviceId] = false;
        }
        if (modalOpen === 'hide') {
          result[deviceId] = true;
        }
      } else if (modalCheckbox && isDeviceCategory(devices[deviceId]?.category)) {
        if (modalOpen === 'show') {
          result[deviceId] = true;
        }
        if (modalOpen === 'hide') {
          result[deviceId] = false;
        }
      }
    });

    dispatch(devicesActions.turnAll(result));
    handleCloseModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
    >
      <Box sx={modalStyle}>
        <DialogTitle style={{ textAlign: 'center', paddingTop: 0 }}>{modalOpen === 'show' ? t('showForPeriodOnMap') : t('hideForPeriodOnMap')}</DialogTitle>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <TextField
              label={t('sharedChoosePeriod')}
              value={modalInput}
              inputProps={{ min: 1, max: 500 }}
              fullWidth
              margin="normal"
              variant="filled"
              type="number"
              onChange={(e) => handleChangeModalInput(e)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl
              style={{ whiteSpace: 'nowrap' }}
              fullWidth
              margin="normal"
            >
              <Select
                value={modalSelect}
                fullWidth
                onChange={(e) => { handleChangeModalSelect(e); }}
              >
                {timesList.map((val) => (
                  <MenuItem key={val} value={val}>
                    {t(`globalTime${toTitleCase(val)}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MenuItem key={7} style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px' }} button onClick={handleClickModalCheckbox}>
              <ListItemIcon>
                <Checkbox checked={modalCheckbox} />
              </ListItemIcon>
              <Typography>
                {modalOpen === 'show' ? t('hideOtherOnMap') : t('showOtherOnMap')}
              </Typography>
            </MenuItem>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" color="primary" fullWidth variant="contained" onClick={handleSubmitModal} disabled={!modalInput}>
              {modalOpen === 'show' ? t('showOnMap') : t('hideOnMap')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
