import sector from '@turf/sector';
import { map } from '../Map';
import palette from '../../common/theme/palette';
import { errorsActions } from '../../store';
import orderLayers from '../../common/static/orderLayers';

export const getBeforeId = (currentId) => {
  const indexId = orderLayers.findIndex((v) => currentId === v);
  if (indexId + 1 !== orderLayers.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const layer of orderLayers.slice(indexId + 1)) {
      if (map.getLayer(layer)) {
        return layer;
      }
    }
  }
  return '';
};

const createFeature = (type, color) => (
  {
    lineOpacity: type === 'timeGap' ? 0.3 : 1,
    color: color || palette.tracks.replay0,
  }
);

export const createGeojsonPath = (id) => {
  map.addSource(id, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
  map.addLayer({
    source: id,
    id,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 4,
      'line-dasharray': [2, 2],
      'line-opacity': { type: 'identity', property: 'lineOpacity' },
    },
  }, getBeforeId(id));

  return () => {
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }
  };
};

export const pathDataFromPositions = (values, devices) => {
  let features = [];
  Object.keys(values).forEach((id) => {
    const feature = values[id].map((position) => {
      let typePath;
      let path = position;
      if (!Array.isArray(position)) {
        [typePath] = Object.keys(position);
        path = position[typePath];
      }
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: path.map((item) => item?.longitude && [item.longitude, item.latitude]),
        },
        properties: createFeature(typePath, devices[id]?.attributes.color),
      };
    });
    features = [...features, ...feature];
  });

  return {
    type: 'FeatureCollection',
    features,
  };
};

const requestMatchOSRM = async (line, movement, dispatch) => {
  const positions = line.filter((position) => position.accuracy < 100);
  if (positions.length < 2) {
    return [];
  }

  const query = new URLSearchParams({
    coordinates: positions.map((position) => `${position.longitude},${position.latitude}`).join(';'),
    timestamps: positions.map((position) => parseInt(new Date(position.fixTime).valueOf() / 1000, 10)).join(';'),
    radiuses: positions
      .map((position) => (position.accuracy && position.accuracy !== 99 && position.accuracy < 20
        ? position.accuracy.toFixed(2) : 20)).join(';'),
    movement: movement || 'driving',
  });

  try {
    const response = await fetch(`/api/osrm/match?${query.toString()}`);
    const points = [];
    if (response.ok) {
      const data = await response.json();
      data.matchings.forEach((match) => {
        points.push(...match.geometry.coordinates.map((position) => ({
          longitude: position[0],
          latitude: position[1],
        })));
      });
    }
    return points;
  } catch {
    dispatch(errorsActions.push('OSRM сервис недоступен'));
    console.warn('OSRM сервис недоступен');
    return [];
  }
};

export const getCoordinatesFromOSRM = async (dispatch, line, movement) => {
  const coordinatesCount = 100;
  const partsCount = Math.floor(line.length / coordinatesCount);
  const coordinates = [];
  for (let i = 0; i < partsCount; i += 1) {
    coordinates.push(
      // eslint-disable-next-line no-await-in-loop
      ...await requestMatchOSRM(line.slice(i * coordinatesCount, (i + 1) * coordinatesCount), movement, dispatch),
    );
  }
  coordinates.push(...await requestMatchOSRM(line.slice(partsCount * coordinatesCount), movement, dispatch));
  return coordinates;
};

const getNumber = (num, defaultNum = 0) => (Number(num) || defaultNum);

export const createRadarFeature = (longitude, latitude, radius, azimuth, diagram, color, theme, category, opacity) => {
  const feature = sector(
    [longitude, latitude],
    getNumber(radius, 1) / 1000,
    getNumber(azimuth) - getNumber(diagram) / 2,
    getNumber(azimuth) + getNumber(diagram) / 2,
    { steps: 35 },
  );
  feature.properties = {
    category: category ?? 'radar',
    radius: getNumber(radius, 1) / 1000,
    b1: getNumber(azimuth) - getNumber(diagram) / 2,
    b2: getNumber(azimuth) + getNumber(diagram) / 2,
    longitude,
    latitude,
    alarm: false,
    timer: 0,
    radarColor: color || theme.palette.tracks.replay0,
    opacity: opacity ?? 0.25,
  };
  return feature;
};
