import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  Grid, Typography,
} from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { formatMsToTime, getStatusColor } from '../../../utils/formatter';
import { parseSettingsFromString } from '../funcs/utils';
import { CustomizeCourseRenderBase } from './sharedCells';

const CustomizeCourseGroupCellRender = ({ dataset }) => (
  <CustomizeCourseRenderBase value={dataset.value} />
);

const ColorsGroupCellRender = ({
  dataset, theme,
}) => (
  <FiberManualRecordIcon style={{ color: dataset.value ?? theme.palette.tracks.replay0 }} />
);

const StatusGroupCellRender = ({
  dataset, deviceListClasses,
}) => {
  const [translatedName, originalName] = parseSettingsFromString(dataset.value);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
      <Brightness1Icon className={`${deviceListClasses[getStatusColor(originalName)]} ${deviceListClasses.brightness}`} />
      <Typography>{translatedName}</Typography>
    </div>
  );
};

const TextIconGroupCellRender = ({
  dataset,
}) => {
  const [text, icon] = parseSettingsFromString(dataset.value);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <img src={`/images/icon/device/${icon ?? 'default'}.svg`} alt="" />
      </Grid>
      <Grid item>
        {text === 'undefined' ? '' : text}
      </Grid>
    </Grid>
  );
};

const IconGroupCellRender = ({
  dataset,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item>
      <img src={`/images/icon/device/${dataset.value || 'default'}.svg`} alt="" />
    </Grid>
  </Grid>
);

const LastUpdateGroupCellRender = ({
  dataset, now, t,
}) => (
  <>
    {formatMsToTime(now.valueOf() - new Date(dataset.value).valueOf(), t)}
  </>
);

export {
  CustomizeCourseGroupCellRender,
  ColorsGroupCellRender,
  StatusGroupCellRender,
  TextIconGroupCellRender,
  IconGroupCellRender,
  LastUpdateGroupCellRender,
};
