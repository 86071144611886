export default [
  '10_s',
  '20_s',
  '30_s',
  '1_m',
  '2_m',
  '5_m',
  '10_m',
  '30_m',
  '1_h',
  '2_h',
  '3_h',
  '6_h',
  '12_h',
  '24_h',
];
