import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'sms',
  initialState: {
    items: [],
    newSmsInitialized: false,
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => !state.items.find((i) => i.id === item.id) && state.items.push(item));
    },
    remove(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clear(state) {
      state.items = [];
    },
    updateNewSmsInitialized(state, action) {
      state.newSmsInitialized = action.payload;
    },
  },
});

export { actions as smsActions };
export { reducer as smsReducer };
