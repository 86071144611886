import { memo, useEffect, useState } from 'react';
import sector from '@turf/sector';
import { getBeforeId } from './funcs/pathFuncs';
import { map } from './Map';

const BSMap = ({
  positions, data, onTower,
}) => {
  const id = data?.name;
  const light = `${id}-light`;
  const [chosenPositions, setChosenPositions] = useState();

  const getTitle = (position) => `${position.lac}-${position.cid}\n${position.network}, ${position.standard}`;

  const createFeature = (position) => {
    const title = getTitle(position);
    return {
      lac: position.lac,
      cid: position.cid,
      latitude: position.latitude,
      longitude: position.longitude,
      azimuth: position.azimuth,
      network: position.network,
      standard: position.standard,
      icon: data.icon,
      count: 0,
      title,
    };
  };

  useEffect(() => {
    const features = [];
    let positionsFiltered;
    switch (onTower) {
      case 1:
        positionsFiltered = positions.filter((position) => position.main);
        break;
      case 2:
        positionsFiltered = positions;
        break;
      default:
        positionsFiltered = [];
        break;
    }

    positionsFiltered.forEach((position) => {
      if (position) {
        let c = false;
        for (let i = 0; i < features.length; i += 1) {
          const { properties } = features[i];
          if (properties.longitude === position.longitude && properties.latitude === position.latitude) {
            features[i].properties.count += 1;
            features[i].properties.title += `\n${getTitle(position)}`;
            c = true;
          }
        }
        if (!c) {
          features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [position.longitude, position.latitude],
            },
            properties: createFeature(position),
          });
        }
        const poligonFeature = sector(
          [position.longitude, position.latitude],
          1.5,
          position.azimuth - position.diagram / 2,
          position.azimuth + position.diagram / 2,
          { steps: 35 },
        );
        poligonFeature.properties = { color: position.main ? '#e37c1b' : '#000' };
        features.push(poligonFeature);
      }
    });

    setChosenPositions({
      type: 'FeatureCollection',
      features,
    });
  }, [positions, onTower]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': '{icon}',
        'icon-allow-overlap': true,
        'icon-size': data.iconSize,
        'text-field': '{title}',
        'text-allow-overlap': false,
        'text-anchor': 'bottom',
        'text-offset': [0, -1],
        'text-font': ['Roboto Medium'],
        'text-size': 12,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 1,
        'text-color': '#000',
      },
    }, getBeforeId(id));
    map.addLayer({
      source: id,
      id: light,
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
      ],
      paint: {
        'fill-color': { type: 'identity', property: 'color' },
        'fill-outline-color': '#705439',
        'fill-opacity': 0.25,
      },
    }, getBeforeId(light));

    return () => {
      Array.from(map.getContainer().getElementsByClassName('maplibregl-popup'))
        .forEach((el) => el.remove());

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(light)) {
        map.removeLayer(light);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    if (chosenPositions) {
      map.getSource(id).setData(chosenPositions);
    }
  }, [chosenPositions]);

  return null;
};

export default memo(BSMap);
