import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, InputAdornment, TextField, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
  addUrl: { width: '50%', backgroundColor: '#b2d1d1' },
  removeTile: { width: '50%', backgroundColor: '#f08080' },
  addTile: { width: '100%', backgroundColor: '#9cd26d' },
}));

const ExtraMapsBlock = ({ item, setItem }) => {
  const t = useTranslation();
  const classes = useStyles();

  const updateExtraMaps = (extraMaps) => setItem({ ...item, attributes: { ...item.attributes, extraMaps } });

  const handleExtraMapsAdd = () => {
    const extraMaps = [...(item.attributes.extraMaps ?? [])];
    const extraMapsIds = extraMaps.map((map) => map.id);
    let newMapId = 1;
    while (true) {
      if (!extraMapsIds.includes(newMapId)) {
        break;
      }
      newMapId++;
    }
    extraMaps.push({
      id: newMapId,
      name: `${t('serverLayer')} ${newMapId}`,
      params: {
        tiles: [
          '',
        ],
        tileSize: 256,
      },
    });
    updateExtraMaps(extraMaps);
  };

  const handleRemoveMap = (index) => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps.splice(index, 1);
    updateExtraMaps(extraMaps);
  };

  const handleTileAdd = (index) => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps[index].params.tiles.push('');
    updateExtraMaps(extraMaps);
  };

  const handleName = (e, index) => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps[index].name = e.target.value;
    updateExtraMaps(extraMaps);
  };

  const handleTile = (e, index, tileIndex) => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps[index].params.tiles[tileIndex] = e.target.value;
    updateExtraMaps(extraMaps);
  };

  const handleRemoveTile = (index, tileIndex) => {
    const extraMaps = JSON.parse(JSON.stringify(item.attributes.extraMaps));
    extraMaps[index].params.tiles.splice(tileIndex, 1);
    updateExtraMaps(extraMaps);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('serverExtraLayers')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {(item.attributes.extraMaps ?? []).map((map, index) => (
          <div key={map.id} style={{ width: '100%' }}>
            <TextField
              key={map.id}
              margin="normal"
              value={map.name}
              onChange={(event) => handleName(event, index)}
              label={t('serverLayerName')}
              variant="filled"
              fullWidth
            />
            {map.params.tiles.map((tile, tileIndex) => (
              <TextField
                margin="normal"
                value={tile}
                onChange={(event) => handleTile(event, index, tileIndex)}
                label="URL"
                variant="filled"
                key={tileIndex}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.adornmentButton}
                        onClick={() => handleRemoveTile(index, tileIndex)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ))}
            <Button variant="outlined" className={classes.addUrl} onClick={() => handleTileAdd(index)}>{t('serverUrlAdd')}</Button>
            <Button variant="outlined" className={classes.removeTile} onClick={() => handleRemoveMap(index)}>{t('serverTileRemove')}</Button>
            <Divider style={{ margin: 20 }} />
          </div>
        ))}
        <Button variant="outlined" className={classes.addTile} onClick={handleExtraMapsAdd}>{t('serverTileAdd')}</Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExtraMapsBlock;
