import React from 'react';
import useComponentsController, { availableComponents } from '../../common/hooks/useComponentsController';
import useStylesForDetectedTable from './common/useStylesForDetectedTable';

/**
 * Настроенный компонент таблицы анти-БПЛА.
 */
const AntiUavsTableView = () => {
  const classesForPerfectTable = useStylesForDetectedTable();
  const getComponent = useComponentsController();

  const settings = {
    classes: classesForPerfectTable,
    small: true,
    isPage: true,
    caption: 'deviceCategoriesAnti_uav',
    disableFilterRowTool: false,
    tableName: 'Anti_uav_page',
  };

  return (
    <>
      {getComponent(availableComponents.AntiUavsTable, settings)}
    </>
  );
};

export default AntiUavsTableView;
