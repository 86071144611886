import { useRef } from 'react';
import {
  COLOR_NOT_FULL_CHECKBOX, EMPTY, FULL, NOT_FULL,
} from '../constants/statusCheckboxChecker';

export default function useStatusCheckboxChecker() {
  const currentStatus = useRef(EMPTY);

  function defineStatus(isFull, isNotFull) {
    if (isFull) {
      currentStatus.current = FULL;
    } else if (isNotFull) {
      currentStatus.current = NOT_FULL;
    } else {
      currentStatus.current = EMPTY;
    }
  }

  function notEmpty() {
    return currentStatus.current !== EMPTY;
  }

  function getStyles() {
    return currentStatus.current === NOT_FULL ? { color: COLOR_NOT_FULL_CHECKBOX } : {};
  }

  return {
    defineStatus, getStyles, notEmpty,
  };
}
