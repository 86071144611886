import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import useTemplatesFieldsPresetForUsersGroupsSettings from '../../common/components/SmartTable/hooks/columnTemplates/settingsTables/useTemplatesFieldsPresetForUsersGroupsSettings';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

const UnionsView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    const response = await fetch('/api/unions?all=true');
    if (response.ok) {
      setItems(await response.json());
    } else if (response.status === 401) {
      if (response.status === 401) {
        logout(history, dispatch);
      }
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  const templateField = useTemplatesFieldsPresetForUsersGroupsSettings();
  const completedColumns = useAutoCompleteColumns(templateField);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="UnionsPage"
      fileExportingName="settingsUnions"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      {completedColumns()}
    </SmartTable>
  );
};

const UnionsPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        disableAdd
        stickyTop
        textOfAddButton={t('unionsAddGroup')}
        content={UnionsView}
        editPath="/settings/union"
        endpoint="unions"
      />
    </OptionsLayout>
  );
};

export default UnionsPage;
