import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme,
} from '@material-ui/core';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const SelectMarkerIcon = ({ icons, item, setItem }) => {
  const t = useTranslation();
  const theme = useTheme();
  const [markerIconOpen, setMarkerIconOpen] = useState(false);

  const chooseIcon = (icon) => {
    setItem({
      ...item,
      icon: icon.icon || 'default',
      iconName: icon.name,
      color: icon.color || theme.palette.markers.black,
    });
    setMarkerIconOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setMarkerIconOpen(true)}
        fullWidth
        variant="contained"
        required
        startIcon={(
          <Avatar style={{ background: '#fff0' }}>
            <img src={`/images/icon/device/${item.icon || 'default'}.svg`} alt="" />
          </Avatar>
        )}
      >
        {t('markerIcon')}
      </Button>

      <Dialog
        maxWidth="lg"
        open={markerIconOpen}
        onClose={() => setMarkerIconOpen(false)}
      >
        <DialogTitle>{t('markerIcon')}</DialogTitle>
        <DialogContent>
          <Grid container columns={2} direction="column">
            {icons.map((ic) => (
              <Grid
                container
                item
                key={ic.name}
                alignItems="center"
                spacing={2}
                onClick={() => chooseIcon(ic)}
                style={{ cursor: 'pointer' }}
              >
                <Grid item>
                  <Avatar
                    style={{ background: item.iconName === ic.name && item.icon === ic.icon ? '#198cffa4' : '#fff' }}
                  >
                    <img src={`/images/icon/device/${ic.icon}.svg`} alt="" />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography>
                    {ic.name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setMarkerIconOpen(false)}>{t('closeTitle')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectMarkerIcon;

SelectMarkerIcon.propTypes = {
  icons: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  setItem: PropTypes.func.isRequired,
};
