import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, 
  FormControl, InputLabel, MenuItem, Select, TextField, Typography, makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const GeocodeBlock = ({
  item, setItem,
}) => {
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">
          {t('sharedGeocode')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel>{t('mapGeoсodeProvider')}</InputLabel>
          <Select
            value={item.attributes.mapGeocodeProvider || 'off'}
            onChange={(e) => setItem({ ...item, attributes: { ...item.attributes, mapGeocodeProvider: e.target.value } })}
          >
            <MenuItem value="off">{t('mapGeocodeProviderOff')}</MenuItem>
            <MenuItem value="yandex">{t('mapGeocodeProviderYandex')}</MenuItem>
            <MenuItem value="nominatim">{t('mapGeocodeProviderNominatim')}</MenuItem>
          </Select>
        </FormControl>
        {(item.attributes.mapGeocodeProvider === 'nominatim') ? (
          <TextField
            label={t('mapGeoсodeProviderUrl')}
            margin="normal"
            value={item.attributes.mapGeoсodeProviderUrl || ''}
            onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, mapGeoсodeProviderUrl: event.target.value } })}
            variant="filled"
          />
        ) : (<></>)}
        {(item.attributes.mapGeocodeProvider === 'yandex') ? (
          <TextField
            label={t('mapGeocodeProviderApiKey')}
            margin="normal"
            value={item.attributes.mapGeocodeProviderApiKey || ''}
            onChange={(event) => setItem({ ...item, attributes: { ...item.attributes, mapGeocodeProviderApiKey: event.target.value } })}
            variant="filled"
          />
        ) : (<></>)}
      </AccordionDetails>
    </Accordion>
  );
};

export default GeocodeBlock;
