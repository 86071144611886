import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import { sessionActions } from '../../../store/session';
import { useCatch, useEffectAsync } from '../../../common/utils/reactHelper';
import OptionsLayout from '../OptionsLayout';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import logout from '../../../common/utils/logout';
import { savePermissions } from '../../../common/utils/savePermisionsFuncs';
import validateItem from '../../../common/components/PositionData/validateItem';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const EditItemView = ({
  children, endpoint, item, setItem, permissions, disabledSave, confirmPassword, setConfirmationTrouble,
  permissionsReverse,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  const { id } = useParams();

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/${endpoint}/${id}`);
      if (response.ok) {
        setItem(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    } else {
      setItem({});
    }
  }, [id]);

  const returnBackOrCloseWindow = () => {
    // Срабатывает только для вкладок открытые скриптом.
    try {
      if (new URLSearchParams(window.location.search)?.has('isNewTab')) {
        window.close();
      }
    } catch (error) {
      console.warn('Ошибка при проверки строки поиска.', error);
    }
    history.goBack();
  };

  const handleSave = useCatch(async () => {
    setProgress(1);
    if (endpoint === 'users' && item?.password !== confirmPassword) {
      setConfirmationTrouble(true);
    } else {
      let url = `/api/${endpoint}`;
      if (id) {
        url += `/${id}`;
      }

      const response = await fetch(url, {
        method: !id ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(validateItem(item)),
      });

      if (response.ok) {
        if (permissions) {
          if (!id) {
            const data = await response.json();
            Object.keys(permissions).forEach((type) => {
              permissions[type].baseId = data.id;
            });
          }
          await Object.values(permissions).forEach((permission) => savePermissions(permission, permissionsReverse));
        }
        if (endpoint === 'users' && JSON.parse(localStorage.getItem('loginEmail')) === item.login) {
          item.latitude = Number(item.latitude);
          item.longitude = Number(item.longitude);
          item.zoom = Number(item.zoom);
          dispatch(sessionActions.updateUser(item));
        }
        returnBackOrCloseWindow();
      } else {
        if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);

        // Нужно генерировать конкретное сообщение об ошибке на бэкенде!?
        const msg = await response.text();
        if (/Duplicate entry.*for key 'st_devices.uniqueid'.*/.test(msg)) {
          throw Error(t('duplicateDeviceErrorMsg'));
        }
        throw Error(msg);
      }
    }
    setProgress(0);
  });

  return (
    <OptionsLayout>
      <Container maxWidth="xs" className={classes.container}>
        {children}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button color="primary" variant="outlined" onClick={returnBackOrCloseWindow} disabled={!!progress}>
              {t('sharedCancel')}
            </Button>
            <Button
              color="primary"
              variant={progress ? 'outlined' : 'contained'}
              onClick={handleSave}
              disabled={!!(disabledSave || progress)}
            >
              {progress ? <CircularProgress /> : t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default EditItemView;
