import { useEffect } from 'react';

import { map } from './Map';

const MapCamera = ({
  latitude, longitude,
}) => {
  useEffect(() => {
    map.easeTo({
      center: [longitude, latitude],
    });
  }, [latitude, longitude]);

  return null;
};

export default MapCamera;
