import React, { lazy, Suspense } from 'react';

export const availableComponents = Object.freeze({
  SelectCategory: 'SelectCategory',

  DetectedObjectsPage: 'DetectedObjectsPage',
  RadarsPage: 'RadarsPage',
  AntiUavsPage: 'AntiUavsPage',

  DetectedObjectsTable: 'DetectedObjectsTable',
  RadarsTable: 'RadarsTable',
  AntiUavsTable: 'AntiUavsTable',

  HeaderText: 'HeaderText',
  FrameAutoCarousel: 'FrameAutoCarousel',
});

const createLazyComponent = (path) => lazy(() => import(`../components/ControllerComponents/${path}`));

const lazyComponents = Object.keys(availableComponents).reduce((components, componentName) => {
  components[componentName] = createLazyComponent(availableComponents[componentName]);
  return components;
}, {});

export default function useComponentsController() {
  const getDefaultComponent = () => <></>;

  const getComponent = (componentType, settings) => {
    const LazyComponent = lazyComponents[componentType];

    return LazyComponent ? (
      <Suspense fallback={<></>}>
        <LazyComponent {...settings} />
      </Suspense>
    ) : getDefaultComponent();
  };

  return getComponent;
}
