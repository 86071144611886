import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionDetails, Checkbox, FormControlLabel, makeStyles, Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import logout from '../../../common/utils/logout';
import SelectField from '../../../common/form/SelectField';
import { prefixString } from '../../../common/utils/stringUtils';
import LinkField from '../../../common/form/LinkField';
import { getIsAdmin } from '../../../common/utils/selectors';
import { savePermissions } from '../../../common/utils/savePermisionsFuncs';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: theme.spacing(1),
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('xs')]: {
      top: theme.spacing(2),
    },
  },
  title: {
    paddingTop: 14,
    paddingLeft: 20,
  },
  details: {
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  checkbox: {
    marginTop: 12,
  },
}));

const GeofenceTablet = ({ setSaveMode, id, refreshGeofences }) => {
  const classes = useStyles();
  const t = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);

  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/geofences/${id}`);
      if (response.ok) {
        setItem(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    } else {
      setItem({});
    }
  }, [id]);

  const handleSave = async () => {
    let url = '/api/geofences';
    if (id) {
      url += `/${id}`;
    }

    const response = await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      if (permissions) {
        await Object.values(permissions).forEach((permission) => savePermissions(permission, true));
      }
      setSaveMode(false);
      refreshGeofences();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: item.id,
          keyBase: 'geofenceId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Accordion defaultExpanded>
        <Typography align="left" className={classes.title}>
          {t('sharedGeofence')}
        </Typography>
        {item && (
          <>
            <AccordionDetails className={classes.details}>
              <TextField
                required
                margin="normal"
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
                variant="filled"
              />
              <SelectField
                margin="normal"
                value={item.type || ''}
                emptyValue={null}
                onChange={(e) => setItem({ ...item, type: e.target.value })}
                endpoint="/api/geofences/types"
                keyGetter={(it) => it.type}
                titleGetter={(it) => t(prefixString('geofence', it?.type || it))}
                label={t('sharedType')}
                variant="filled"
                fullWidth
              />
              <FormControlLabel
                className={classes.checkbox}
                control={(
                  <Checkbox
                    checked={item.always}
                    onChange={(event) => setItem({ ...item, always: event.target.checked })}
                  />
                )}
                label={t('geofenceAlways')}
              />

              {permissions && (
                <LinkField
                  margin="normal"
                  endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${userId}`}`}
                  endpointLinked={`/api/unions?geofenceId=${item.id}`}
                  label={t('settingsUnions')}
                  variant="filled"
                  permissions={permissions}
                  setPermissions={setPermissions}
                  type="unions"
                  fullWidth
                  newItem={!item.id}
                />
              )}
            </AccordionDetails>
            <FormControl fullWidth margin="none" style={{ marginBottom: 12 }}>
              <div className={classes.buttons}>
                <Button type="button" color="primary" variant="outlined" onClick={() => setSaveMode(false)}>
                  {t('sharedCancel')}
                </Button>
                <Button type="button" color="primary" variant="contained" onClick={handleSave} disabled={!item?.name}>
                  {t('sharedSave')}
                </Button>
              </div>
            </FormControl>
          </>
        )}
      </Accordion>
    </Container>
  );
};

export default GeofenceTablet;
