import { createSlice } from '@reduxjs/toolkit';

const valueDefault = {
  isShow: false,
  header: undefined,
  body: undefined,
};

const { reducer, actions } = createSlice({
  name: 'universal panel',
  initialState: { ...valueDefault },
  reducers: {
    createAndShow(state, action) {
      state = { ...state, ...action.payload };
      state.isShow = true;
      return state;
    },
    create(state, action) {
      state = { ...state, ...action.payload };
      return state;
    },
    changeVisibility(state, action) {
      state.isShow = action.payload ?? !state.isShow;
    },
    reset(state) {
      state = { ...valueDefault };
      return state;
    },
    getVisibility(state) {
      return state.isShow;
    },
  },
});

export { actions as universalPanelActions };
export { reducer as universalPanelReducer };
