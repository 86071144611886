import React from 'react';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  ColumnChooser, ColumnFixing, FilterPanel, FilterRow,
  GroupPanel, Grouping, HeaderFilter, Pager, Paging, Position, Selection, Sorting,
} from 'devextreme-react/data-grid';

const SimpleDataGrid = ({
  columns, dataSource,
  allowedPageSizes = [10, 20, 50, 100, 150, 250, 500], defaultPageSize = 50,
  columnResizingMode = 'widget',
  style,
}) => (
  <DataGrid
    style={{
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      ...style,
    }}
    columns={columns}
    dataSource={dataSource}
    showBorders
    columnResizingMode={columnResizingMode}
    hoverStateEnabled
    allowColumnReordering
    allowColumnResizing
    columnAutoWidth
  >
    <ColumnChooser enabled mode="select" allowSearch height={400} width={300}>
      <Position
        my="right top"
        at="right top"
        of=".dx-datagrid-column-chooser-button"
      />
    </ColumnChooser>
    <LoadPanel enabled />
    <ColumnFixing enabled />
    <Paging enabled defaultPageSize={defaultPageSize} />
    <Pager
      visible
      showPageSizeSelector
      showInfo
      showNavigationButtons
      allowedPageSizes={allowedPageSizes}
    />
    <FilterRow visible />
    <FilterPanel visible />
    <HeaderFilter allowSearch visible />
    <Selection mode="multiple" showCheckBoxesMode="always" />
    <Grouping contextMenuEnabled autoExpandAll={false} expandMode="rowClick" />
    <GroupPanel visible />
    <Sorting mode="multiple" />
  </DataGrid>
);

export default SimpleDataGrid;
