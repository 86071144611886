import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FileManager } from 'devextreme-react';
import {
  Column, Details, FileSelectionItem, Item, ItemView, Permissions, Toolbar,
} from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import {
  LinearProgress, Typography,
  useMediaQuery, useTheme,
} from '@material-ui/core';
import { useTranslation } from './LocalizationProvider';
import useDebounce from '../hooks/useDebounce';
import { getIsAdmin } from '../utils/selectors';
import { isUndefinedOrNull } from '../utils/stringUtils';
import { gigabytesToBytes } from '../utils/converter';
import FreeModalWindow from './FreeModalWindow';
import { downloadFileByUrl } from '../utils/fileHelper';
import { filePreviewLayoutActions } from '../../store';
import { useEffectAsync } from '../utils/reactHelper';

const defaultIcon = 'images/icon/files/simple/file.png';
const pathsToIcons = {
  'images/icon/files/simple/': ['acc', 'ai', 'arj', 'avi', 'css', 'csv', 'dbf', 'doc',
    'dwg', 'exe', 'fla', 'flac', 'gif', 'html', 'iso', 'jpg', 'js', 'json', 'mdf', 'mp2', 'mp3', 'mp4',
    'mxf', 'nrg', 'pdf', 'png', 'ppt', 'psd', 'rar', 'tiff', 'rtf', 'svg', 'txt', 'wav', 'wma',
    'xls', 'xml', 'zip', '7z', 'file'],
  'images/icon/files/bright/': ['xlsx'],
};
const icons = {};

for (const [path, exts] of Object.entries(pathsToIcons)) {
  for (const ext of exts) {
    icons[ext] = `${path}${ext}.png`;
  }
}

const getIcon = (fileExtension) => {
  const iconForFile = icons[fileExtension];

  if (isUndefinedOrNull(iconForFile)) {
    return defaultIcon;
  }
  return iconForFile;
};

const fileSizeNumber = 1;
const maxFileAndChunkSize = gigabytesToBytes(fileSizeNumber);
const sizeSettingsForUploadedFiles = {
  maxFileSize: maxFileAndChunkSize,
  chunkSize: maxFileAndChunkSize,
};

const availableFileListMode = Object.freeze({
  Thumbnails: 'thumbnails',
  Details: 'details',
});

const SmartFileManager = ({
  deviceId,
  disableWindowMode = false, disableColumnFixedSize = false,
  onCloseFilesWindow,
}) => {
  const t = useTranslation();
  const delay = useDebounce(550);
  const theme = useTheme();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isAdmin = useSelector(getIsAdmin);

  const fileManagerRef = useRef();
  const filesStorage = useRef([]);

  const [sendingFilesCount, setSendingFilesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [fileListMode, setFileListMode] = useState(availableFileListMode.Details);

  const iconForFileListModeButton = fileListMode === availableFileListMode.Details ? 'mediumiconslayout' : 'bulletlist';

  const switchFileListView = () => {
    const mode = fileListMode === availableFileListMode.Details ? availableFileListMode.Thumbnails : availableFileListMode.Details;
    setFileListMode(mode);
    const settings = {
      fileListMode: mode,
    };
    localStorage.setItem('smartFileManagerSettings', JSON.stringify(settings));
  };

  useEffect(() => {
    try {
      const settings = JSON.parse(localStorage.getItem('smartFileManagerSettings'));
      if (isUndefinedOrNull(settings?.fileListMode)) {
        return;
      }
      setFileListMode(settings.fileListMode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const displayFileToPreviewWindow = useCallback((event) => {
    try {
      const imageName = event.file?.name ?? '';
      // eslint-disable-next-line no-restricted-globals
      const fullUrl = `${location.origin}/api/media/${deviceId}/${imageName}`;

      const newWindow = {};
      newWindow[fullUrl] = { name: imageName, url: fullUrl };
      dispatch(filePreviewLayoutActions.add(newWindow));
    } catch (error) {
      console.log(error);
    }
  }, [deviceId]);

  const getFiles = useCallback(async () => {
    const result = [];

    try {
      const files = await (await fetch(`/api/devices/${deviceId}/files`)).json();
      for (const file of files) {
        result.push({
          name: file.name,
          size: file.size,
          loadDate: new Date(file.lastModified).toLocaleString(),
        });
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }, [deviceId]);

  const refreshFileStorage = useCallback(async () => {
    try {
      filesStorage.current = await getFiles();
    } catch (error) {
      console.error(error);
    }
  }, [deviceId]);

  const removeProgressPanel = useCallback(() => {
    const selectorsForRemoving = [
      '.dx-widget.dx-filemanager-progress-panel',
      '.dx-filemanager-notification-drawer.dx-drawer.dx-widget .dx-drawer-shader',
      '.dx-filemanager-notification-drawer.dx-drawer.dx-widget .dx-overlay-wrapper',
      '.dx-filemanager-notification-drawer .dx-drawer-panel-content',
    ];
    for (const selector of selectorsForRemoving) {
      const panelComponent = fileManagerRef.current.instance.element().querySelector(selector);
      if (isUndefinedOrNull(panelComponent)) {
        continue;
      }
      panelComponent.remove();
    }
  }, []);

  const refreshFileManager = useCallback(async () => {
    if (isUndefinedOrNull(deviceId)) return;
    if (isUndefinedOrNull(fileManagerRef.current)) return;

    try {
      await refreshFileStorage();
      fileManagerRef.current.instance.refresh();
      setSendingFilesCount(() => 0);

      removeProgressPanel();
    } catch (error) {
      console.error(error);
    }
  }, [deviceId]);

  const customFileProvider = useMemo(() => new CustomFileSystemProvider({
    getItems: () => filesStorage.current,
    uploadFileChunk: async (file, uploadInfo) => {
      setSendingFilesCount((prev) => prev += 1);

      try {
        await fetch(`/api/devices/${deviceId}/files/${file.name}`, {
          method: 'POST',
          body: new Blob([uploadInfo.chunkBlob], { type: file.type }),
        });
      } catch (error) {
        console.error(error);
      }

      delay(refreshFileManager);
    },
    downloadItems: (files) => {
      for (const file of files) {
        downloadFileByUrl(
          `/api/media/${deviceId}/${file.name}`,
          file.name,
        ).catch((e) => console.log(e));
      }
    },
    deleteItem: async (file) => {
      try {
        await fetch(`/api/devices/${deviceId}/files/${file.name}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        });
      } catch (error) {
        console.error(error);
      }

      delay(refreshFileManager);
    },
  }), [deviceId]);

  useEffectAsync(async () => {
    try {
      setIsLoading(true);
      await refreshFileStorage();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [deviceId]);

  const removeUITools = (e) => {
    try {
      // Боковая панель навигации по папкам
      e.element.querySelector('div.dx-drawer-panel-content.dx-drawer-panel-content-initial').remove();
      // Панель навигации по папкам сверху
      e.element.querySelector('div.dx-widget.dx-filemanager-breadcrumbs').remove();

      // Боковая панель уведомлений
      // e.element.querySelector('div.dx-drawer-panel-content').remove();
    } catch (error) {
      console.error(error);
    }
  };

  const customizeIcon = useCallback((fileSystemItem) => {
    const fileExtension = fileSystemItem.getFileExtension().replace('.', '');
    return getIcon(fileExtension);
  }, []);

  const openFilesToPreviewWindows = useCallback(() => {
    const files = fileManagerRef.current.instance.getSelectedItems().map((f) => ({ file: { name: f.dataItem.name } }));
    for (const file of files) {
      displayFileToPreviewWindow(file);
    }
  }, []);

  const filesSentComponent = (
    <Typography
      style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}
      variant="caption"
      noWrap
    >
      <div>
        {sendingFilesCount > 0 && (
          <>{`${t('globalSendingFiles')}: ${sendingFilesCount} ${t('sharedNumbers')}.`}</>
        )}
      </div>
      <div>
        {`${t('globalMaxFileSize')} ${fileSizeNumber}${t('globalGigabytes')}`}
      </div>
    </Typography>
  );

  const fileManagerComponent = useMemo(() => (
    <FileManager
      ref={fileManagerRef}
      fileSystemProvider={customFileProvider}
      height="calc(100% - 20px)"
      rootFolderName={t('globalAttachments')}
      upload={sizeSettingsForUploadedFiles}
      customizeThumbnail={customizeIcon}
      onContentReady={removeUITools}
      onSelectedFileOpened={displayFileToPreviewWindow}
    >
      <Permissions delete={isAdmin} upload={isAdmin} download />
      <Toolbar>
        <FileSelectionItem text="" name="download" />
        <FileSelectionItem name="separator" />
        <FileSelectionItem text="" name="delete" />
        <FileSelectionItem name="separator" />
        <FileSelectionItem>
          <Button
            icon="video"
            hint={t('globalOpenContents')}
            stylingMode="text"
            onClick={openFilesToPreviewWindows}
          />
        </FileSelectionItem>
        <FileSelectionItem name="clearSelection" />

        <Item name="delete" />
        <Item name="upload" />
        <Item name="download" />
        <Item location="after">
          <Button
            icon={iconForFileListModeButton}
            stylingMode="text"
            onClick={switchFileListView}
          />
        </Item>
      </Toolbar>
      <ItemView mode={fileListMode}>
        <Details>
          {disableColumnFixedSize ? (
            <>
              <Column dataField="thumbnail" />
              <Column dataField="name" />
              <Column dataField="loadDate" sortOrder="desc" caption={t('globalUploadDate')} />
              <Column dataField="size" alignment="left" />
            </>
          ) : (
            <>
              <Column dataField="thumbnail" width={36} />
              <Column dataField="name" width={isMobile ? '50%' : 250} />
              {isMobile ? (
                <Column dataField="loadDate" width={145} sortOrder="desc" caption={t('globalUploadDate')} />
              ) : (
                <Column dataField="loadDate" sortOrder="desc" caption={t('globalUploadDate')} />
              )}
              <Column dataField="size" alignment="left" />
            </>
          )}
        </Details>
      </ItemView>
    </FileManager>
  ), [isMobile, deviceId, fileListMode]);

  if (isLoading) {
    return (
      <LinearProgress />
    );
  }

  return (
    <>
      {disableWindowMode ? (
        <>
          {filesSentComponent}
          {fileManagerComponent}
        </>
      ) : (
        <FreeModalWindow enableResizable onCloseModalWindow={onCloseFilesWindow}>
          {filesSentComponent}
          {fileManagerComponent}
        </FreeModalWindow>
      )}
    </>
  );
};

export default SmartFileManager;
