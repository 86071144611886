import {
  Checkbox,
  FormControl, InputLabel, ListItemText, MenuItem, Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffectAsync } from '../utils/reactHelper';
import logout from '../utils/logout';

const SelectField = ({
  margin,
  variant,
  label,
  multiple,
  value,
  emptyValue = 0,
  onChange,
  endpoint,
  data,
  fullWidth,
  required,
  disabled,
  error,
  keyGetter = (item) => item.id,
  titleGetter = (item, items) => item?.name || items.find((it) => it.id === item)?.name,
}) => {
  const [items, setItems] = useState(data);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  useEffectAsync(async () => {
    if (endpoint) {
      const response = await fetch(endpoint);
      if (response.ok) {
        setItems(await response.json());
      } else if (response.status === 401) {
        logout(history, dispatch);
      }
    }
  }, []);

  if (items) {
    return (
      <FormControl margin={margin} variant={variant} fullWidth={fullWidth} required={required} disabled={!!disabled} error={error}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          value={value}
          onChange={onChange}
          renderValue={(selected) => (
            multiple ? selected.map((val) => titleGetter(val)).join(', ') : titleGetter(selected, items))}
          MenuProps={theme.overrides.MenuProps}
        >
          {!!(!multiple && emptyValue !== null)
            && <MenuItem value={emptyValue}>&nbsp;</MenuItem>}
          {multiple ? (
            items.map((item) => (
              <MenuItem
                value={keyGetter(item)}
                key={keyGetter(item)}
              >
                <Checkbox checked={value.includes(keyGetter(item))} />
                <ListItemText primary={titleGetter(item, items)} />
              </MenuItem>
            ))
          ) : (
            items.map((item) => (
              <MenuItem key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item, items)}</MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    );
  }
  return null;
};

export default SelectField;
