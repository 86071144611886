import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../common/components/LocalizationProvider';

const MarkerCategoryRows = ({ categoryParams, classes }) => {
  const t = useTranslation();

  if (categoryParams?.category === 'radar') {
    return (
      <>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography className={classes.fontSize}>
              {t('markerAzimuth')}
            </Typography>
          </Grid>
          <Grid item xs style={{ textAlign: 'right' }}>
            <Typography className={classes.fontSize}>
              {categoryParams.azimuth}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography className={classes.fontSize}>
              {t('markerDiagram')}
            </Typography>
          </Grid>
          <Grid item xs style={{ textAlign: 'right' }}>
            <Typography className={classes.fontSize}>
              {categoryParams.diagram}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Typography className={classes.fontSize}>
              {t('markerRadius')}
            </Typography>
          </Grid>
          <Grid item xs style={{ textAlign: 'right' }}>
            <Typography className={classes.fontSize}>
              {categoryParams.radius}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
  return null;
};

export default MarkerCategoryRows;

MarkerCategoryRows.propTypes = {
  categoryParams: PropTypes.object,
  classes: PropTypes.object.isRequired,
};
