import { useRef } from 'react';

/**
 * Отклыдвает вызов функции, пока с последнего вызова обработчика события пройдёт определённое количество времен.
 */
export default function useDebounce(time = 250) {
  const timerDebounceRef = useRef();

  /** Отклыдвает вызов функции, пока с последнего вызова обработчика события пройдёт определённое количество времен. */
  function handler(fn) {
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }

    timerDebounceRef.current = setTimeout(() => {
      try {
        fn();
      } catch (error) {
        console.warn('Ошибка при вызове функции в хуке useDebounce.', error);
      }
    }, time);
  }

  return (
    handler
  );
}
