import React from 'react';
import { useSelector } from 'react-redux';
import PointsMap from './PointsMap';

const ExtraLayers = () => {
  const flightRadarFlights = useSelector((state) => state.extraLayers.flightRadarFlights);
  const flightRadarAirports = useSelector((state) => state.extraLayers.flightRadarAirports);

  return (
    <>
      <PointsMap points={flightRadarFlights} name="flightRadarFlights" />
      <PointsMap points={flightRadarAirports} name="flightRadarAirports" />
    </>
  );
};

export default ExtraLayers;
