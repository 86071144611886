import React, { useState } from 'react';
import {
  Paper, Button, ButtonGroup, Typography, makeStyles,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStylesDeviceList, useStylesMainPanel } from '../../MainPage/MainPage.styles';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import VirtualHeaderRow from './VirtualHeaderRow';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  container: {
    margin: '8px 0',
    gap: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const DeviceReorderingMode = ({
  categories, onAccept, onCancel,
}) => {
  const t = useTranslation();
  const classes = useStylesDeviceList();
  const innerClasses = useStyles();
  const mainClasses = useStylesMainPanel();
  const [innerCategories, setInnerCategories] = useState([...categories]);

  const handleAccept = () => {
    try {
      for (let i = 0; i < innerCategories.length; i++) {
        innerCategories[i] = { ...innerCategories[i], order: i };
      }
    } catch (error) {
      console.warn('Ошибка при изменении порядка заголовков устройств.', error);
    }
    onAccept([...innerCategories]);
  };

  const handleCancel = () => {
    onCancel();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDrag = (result) => {
    try {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;

      const quotes = reorder(
        innerCategories,
        result.source.index,
        result.destination.index,
      );

      setInnerCategories([...quotes]);
    } catch (error) {
      console.warn('Ошибка при обработке перетаскивании объекта.', error);
    }
  };

  return (
    <div className={mainClasses.sidebar}>
      <Paper className={innerClasses.root}>
        <div className={innerClasses.container}>
          <Typography variant="body1">{t('lazyDevicesListDeviceReorderingMode')}</Typography>
          <ButtonGroup disableElevation size="small" variant="contained" color="default">
            <Button onClick={handleCancel}>{t('sharedCancel')}</Button>
            <Button onClick={handleAccept}>{t('sharedSave')}</Button>
          </ButtonGroup>
        </div>

        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="droppable1">
            {(provider) => (
              <div
                ref={provider.innerRef}
                className={classes.list}
                {...provider.droppableProps}
              >
                {innerCategories.map((dh, i) => (
                  <Draggable index={i} key={dh.order} draggableId={dh.title}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <VirtualHeaderRow
                          style={{
                            border: '1px dashed black',
                            marginBottom: 3,
                          }}
                          deviceHeader={dh}
                          classes={classes}
                          expandHandler={() => { }}
                          turnOnDevices={() => { }}
                          onMouseDown={() => { }}
                          onMouseUp={() => { }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>
    </div>
  );
};

export default DeviceReorderingMode;
