import React, { useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffectAsync } from '../../common/utils/reactHelper';
import EditCollectionView from '../../EditCollectionView';
import OptionsLayout from './OptionsLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import logout from '../../common/utils/logout';
import { markersActions } from '../../store';
import { getIsAdmin } from '../../common/utils/selectors';
import SmartTable from '../../common/components/SmartTable';
import { allowedPageSizes, currentPageSize } from '../../common/settings/settingsPage';
import useTemplatesFieldsPresetForIconsSettings from '../../common/components/SmartTable/hooks/columnTemplates/settingsTables/useTemplatesFieldsPresetForIconsSettings';
import useAutoCompleteColumns from '../../common/components/SmartTable/hooks/useAutoCompleteColumns';

const MarkersView = ({ updateTimestamp, onMenuClick, onAddNewRow }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);

  const [items, setItems] = useState([]);
  const [requestProgress, setRequestProgress] = useState(true);

  useEffectAsync(async () => {
    let response;

    if (isAdmin) {
      response = await fetch('/api/markers?all=true');
    } else {
      response = await fetch(`/api/markers?userId=${userId}`);
    }

    if (response.ok) {
      const data = await response.json();
      setItems(data);
      dispatch(markersActions.refresh(data));
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
    setRequestProgress(false);
  }, [updateTimestamp]);

  const templateField = useTemplatesFieldsPresetForIconsSettings();
  const completedColumns = useAutoCompleteColumns(templateField);

  if (requestProgress) {
    return (
      <LinearProgress style={{ top: '100px' }} />
    );
  }

  return (
    <SmartTable
      tableName="MarkersPage"
      fileExportingName="settingsMarkers"
      allowedPageSizes={allowedPageSizes}
      currentPageSize={currentPageSize}
      arrayDependencies={[items]}
      dataSource={items}
      onAddNewRow={onAddNewRow}
      onMenuClick={onMenuClick}
    >
      {completedColumns()}
    </SmartTable>
  );
};

const MarkersPage = () => {
  const t = useTranslation();

  return (
    <OptionsLayout>
      <EditCollectionView
        hideButtonAdd
        stickyTop
        textOfAddButton={t('markerAddMarker')}
        content={MarkersView}
        editPath="/settings/marker"
        endpoint="markers"
        forseAdd
      />
    </OptionsLayout>
  );
};

export default MarkersPage;
