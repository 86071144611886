import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Avatar, Container, Slider,
  Tooltip, Typography, useTheme,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReportSmartFilter from '../../Reports/components/ReportSmartFilter';
import requestRoute from '../../../common/utils/requestRoute';
import CircularProgressWithLabel from '../../../common/components/CircularProgressWithLabel';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getIconSrc } from '../../../common/utils/selectors';
import { useMarkStyles, useStylesControlPanel, useTooltipStyles } from '../ReplayPage/ReplayPage.styles';
import TimeLineSlider from '../ReplayPage/TimeLineSlider/TimeLineSlider';

const TimeLabelNew = ({
  children, open, value, index,
}) => {
  const classes = useTooltipStyles();
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement={index === 0 ? 'top-end' : 'top-start'}
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
};

const MarkLabel = ({ device, position }) => {
  const classes = useMarkStyles();
  const theme = useTheme();

  return (
    <Tooltip title={`${device.name} - ${new Date(position.fixTime).toLocaleString()}`}>
      <Avatar
        className={classes.mark}
        style={{ backgroundColor: device.attributes.color || theme.palette.tracks.replay0 }}
      >
        <img src={getIconSrc(device)} alt="" className={classes.icon} />
      </Avatar>
    </Tooltip>
  );
};

const ControlPanel = ({
  to, setTo, from, setFrom, progress, setProgress, compactMode, indexes, setIndexes,
  positions, setPositions, period, setPeriod, panel, setNothing, needMoveMap, setPositionsPath, setPositionsStop,
  setPositionsSimilar, currentPositions, devicesObject, devicesList, progressDevices, countProgress, setDevicesObject,
}) => {
  const t = useTranslation();
  const classes = useStylesControlPanel();
  const [expanded, setExpanded] = useState(true);
  const [minDistance, setMinDistance] = useState(1000);
  const [marks, setMarks] = useState([]);

  const handleChangeTwo = (event, newValue) => {
    /**
     * Изменяет ширину временной шкалы видимости устройств
     */
    if (!Array.isArray(newValue)) {
      return;
    }
    if (period[0] !== newValue[0]) {
      if (newValue[0] + minDistance <= to.valueOf()) {
        newValue[1] = newValue[0] + minDistance;
        setPeriod(newValue);
      }
    } else if (newValue[1] - minDistance >= from.valueOf()) {
      newValue[0] = newValue[1] - minDistance;
      setPeriod(newValue);
    }
  };

  const handleSubmit = async (deviceIds, from, to, headers) => {
    /**
     * Обнуляет все данные, запрашивает и устанавливает новые.
     */
    setProgress(1);
    setPositions({});
    setPositionsPath({});
    setPositionsStop({});
    setPositionsSimilar({});
    setIndexes(null);
    const states = {
      setPositions,
      setPositionsPath,
      setPositionsStop,
      setPositionsSimilar,
      indexes,
      setIndexes,
    };

    const query = new URLSearchParams({});
    query.append('object', true);
    deviceIds.forEach((deviceId) => query.append('id', deviceId));
    const response = await fetch(`/api/devices?${query.toString()}`);
    let result = {};
    if (response.ok) {
      result = await response.json();
      setDevicesObject(result);
    }

    const res = await requestRoute(deviceIds.map((id) => result[id]), from, to, headers, states, panel.onBs, false,
      setProgress);
    setProgress(100);
    setProgress(0);
    if (res) {
      setExpanded(false);
    } else {
      setNothing(true);
    }
  };

  useEffect(() => {
    /**
     * Изменение дефолтных состояний точек на временной школе при изменении перода from - to
     */
    setMinDistance(to.valueOf() - from.valueOf());
    setPeriod([from.valueOf(), to.valueOf()]);
  }, [from, to]);

  useEffect(() => {
    /**
     * Обновляет значения маркеров позиций устройств на временной шкале при изменении текущих позиций currentPositions
     */
    if (currentPositions) {
      setMarks(Object.values(currentPositions)
        .map((p) => ({
          value: new Date(p[0]?.fixTime).valueOf(),
          label: p[0] && <MarkLabel device={devicesObject[p[0].deviceId]} position={p[0]} />,
        })));
    }
  }, [currentPositions]);

  return (
    <Container
      maxWidth={!expanded ? false : 'sm'}
      className={`${classes.controlPanel} ${needMoveMap ? classes.controlPanelShort : classes.controlPanelWide}`}
    >
      {!!(!expanded && Object.keys(positions).length) && (
        compactMode ? (
          <div className={classes.sliderOneItem}>
            <Slider
              getAriaLabel={() => 'Custom marks'}
              value={period}
              step={20000}
              valueLabelFormat={(v) => new Date(v).toLocaleString()}
              onChange={handleChangeTwo}
              valueLabelDisplay="auto"
              min={from.valueOf()}
              max={to.valueOf()}
              marks={marks}
              ValueLabelComponent={TimeLabelNew}
            />
          </div>
        ) : (
          <div className={classes.sliderBlock}>
            {!!(indexes && devicesObject) && (
              <div className={classes.sliderOneItem}>
                <TimeLineSlider from={from} to={to} positions={positions} indexes={indexes} setIndexes={setIndexes} />
              </div>
            )}
          </div>
        )
      )}
      <div className={classes.sliderButtons}>
        <Accordion
          expanded={expanded}
          onChange={() => !progress && setExpanded(!expanded)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={progress ? <CircularProgressWithLabel value={progress} rotate /> : <ExpandLessIcon />}
            className={classes.accordionBar}
          >
            <Typography align="left" noWrap>
              {t('reportReplay')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.configForm}>
            <ReportSmartFilter
              handleSubmit={handleSubmit}
              showOnly
              progress={progress}
              onBs={panel.onBs}
              setF={setFrom}
              setT={setTo}
              devicesList={devicesList}
              progressDevices={progressDevices}
              countProgress={countProgress}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

export default ControlPanel;
