import React, { useEffect, useState } from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import EditItemView from './components/EditItemView';
import LinkField from '../../common/form/LinkField';
import SelectField from '../../common/form/SelectField';
import { useEffectAsync } from '../../common/utils/reactHelper';
// import markerCategoryParams from '../../common/static/markerCategoryParams';
// import { prefixString } from '../../common/utils/stringUtils';
// import MarkerCategoryFields from './components/MarkerCategoryFields';
import { getIsAdmin } from '../../common/utils/selectors';
import SelectMarkerIcon from './components/SelectMarkerIcon';
import { isGeocoordValid } from '../../common/utils/validators';
import ValidatedTextField from '../../common/form/ValidatedTextField';
import CoordinatesBlock from '../../common/components/CoordinatesBlock';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MarkerPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isAdmin = useSelector(getIsAdmin);
  const userId = useSelector((state) => state.session.user?.id);
  const [item, setItem] = useState();
  const [permissions, setPermissions] = useState();
  const [icons, setIcons] = useState([]);
  // const [categoryParams, setCategoryParams] = useState();
  const [errors, setErrors] = useState({});
  const [initState, setInitState] = useState();

  useEffect(() => {
    setItem(initState);
  }, [initState]);

  useEffect(() => {
    if (item && !permissions) {
      const permissionsDict = {
        unions: {
          baseId: item.id,
          keyBase: 'markerId',
          keyLink: 'unionId',
          linked: new Set(),
          old: new Set(),
        },
      };
      setPermissions(permissionsDict);
    }
  }, [item]);

  useEffectAsync(async () => {
    const response = await fetch('/api/icons');
    if (response.ok) {
      setIcons(await response.json());
    } else {
      setIcons([]);
    }
  }, []);

  // useEffect(() => {
  //   if (item) {
  //     if (!categoryParams) {
  //       setCategoryParams(item.attributes?.categoryParams || markerCategoryParams.marker);
  //     } else if (item.category !== categoryParams.category) {
  //       setCategoryParams(markerCategoryParams[item.category || 'marker']);
  //     }
  //   }
  // }, [item?.category]);

  // useEffect(() => {
  //   if (item && categoryParams) {
  //     setItem({ ...item, attributes: { ...item.attributes, categoryParams } });
  //   }
  // }, [categoryParams]);

  return (
    <EditItemView
      endpoint="markers"
      item={item}
      setItem={setInitState}
      permissions={permissions}
      permissionsReverse
      disabledSave={!item?.color
        || !item?.iconName || Object.values(errors).some((v) => v)}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('settingsMarker')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <ValidatedTextField
                fullWidth
                initState={initState?.name}
                required
                helperText="Слишком длинное название (не более 25 символов)" // вынести в t()?
                label={t('markerName')}
                onChange={(value, isValid) => { setItem({ ...item, name: value }); setErrors({ ...errors, name: !isValid }); }}
                validator={(value) => value.length < 26}
              />
              <ValidatedTextField
                fullWidth
                initState={initState?.description}
                required
                multiline
                helperText="Слишком длинное описание (не более 4000 символов)" // вынести в t()?
                label={t('markerDescription')}
                onChange={(value, isValid) => { setItem({ ...item, description: value }); setErrors({ ...errors, description: !isValid }); }}
                validator={(value) => value.length < 4001}
              />

              <CoordinatesBlock
                latitude={item.latitude}
                longitude={item.longitude}
                setLatitude={(latitude) => setItem((prevCoordinates) => ({ ...prevCoordinates, latitude }))}
                setLongitude={(longitude) => setItem((prevCoordinates) => ({ ...prevCoordinates, longitude }))}
                errors={errors}
                setErrors={setErrors}
              />

              <SelectMarkerIcon icons={icons} item={item} setItem={setItem} />

              <SelectField
                required
                label={t('markerColor')}
                value={item.color || ''}
                keyGetter={(it) => it.toLowerCase()}
                onChange={(e) => setItem({ ...item, color: e.target.value.toLowerCase() })}
                titleGetter={(color) => <FiberManualRecordIcon style={{ color }} />}
                data={Object.values(theme.palette.markers)}
                margin="normal"
                variant="filled"
                emptyValue={null}
                fullWidth
              />
              {/* <SelectField
                margin="normal"
                value={item.category || ''}
                emptyValue={null}
                onChange={(e) => setItem({ ...item, type: e.target.value })}
                endpoint="/api/markers/types"
                keyGetter={(it) => it.category}
                titleGetter={(it) => t(prefixString('marker', it?.type || it))}
                label={t('sharedType')}
                variant="filled"
                fullWidth
              />
              <MarkerCategoryFields categoryParams={categoryParams} setCategoryParams={setCategoryParams} /> */}
              <ValidatedTextField
                fullWidth
                initState={initState?.course}
                required
                helperText={t('ValidateErrorMsg')}
                label={t('markerCourse')}
                onChange={(value, isValid) => { setItem({ ...item, course: value }); setErrors({ ...errors, course: !isValid }); }}
                validator={(value) => isGeocoordValid(value, 360) || !value}
              />
            </AccordionDetails>
          </Accordion>
          {permissions && (
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  margin="normal"
                  endpointAll={`/api/unions?${isAdmin ? 'all=true' : `userId=${userId}`}`}
                  endpointLinked={`/api/unions?markerId=${item.id}`}
                  label={t('settingsUnions')}
                  variant="filled"
                  permissions={permissions}
                  setPermissions={setPermissions}
                  type="unions"
                  fullWidth
                  newItem={!item.id}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default MarkerPage;
