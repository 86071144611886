import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    height: 'calc(100vh - 150px)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  caption: {
    margin: '0 auto',
  },
  search: {
    marginTop: 0,
    marginBottom: 0,
    width: '300px',
    marginLeft: theme.spacing(2.5),
  },
  containerFullSize: {
    height: 'calc(100vh - 148px)',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 100px)',
    },
  },
  headerCell: {
    padding: '3px 0 !important',
  },
  bodyCell: {
    padding: '0 !important',
  },
  dataGridContainer: {
    height: '100%',
    width: '100%',
  },
}));
