import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  formatSpeed, formatDate, formatPosition, formatBoolean, collectReport, abortRequest,
} from '../../common/utils/formatter';
import { useAttributePreference } from '../../common/utils/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import { positionHandler } from '../../common/utils/requestRoute';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import ReplayPathMap from '../../map/ReplayPathMap';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { runLoadingResources } from '../../map/MapControllerDynamic';
import { errorsActions } from '../../store';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'routeReport';

// Список колонок [имя поля в json с бэкенда, имя поля в файле переводчике, возможность сортировки (0 - нет, 1 - да)]
const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  ['group', 'reportGroup', 1],
  ['serverTime', 'reportServerTime', 1],
  ['fixTime', 'reportFixTime', 1],
  ['latitude', 'reportLatitude', 0],
  ['longitude', 'reportLongitude', 0],
  ['address', 'reportAddress', 0],
  ['valid', 'reportValid', 0],
  ['speed', 'reportSpeed', 0],
  ['sat', 'reportSat', 0],
  ['mccMnc', 'reportMccMnc', 0],
  ['lacCid', 'reportLacCid', 0],
  ['visibleCids', 'reportVisibleCids', 0],
  ['accuracy', 'reportAccuracy', 0],
  ['motion', 'reportMotion', 0],
  ['powerLine', 'reportPowerLine', 0],
  ['batteryLevel', 'reportBatteryLevel', 0],
  ['protocol', 'reportProtocol', 0],
  ['message', 'reportMessage', 0],
];

const RouteReportPage = () => {
  const t = useTranslation();
  const classes = useReportStyles();
  const speedUnit = useAttributePreference('speedUnit');
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});
  const groups = useSelector((state) => state.groups.items);
  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [positionsPath, setPositionsPath] = useState({});
  const [page, setPage] = useState(0);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const reportAbortController = useRef();

  useEffect(() => {
    const result = {};
    devicesList.forEach((item) => result[item.id] = item);
    setDevicesObject(result);
  }, [devicesList]);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = (deviceId, from, to, headers) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;
    setProgress(1);

    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        deviceId.forEach((it) => query.append('deviceId', it));
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        query.append('requestName', requestName);
        const response = await fetch(`/api/reports/route?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
      }
    };
    apiCall();

    setProgress(100);
    setProgress(0);
  };

  const formatValue = (item, key, devices) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return formatDate(item[key]);
      case 'deviceId':
        return devices[item[key]]?.name;
      case 'group':
        return groups[devices[item.deviceId].groupId]?.name || '';
      case 'longitude':
      case 'latitude':
        return formatPosition(item[key], 'latitude', t);
      case 'address':
        return formatPosition(item[key], 'address', t);
      case 'valid':
        return item.valid ? t('sharedValid') : t('sharedNotValid');
      case 'speed':
        return formatSpeed(item[key], speedUnit, t);
      case 'sat':
        return item.attributes[key] !== 99 ? item.attributes[key] : '';
      case 'visibleCids':
        return item.network?.cellTowers.length > 1
          && item.network.cellTowers.slice(1).map((tower) => (tower.cellId)).join(',');
      case 'lacCid':
        return item.network?.cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`)[0];
      case 'mccMnc':
        return item.network?.cellTowers
          .map((tower, index) => (index === 0 && `${tower.mobileCountryCode}-${tower.mobileNetworkCode}`))[0];
      case 'motion':
        return formatBoolean(item.attributes[key], t);
      case 'powerLine':
      case 'message':
        return item.attributes[key] || '';
      case 'accuracy':
        return formatPosition(item[key], 'accuracy', t);
      case 'batteryLevel':
        return formatPosition(item.attributes[key], 'batteryLevel', t);
      default:
        return item[key];
    }
  };

  useEffect(() => {
    if (selectedItem && !Object.keys(positionsPath).length) {
      const path = items.reduce((a, v) => ({ ...a, [v.deviceId]: a[v.deviceId] ? [...a[v.deviceId], v] : [v] }), {});
      const deviceIds = Object.keys(path);
      const result = {};
      for (let i = 0; i < deviceIds.length; i += 1) {
        const deviceId = deviceIds[i];
        const clearResponsePath = [];
        positionHandler(path[deviceId], clearResponsePath, true);
        result[deviceId] = clearResponsePath;
      }
      setPositionsPath(result);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);
            // setDevicesObject(result);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => runLoadingResources(), []);

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      setSelectedItem={setSelectedItem}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      formatValue={formatValue}
      page={page}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      setPositionsPath={setPositionsPath}
      breadcrumbs={['reportTitle', 'reportRoute']}
      devicesList={devicesList}
      devicesObject={devicesObject}
      countProgress={countProgress}
      progressReport={progressReport}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      map={selectedItem && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <ReplayPathMap positions={positionsPath} devices={devicesObject} />
            <PositionsMap
              positions={{ [selectedItem.deviceId]: [selectedItem] }}
              devices={devicesObject}
              data={positionsTypes.current}
              noClick
            />
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </Map>
        </div>
      )}
      mapOn
    />
  );
};

export default RouteReportPage;
