import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Button, Slide, Snackbar, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import usePersistedState from '../../../common/utils/usePersistedState';
import ReplayMap from '../ReplayMap/ReplayMap';
import componentPanelStatus from '../../../common/static/componentPanelStatus';
import PositionDataButton from '../../../common/components/PositionDataButton';
import PopupStatusView from '../../../common/components/PopupStatusView';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import { getCoordinatesFromOSRM } from '../../../map/funcs/pathFuncs';
import TextRulerDistance from '../../../common/components/TextRulerDistance';
import TextMarkerCoordinates from '../../../common/components/TextMarkerCoordinates';
import ControlPanel from '../ControlPanel/ControlPanel';
import ComponentButtons from '../../../common/components/panel/ComponentButtons';
import PositionData from '../../../common/components/PositionData';
import { useStyles } from './ReplayPage.styles';
import PageProgress from '../../../common/components/PageProgress';
import logout from '../../../common/utils/logout';
import { abortRequest, collectReport } from '../../../common/utils/formatter';
import { errorsActions } from '../../../store';

const ReplayPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const dispatch = useDispatch();
  const [positions, setPositions] = useState({});
  const [positionsPath, setPositionsPath] = useState({});
  const [positionsSimilar, setPositionsSimilar] = useState({});
  const [positionsStop, setPositionsStop] = useState({});
  const [positionsBS, setPositionsBS] = useState([]);
  const [currentPositions, setCurrentPositions] = useState();
  const [pathMatching, setPathMatching] = useState({});
  const [devicesObject, setDevicesObject] = useState();
  const [devicesList, setDevicesList] = useState([]);

  const [indexes, setIndexes] = useState();
  const [nothing, setNothing] = useState(false);
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  const devices = useSelector((state) => state.devices.items);
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(true);
  const [compactMode, setCompactMode] = useState(false);
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

  const [panel, setPanel] = usePersistedState('panel', componentPanelStatus);
  const [positionDataVisible, setPositionDataVisible] = usePersistedState('positionDataVisible', true);
  const [nextPosition, setNextPosition] = useState();
  const [countPosition, setCountPosition] = useState(0);
  const popupData = useSelector((state) => state.positions.popupData);
  const styleOpen = useSelector((state) => state.map.styleOpen);
  const [from, setFrom] = useState(moment().subtract(1, 'hour'));
  const [to, setTo] = useState(moment());
  const [period, setPeriod] = useState([from.valueOf(), to.valueOf()]);
  const [buttonsDrawerOpen, setButtonsDrawerOpen] = useState(false);
  const [countProgress, setCountProgress] = useState(0);

  const [addMarkerMode, setAddMarkerMode] = useState();
  const [newMarkerCoordinates, setNewMarkerCoordinates] = useState();
  const [moveableMarker, setMoveableMarker] = useState();
  const markerState = useRef();

  const [rulerMode, setRulerMode] = useState(false);
  const [rulerDistance, setRulerDistance] = useState();

  const [selectedMarkerComponents, setSelectedMarkerComponents] = useState({});

  const osrmUrl = useSelector((state) => state.session.server.attributes.services?.osrm?.url);

  const needMoveMap = !isPhone && Object.keys(selectedPositionData).length && !popupData && positionDataVisible;

  const clearMarkerState = () => (markerState.current = {});

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const apiCall = async () => {
      const requestName = uuidv4();
      try {
        const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
        if (response.ok) {
          const result = [];
          await collectReport(response, result, setCountProgress);
          setDevicesList(result);
        } else if (response.status === 401) {
          if (response.status === 401) {
            logout(history, dispatch);
          }
        }
        setProgressDevices(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
      }
    };
    apiCall();
    return () => {
      abortController.abort(); // Cancel the request if component unmounts
    };
  }, []);

  useEffectAsync(async () => {
    /**
     * Запрашивает данные в OSRM сервисе
     */
    const res = {};
    if (panel.onLine === 2 && osrmUrl) {
      await Promise.all(Object.keys(positionsPath).map(async (key) => {
        res[key] = [];
        await Promise.all(positionsPath[key].map(async (line, i) => {
          if (Array.isArray(line)) {
            res[key][i] = await getCoordinatesFromOSRM(dispatch, line, devices[key].attributes.movement);
          } else {
            res[key][i] = line;
          }
        }));
      }));
    }
    setPathMatching(res);
  }, [positionsPath, panel.onLine, osrmUrl]);

  useEffect(() => {
    /** При изменении размеров страницы сворачивает кнопочную панель */
    if (!isTablet) {
      setButtonsDrawerOpen(false);
    }
  }, [isTablet]);

  return (
    <div className={classes.root}>
      <Tooltip title={t('backTitle')}>
        <Button
          variant="contained"
          className={classes.buttonBack}
          onClick={() => history.push('/')}
        >
          <ArrowBackIcon />
        </Button>
      </Tooltip>
      <ReplayMap
        compactMode={compactMode}
        statePositions={{
          positions,
          setPositions,
          positionsPath,
          setPositionsPath,
          positionsStop,
          setPositionsStop,
          positionsSimilar,
          setPositionsSimilar,
          positionsBS,
          setPositionsBS,
          indexes,
          setIndexes,
          pathMatching,
        }}
        fromPeriod={period[0]}
        toPeriod={period[1]}
        nextPosition={nextPosition}
        setCountPosition={setCountPosition}
        setNextPosition={setNextPosition}
        panel={panel}
        needMoveMap={needMoveMap}
        addMarkerMode={addMarkerMode}
        setAddMarkerMode={setAddMarkerMode}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
        moveableMarker={moveableMarker}
        setMoveableMarker={setMoveableMarker}
        rulerMode={rulerMode}
        setRulerDistance={setRulerDistance}
        currentPositions={currentPositions}
        setCurrentPositions={setCurrentPositions}
        setPositionDataVisible={setPositionDataVisible}
        setPositionsBS={setPositionsBS}
        setSelectedMarkerComponents={setSelectedMarkerComponents}
        clearMarkerState={clearMarkerState}
        markerState={markerState}
        devicesObject={devicesObject}
      />
      {!!(!positionDataVisible && Object.keys(selectedPositionData).length) && (
        <PositionDataButton setPositionDataVisible={setPositionDataVisible} />
      )}
      {Object.keys(selectedPositionData).length && (
        <PopupStatusView
          popupData={popupData}
          positionDataVisible={positionDataVisible}
          setPositionsBS={setPositionsBS}
          setNextPosition={setNextPosition}
          countPosition={countPosition}
          setCountPosition={setCountPosition}
          nextPosition={nextPosition}
          positionsSimilar={positionsSimilar}
          setPositionDataVisible={setPositionDataVisible}
          selectedMarkerComponents={selectedMarkerComponents}
        />
      )}
      {!!(positionDataVisible && Object.keys(selectedPositionData).length && !popupData) && (
        <PositionData
          setNextPosition={setNextPosition}
          countPosition={countPosition}
          setCountPosition={setCountPosition}
          nextPosition={nextPosition}
          positionsSimilar={positionsSimilar}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          selectedMarkerComponents={selectedMarkerComponents}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={nothing}
        autoHideDuration={3000}
        onClose={() => setNothing(false)}
        message={t('nothingInfo')}
        TransitionComponent={(props) => (<Slide {...props} direction="up" />)}
        transitionDuration={500}
        className={classes.foundNothing}
      />
      {!styleOpen && (
        <ComponentButtons
          panel={panel}
          setPanel={setPanel}
          showOnly
          progress={progress}
          setButtonsDrawerOpen={setButtonsDrawerOpen}
          buttonsDrawerOpen={buttonsDrawerOpen}
          needMoveMap={needMoveMap}
          addMarkerMode={addMarkerMode}
          setAddMarkerMode={setAddMarkerMode}
          rulerMode={rulerMode}
          setRulerMode={setRulerMode}
          markerState={markerState}
        />
      )}
      {!!((addMarkerMode || moveableMarker) && newMarkerCoordinates) && (
        <TextMarkerCoordinates
          coordinates={newMarkerCoordinates}
          setAddMarkerMode={setAddMarkerMode}
          markerState={markerState}
          setMoveableMarker={setMoveableMarker}
          setNewMarkerCoordinates={setNewMarkerCoordinates}
          clearMarkerState={clearMarkerState}
        />
      )}
      {!!(rulerMode && rulerDistance) && <TextRulerDistance distance={rulerDistance} />}
      <ControlPanel
        to={to}
        setTo={setTo}
        from={from}
        setFrom={setFrom}
        progress={progress}
        setProgress={setProgress}
        progressDevices={progressDevices}
        compactMode={compactMode}
        setCompactMode={setCompactMode}
        indexes={indexes}
        setIndexes={setIndexes}
        positions={positions}
        setPositions={setPositions}
        period={period}
        setPeriod={setPeriod}
        panel={panel}
        setNothing={setNothing}
        needMoveMap={needMoveMap}
        setPositionsPath={setPositionsPath}
        setPositionsStop={setPositionsStop}
        setPositionsSimilar={setPositionsSimilar}
        currentPositions={currentPositions}
        devicesObject={devicesObject}
        devicesList={devicesList}
        countProgress={countProgress}
        setDevicesObject={setDevicesObject}
      />
      <PageProgress />
    </div>
  );
};

export default ReplayPage;
