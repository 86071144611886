import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Button } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListIcon from '@material-ui/icons/ViewList';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import BottomMenu from '../../../common/components/BottomMenu';
import usePersistedState from '../../../common/utils/usePersistedState';
import PositionData from '../../../common/components/PositionData';
import MainMap from '../MainMap/MainMap';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import MainPanel from '../MainPanel/MainPanel';
import componentPanelStatus from '../../../common/static/componentPanelStatus';
import PopupStatusView from '../../../common/components/PopupStatusView';
import ConfirmExit from '../../../common/components/ConfirmExit';
import TextExpirationDay from '../../../common/components/TextExpirationDay';
import { getIsAdmin } from '../../../common/utils/selectors';
import SmsAlerts from '../../../common/components/SmsAlerts';
import TextMarkerCoordinates from '../../../common/components/TextMarkerCoordinates';
import NotificationButtons from '../../../common/components/NotificationButtons';
import { getCoordinatesFromOSRM } from '../../../map/funcs/pathFuncs';
import TextRulerDistance from '../../../common/components/TextRulerDistance';
import ComponentButtons from '../../../common/components/panel/ComponentButtons';
import { useStyles } from './MainPage.styles';
import tailPeriods from '../../../common/static/tailPeriods';
import { positionsActions, tailActions } from '../../../store';
import UniversalPanelButton from './UniversalPanelButton';
import UniversalPanel from '../UniversalPanel/UniversalPanel';
import OpacityMapSliders from '../../../common/components/OpacityMapSliders';
import SearchAddress from '../../../common/components/SearchAddress';
import { getCategory, getFrom } from '../../../common/utils/formatter';
import requestRoute from '../../../common/utils/requestRoute';
import FilePreviewLayout from '../../../common/components/FilePreviewLayout';

const MainPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isAdmin = useSelector(getIsAdmin);
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);
  const positionsPath = useSelector((state) => state.tail.positionsPath);
  const stateSyncTail = useSelector((state) => state.tail.stateSyncTail);
  const [positionsBS, setPositionsBS] = useState([]);
  const [osrmTimeout, setOsrmTimeout] = useState(0);

  const periods = useSelector((state) => state.session.server.attributes.tailPeriods || tailPeriods);
  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const devices = useSelector((state) => state.devices.items);
  const deviceInited = useSelector((state) => state.devices.deviceInited);
  const selectedDevices = useSelector((state) => state.tail.selectedDevices);
  const selectedCategories = useSelector((state) => state.tail.selectedCategories);
  const devicesToChangeState = useSelector((state) => state.tail.devicesToChangeState);
  const stateDevicesToChangeState = useSelector((state) => state.tail.stateDevicesToChangeState);

  const newSms = useSelector((state) => state.sms.items);
  const item = useSelector((state) => state.tail.tail);
  const [period, setPeriod] = usePersistedState('periodTail', periods[0]);
  const [progress, setProgress] = useState(0);
  const periodRef = useRef(period);
  const selectedPositionData = useSelector((state) => state.positions.selectedPositionData);
  const needToShowDataPanel = useSelector((state) => state.positions.needToShowDataPanel);
  const [panel, setPanel] = usePersistedState('panel', componentPanelStatus);
  const [positionDataVisible, setPositionDataVisible] = usePersistedState('positionDataVisible', true);

  const [newSmsLength, setNewSmsLength] = useState(newSms.length);
  const [smsArrived, setSmsArrived] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [nextPosition, setNextPosition] = useState();
  const [countPosition, setCountPosition] = useState(0);
  const popupData = useSelector((state) => state.positions.popupData);
  const styleOpen = useSelector((state) => state.map.styleOpen);
  const exportOpen = useSelector((state) => state.map.exportOpen);
  const newSmsInitialized = useSelector((state) => state.sms.newSmsInitialized);
  const [buttonsDrawerOpen, setButtonsDrawerOpen] = useState(false);

  const [openConfirmExit, setOpenConfirmExit] = useState(false);
  const timeExpiration = useSelector((state) => state.session.timeExpiration);

  const [addMarkerMode, setAddMarkerMode] = useState();
  const [newMarkerCoordinates, setNewMarkerCoordinates] = useState();
  const [moveableMarker, setMoveableMarker] = useState();
  const markerState = useRef();

  const [moveableDevice, setMoveableDevice] = useState();

  const [rulerMode, setRulerMode] = useState(false);
  const [rulerDistance, setRulerDistance] = useState();

  const [selectedMarkerComponents, setSelectedMarkerComponents] = useState({});

  const [opacityMaps, setOpacityMaps] = usePersistedState('opacityMaps', {});
  const [opacityMapsOpen, setOpacityMapsOpen] = useState(false);

  const [searchCoords, setSearchCoords] = useState();

  const [addDevicesTail, setAddDevicesTail] = useState([]);

  const osrmUrl = useSelector((state) => state.session.server?.attributes.services?.osrm?.url);

  const needMoveMap = !isPhone && Object.keys(selectedPositionData).length && !popupData && positionDataVisible && needToShowDataPanel;

  const clearMarkerState = () => (markerState.current = {});

  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  useEffect(() => {
    if (selectedPositionData.objClass !== 'marker') {
      setSelectedMarkerComponents({});
    }
  }, [selectedPositionData.objClass]);

  useEffectAsync(async () => {
    if (item && panel.onLine === 2 && osrmUrl) {
      const res = {};
      if (osrmTimeout === 0) {
        setOsrmTimeout(1);
        await Promise.all(Object.keys(positionsPath).map(async (key) => {
          res[key] = await getCoordinatesFromOSRM(dispatch, positionsPath[key], devices[key].attributes.movement);
        }));
        setOsrmTimeout(0);
        dispatch(tailActions.changePathMatching(res));
      }
    } else {
      dispatch(tailActions.changePathMatching({}));
    }
  }, [item, stateSyncTail, panel.onLine, osrmUrl]);

  const handleClose = () => {
    setCollapsed(!collapsed);
  };

  const updateTail = (items) => {
    const handledDevices = items
      .filter((device) => device
        && !device.temporary
        && !closedDevices[device.id]
        && (
          selectedDevices[device.id]
          ?? selectedCategories[getCategory(device)]
          ?? true
        ));

    requestRoute(
      handledDevices,
      getFrom(period).toISOString(),
      moment().toISOString(),
      { Accept: 'application/json' },
      {},
      panel.onBs,
      item,
      setProgress,
      dispatch,
    );
  };

  // Проверяет валидность текущей позиции перед добавлением её в список позиций трека
  // const check = (current, arrPositions) => current?.longitude && (panel.onBs ? true : !current.attributes.approximate)
  //   && !(arrPositions.length && arrPositions[arrPositions.length - 1].fixTime === current.fixTime);

  useEffect(() => {
    const eventCleanPositionsInterval = setInterval(() => {
      dispatch(tailActions.clean(periodRef.current));
    }, 9000);
    return () => clearInterval(eventCleanPositionsInterval);
  }, []);

  useEffect(() => {
    if (!isTablet) {
      setButtonsDrawerOpen(false);
    }
  }, [isTablet]);

  useEffect(() => setCollapsed(isTablet), [isTablet]);

  useEffect(() => {
    const newLength = newSms.length;
    if (newSmsLength < newLength && newSmsInitialized) {
      setSmsArrived(true);
    }
    setNewSmsLength(newLength);
  }, [newSms.length]);

  useEffect(() => {
    dispatch(tailActions.turnWorking(true));
    return () => {
      dispatch(tailActions.turnWorking(false));
      dispatch(tailActions.clear());
    };
  }, [history]);

  useEffect(() => () => {
    dispatch(positionsActions.unselectPosition());
  }, [history]);

  useEffect(() => {
    if (item && deviceInited) {
      updateTail(Object.values(devices));
    }
  }, [item, deviceInited, period]);

  useEffect(() => {
    if (item && deviceInited && addDevicesTail.length) {
      updateTail(addDevicesTail.map((deviceId) => devices[deviceId]));
      setAddDevicesTail([]);
    }
  }, [addDevicesTail.length]);

  useEffect(() => {
    const handleDevicesToChangeStateTimeout = setTimeout(() => {
      const removedDevices = [];
      const addDevices = [];
      Object.entries(devicesToChangeState).forEach(([deviceId, val]) => {
        if (val) {
          addDevices.push(deviceId);
        } else {
          removedDevices.push(deviceId);
        }
      });
      dispatch(tailActions.clearState());
      dispatch(tailActions.remove(removedDevices));
      setAddDevicesTail(addDevices);
    }, 2000);

    return () => clearTimeout(handleDevicesToChangeStateTimeout);
  }, [stateDevicesToChangeState]);

  return (
    <div className={classes.root}>
      <MainMap
        searchCoords={searchCoords}
        isTablet={isTablet}
        item={item}
        statePositions={{
          positionsBS,
          setPositionsBS,
        }}
        nextPosition={nextPosition}
        setCountPosition={setCountPosition}
        setNextPosition={setNextPosition}
        panel={panel}
        needMoveMap={needMoveMap}
        addMarkerMode={addMarkerMode}
        setAddMarkerMode={setAddMarkerMode}
        setNewMarkerCoordinates={setNewMarkerCoordinates}
        moveableMarker={moveableMarker}
        setMoveableMarker={setMoveableMarker}
        moveableDevice={moveableDevice}
        setMoveableDevice={setMoveableDevice}
        rulerMode={rulerMode}
        markerState={markerState}
        clearMarkerState={clearMarkerState}
        setRulerDistance={setRulerDistance}
        setPositionDataVisible={setPositionDataVisible}
        setPositionsBS={setPositionsBS}
        setSelectedMarkerComponents={setSelectedMarkerComponents}
        opacityMaps={opacityMaps}
      />
      {!!(Object.keys(selectedPositionData).length && needToShowDataPanel) && (
        <PopupStatusView
          popupData={popupData}
          positionDataVisible={positionDataVisible}
          setPositionsBS={setPositionsBS}
          setNextPosition={setNextPosition}
          countPosition={countPosition}
          setCountPosition={setCountPosition}
          nextPosition={nextPosition}
          setPositionDataVisible={setPositionDataVisible}
          selectedMarkerComponents={selectedMarkerComponents}
          setMoveableDevice={setMoveableDevice}
        />
      )}
      {!!(positionDataVisible && Object.keys(selectedPositionData).length && !popupData && needToShowDataPanel) && (
        <PositionData
          setNextPosition={setNextPosition}
          countPosition={countPosition}
          setCountPosition={setCountPosition}
          nextPosition={nextPosition}
          setPositionDataVisible={setPositionDataVisible}
          setPositionsBS={setPositionsBS}
          selectedMarkerComponents={selectedMarkerComponents}
          setMoveableDevice={setMoveableDevice}
        />
      )}
      <NotificationButtons
        positionDataVisible={positionDataVisible}
        setPositionDataVisible={setPositionDataVisible}
        newSms={newSms}
        moveableMarker={moveableMarker || moveableDevice}
        setMoveableMarker={moveableMarker ? setMoveableMarker : setMoveableDevice}
      />
      <SearchAddress collapsed={collapsed} setSearchCoords={setSearchCoords} />
      {(!exportOpen && !styleOpen) && (
        <ComponentButtons
          panel={panel}
          setPanel={setPanel}
          mainPage
          setButtonsDrawerOpen={setButtonsDrawerOpen}
          buttonsDrawerOpen={buttonsDrawerOpen}
          needMoveMap={needMoveMap}
          addMarkerMode={addMarkerMode}
          setAddMarkerMode={setAddMarkerMode}
          rulerMode={rulerMode}
          setRulerMode={setRulerMode}
          markerState={markerState}
          setOpacityMapsOpen={setOpacityMapsOpen}
        />
      )}
      <Button
        variant="contained"
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
      >
        <ListIcon />
      </Button>
      {useMemo(() => (
        <MainPanel
          collapsed={collapsed}
          period={period}
          setPeriod={setPeriod}
          item={item}
          handleClose={handleClose}
          setSmsSent={setSmsSent}
          panel={panel}
          setPanel={setPanel}
        />
      ), [collapsed, period, item])}
      <UniversalPanelButton />
      <UniversalPanel />
      <FilePreviewLayout />
      <SmsAlerts smsSent={smsSent} setSmsSent={setSmsSent} smsArrived={smsArrived} setSmsArrived={setSmsArrived} />
      <BottomMenu setOpenConfirmExit={setOpenConfirmExit} />
      <ConfirmExit open={openConfirmExit} setOpen={setOpenConfirmExit} />
      {!!(timeExpiration && isAdmin) && <TextExpirationDay timeExpiration={timeExpiration} />}
      {!!((addMarkerMode || moveableMarker) && newMarkerCoordinates) && (
        <TextMarkerCoordinates
          coordinates={newMarkerCoordinates}
          setNewMarkerCoordinates={setNewMarkerCoordinates}
          markerState={markerState}
          clearMarkerState={clearMarkerState}
          setMoveableMarker={setMoveableMarker}
          setAddMarkerMode={setAddMarkerMode}
        />
      )}
      {!!(rulerMode && rulerDistance) && <TextRulerDistance distance={rulerDistance} />}
      {opacityMapsOpen && <OpacityMapSliders opacityMaps={opacityMaps} setOpacityMaps={setOpacityMaps} setOpacityMapsOpen={setOpacityMapsOpen} />}
    </div>
  );
};

export default MainPage;
