import React, { useState } from 'react';
import {
  makeStyles,
  Button,
  FormControl,
  Container,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OptionsLayout from '../OptionsLayout';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import logout from '../../../common/utils/logout';
import {
  checkUrl, formatUrl, getAttr, isValid,
} from '../../../common/utils/formatter';
import lifetimes from '../../../common/static/lifetimes';
import ExtraMapsBlock from './ExtraMapsBlock';
import { eventName, notify } from '../../../NotificationSyncController';
import SmtpBlock from './SmtpBlock';
import TelegramBlock from './TelegramBlock';
import PreferencesBlock from './PreferencesBlock';
import ServicesBlock from './ServicesBlock';
import AutoregistartionBlock from './AutoregistrationBlock';
import PermissionsBlock from './PermissionsBlock';
import TimeoutsBlock from './TimeoutsBlock';
import GeocodeBlock from './GeocodeBlock';
import MapBlock from './MapBlock';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
  adornmentButton: {
    padding: 0,
  },
}));

const ServerPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();

  const server = useSelector((state) => state.session.server);

  const [item, setItem] = useState(server);
  const [errorBscoder, setErrorBscoder] = useState(false);
  const [errorOsrm, setErrorOsrm] = useState(false);
  const [errors, setErrors] = useState({});

  const [categoryLifetime, setCategoryLifetime] = useState(Object.keys(lifetimes.categories).reduce((a, v) => (
    { ...a, [v]: (item.attributes?.categoryLifetime ?? {})[v] ?? lifetimes.categories[v] }
  ), {}));

  const [temporaryLifetime, setTemporaryLifetime] = useState(item.attributes?.temporaryLifetime ?? lifetimes.temporary);

  const [zoomCenter, setZoomCenter] = useState({
    zoom: server.zoom,
    longitude: server.longitude,
    latitude: server.latitude,
  });

  const checkValidation = () => {
    let valid = true;
    if (!checkUrl(item.attributes.services?.bscoder?.url)) {
      setErrorBscoder(true);
      valid = false;
    }
    if (!checkUrl(item.attributes.services?.osrm?.url)) {
      setErrorOsrm(true);
      valid = false;
    }
    return valid;
  };

  const validate = (services) => {
    const body = {
      ...item,
      zoom: Number(zoomCenter.zoom ?? 0),
      longitude: Number(zoomCenter.longitude ?? 0),
      latitude: Number(zoomCenter.latitude ?? 0),
      attributes: {
        ...item.attributes,
        services,
        categoryLifetime,
        temporaryLifetime,
      },
    };
    if (item.attributes['mail.smtp.port']) {
      body.attributes['mail.smtp.port'] = Number(body.attributes['mail.smtp.port']);
    }
    if (item.attributes['status.timeout']) {
      body.attributes['status.timeout'] = Number(body.attributes['status.timeout']);
    }
    return body;
  };

  const handleSave = async () => {
    if (!checkValidation()) {
      return;
    }
    if (getAttr(item, 'mail.smtp.port') && !isValid('mail.smtp.port', getAttr(item, 'mail.smtp.port'))) {
      return;
    }

    const services = {
      bscoder: { url: formatUrl(item.attributes.services?.bscoder?.url) },
      osrm: { url: formatUrl(item.attributes.services?.osrm?.url) },
    };

    const body = validate(services);

    const response = await fetch('/api/server', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      notify(eventName.UpdateWebsiteSettings, body);
      history.goBack();
    } else if (response.status === 401) {
      logout(history, dispatch);
    }
  };

  const isDisabled = () => {
    if (Object.keys(errors).length) {
      return true;
    }
    if (!isValid('longitude', zoomCenter.longitude)) {
      return true;
    }
    if (!isValid('latitude', zoomCenter.latitude)) {
      return true;
    }
    if (!isValid('zoom', zoomCenter.zoom)) {
      return true;
    }
    if (item.attributes.registerUnknown && !item.attributes.registerUnknownDefaultGroupId) {
      return true;
    }
    return false;
  };

  return (
    <OptionsLayout>
      <Container maxWidth="xs" className={classes.container}>
        {item && (
          <>
            <PreferencesBlock item={item} setItem={setItem} />
            <MapBlock
              item={item}
              setItem={setItem}
              zoomCenter={zoomCenter}
              setZoomCenter={setZoomCenter}
              errors={errors}
              setErrors={setErrors}
            />
            <GeocodeBlock item={item} setItem={setItem} />
            <TimeoutsBlock
              item={item}
              setItem={setItem}
              categoryLifetime={categoryLifetime}
              setCategoryLifetime={setCategoryLifetime}
              temporaryLifetime={temporaryLifetime}
              setTemporaryLifetime={setTemporaryLifetime}
            />
            <ExtraMapsBlock item={item} setItem={setItem} />
            <SmtpBlock item={item} setItem={setItem} />
            <TelegramBlock item={item} setItem={setItem} />
            <ServicesBlock
              item={item}
              setItem={setItem}
              errorBscoder={errorBscoder}
              setErrorBscoder={setErrorBscoder}
              errorOsrm={errorOsrm}
              setErrorOsrm={setErrorOsrm}
            />
            <AutoregistartionBlock item={item} setItem={setItem} />
            <PermissionsBlock item={item} setItem={setItem} />
          </>
        )}
        <FormControl fullWidth margin="normal">
          <div className={classes.buttons}>
            <Button type="button" color="primary" variant="outlined" onClick={() => history.goBack()}>
              {t('sharedCancel')}
            </Button>
            <Button type="button" color="primary" variant="contained" onClick={handleSave} disabled={isDisabled()}>
              {t('sharedSave')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default ServerPage;
