/* eslint-disable object-curly-newline */
import {
  getGroupName,
} from '../../funcs/calculateCellValue';
import baseProperties from '../shared/baseProperties';
import presetsColumns from '../shared/presetsColumns';

export default {
  calculateValues: {
    shared: ({ now, t, groups, theme }) => [
      {
        ...presetsColumns.calculateValues.lastUpdateShort({ now, t }),
        ...baseProperties,
      },
      {
        ...presetsColumns.calculateValues.lastUpdate(),
        ...baseProperties,
      },
      presetsColumns.calculateValues.deviceIcon.iconWithText({ t }),
      {
        ...presetsColumns.base.groupName,
        ...baseProperties,
        calculateCellValue: (item) => getGroupName(item, groups),
        calculateGroupValue: (item) => getGroupName(item, groups),
      },
      presetsColumns.calculateValues.deviceColor({ theme }),
      presetsColumns.calculateValues.category({ t }),
    ],
  },
  common: [
    presetsColumns.common.id,
    presetsColumns.common.name,
  ],
};
