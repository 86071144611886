import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import useDebounceWithCancel from '../hooks/useDebounceWithCancel';

const ValidatedTextField = ({
  initState,
  onChange,
  validator,
  helperText,
  ...props
}) => {
  const [value, setValue] = useState(initState || '');
  const [error, setError] = useState(false);

  const delayedValidate = useDebounceWithCancel((newValue) => {
    const isValid = validator(newValue);
    setError(!isValid);
    onChange(newValue, isValid);
  });

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    delayedValidate(newValue);
  };

  useEffect(() => {
    delayedValidate(initState || '');
  }, [initState]);

  return (
    <TextField
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={error && helperText}
      {...props}
    />
  );
};

export default ValidatedTextField;
