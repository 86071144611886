import React, { useEffect, useState } from 'react';
import {
  Divider, IconButton, Link,
  Paper, Toolbar, Typography, CircularProgress, Tooltip,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import commands from '../../../common/static/commands';
import { getIsAdmin } from '../../../common/utils/selectors';
import logout from '../../../common/utils/logout';
import { useStylesCommandPanel } from '../MainPage/MainPage.styles';

const CommandsPanel = ({ device, setCommandsPanelDevice, setSmsSent }) => {
  const classes = useStylesCommandPanel();
  const t = useTranslation();
  const [items, setItems] = useState();
  const [command, setCommand] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);
  const [progress, setProgress] = useState(false);

  useEffect(() => setItems(commands[device.attributes.protocol]), [device]);

  const clickItem = async () => {
    setProgress(true);
    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: 0,
        deviceId: device.id,
        description: command.description,
        type: 'custom',
        attributes: { data: command.command },
        textChannel: true,
      }),
    });
    if (response.ok) {
      setCommandsPanelDevice(null);
      setSmsSent('sent');
    } else if (response.status === 401) {
      logout(history, dispatch);
    } else {
      setSmsSent('no-sent');
    }
    setProgress(false);
  };

  return (
    <Paper square elevation={3} className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="subtitle2" color="inherit" className={classes.head}>
          {device.name}
        </Typography>
        <IconButton onClick={() => setCommandsPanelDevice(null)} size="small" style={{ marginLeft: 'auto', marginBottom: 0 }}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      {device.phone ? (
        <List>
          {items && Object.keys(items).filter((item) => item !== 'custom').map((item) => (
            <Tooltip title={items[item]?.description || ''} key={item}>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => setCommand(items[item])}
              >
                <ListItemText
                  primary={items[item]?.command}
                  secondary={items[item]?.type || ''}
                />
              </ListItem>
            </Tooltip>
          ))}
          <ListItem
            key="custom"
            className={classes.listItem}
          >
            <ListItemText
              primary={(
                <TextField
                  margin="normal"
                  value={command?.command || ''}
                  onChange={(event) => setCommand({ ...command, command: event.target.value })}
                  label={t('commandMessage')}
                  variant="filled"
                  className={classes.textField}
                />
              )}
            />
            <ListItemSecondaryAction>
              {progress ? (
                <IconButton>
                  <CircularProgress size={24} />
                </IconButton>
              ) : (
                <Tooltip title={t('commandSend')}>
                  <IconButton onClick={() => command && clickItem('custom')}>
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ) : (
        <>
          <Typography align="center" style={{ color: 'darkred' }}>
            {t('commandNoPhone')}
            {', '}
            {isAdmin ? (
              <Link component="button" onClick={() => history.push(`/device/${device.id}`)}>{t('settingsTitle')}</Link>
            ) : (
              t('commandNoPhone2')
            )}
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default CommandsPanel;
