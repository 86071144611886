import { Cookies } from 'react-cookie';
import {
  devicesActions, geofencesActions, groupsActions, mapActions, markersActions, positionsActions, sessionActions, smsActions, unionsActions,
} from '../../store';
import { map } from '../../map/Map';

export default (history, dispatch) => {
  const cookies = new Cookies();
  const { href } = window.location;
  map.fire('closeAllPopups');
  if (!href.includes('/reset-password') && !href.includes('/register')) {
    history.push('/login');
  }
  // eslint-disable-next-line no-undef
  dispatch(sessionActions.updateUser(null));
  cookies.remove('user', { path: '/' });
  cookies.remove('password', { path: '/' });
  dispatch(devicesActions.clear(null));
  dispatch(positionsActions.clear());
  dispatch(unionsActions.clear(null));
  dispatch(geofencesActions.clear(null));
  dispatch(markersActions.clear(null));
  dispatch(groupsActions.clear(null));
  dispatch(smsActions.clear(null));
  dispatch(mapActions.updateZoomInitialized(null));
  dispatch(smsActions.updateNewSmsInitialized(false));
  window.localStorage.removeItem('closedDevices');
  window.localStorage.removeItem('deviceCategoriesOrder');
};
