import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import AdjustIcon from '@material-ui/icons/Adjust';
import SmsIcon from '@material-ui/icons/Sms';
import EventIcon from '@material-ui/icons/Event';
import ReportIcon from '@material-ui/icons/Report';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import GestureIcon from '@material-ui/icons/Gesture';
import { Album, FilterTiltShift } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { getIsAdmin } from '../../../common/utils/selectors';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItem button key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  selected: PropTypes.bool.isRequired,
};

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const isAdmin = useSelector(getIsAdmin);
  const accessibleCategoriesByDetected = useSelector((state) => state.devices.accessibleCategoriesByDetected);

  const devicesDetectedPages = {
    detected_object: <MenuItem
      key="detected_object"
      title={t('reportDetectedObject')}
      link="/reports/detected"
      icon={<AdjustIcon />}
      selected={location.pathname === '/reports/detected'}
    />,
    radar: <MenuItem
      key="radar"
      title={t('reportRadars')}
      link="/reports/radar"
      icon={<FilterTiltShift />}
      selected={location.pathname === '/reports/radar'}
    />,
    anti_uav: <MenuItem
      key="anti_uav"
      title={t('reportAntiUav')}
      link="/reports/antiuav"
      icon={<Album />}
      selected={location.pathname === '/reports/antiuav'}
    />,
  };

  return (
    <>
      <List>
        <MenuItem
          title={t('reportRoute')}
          link="/reports/route"
          icon={<TimelineIcon />}
          selected={location.pathname === '/reports/route'}
        />
        {accessibleCategoriesByDetected.map((category) => devicesDetectedPages[category])}
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<EventIcon />}
          selected={location.pathname === '/reports/event'}
        />
        <MenuItem
          title={t('reportTrips')}
          link="/reports/trip"
          icon={<GestureIcon />}
          selected={location.pathname === '/reports/trip'}
        />
        <MenuItem
          title={t('reportStops')}
          link="/reports/stop"
          icon={<LocalParkingIcon />}
          selected={location.pathname === '/reports/stop'}
        />
        <MenuItem
          title={t('reportSms')}
          link="/reports/sms"
          icon={<SmsIcon />}
          selected={location.pathname === '/reports/sms'}
        />
        {/* <MenuItem
          title={t('reportStat')}
          link="/reports/stat"
          icon={<AssessmentIcon />}
          selected={location.pathname === '/reports/stat'}
        /> */}
        {isAdmin && (
          <>
            <Divider />
            <MenuItem
              title={t('reportLogs')}
              link="/reports/log"
              icon={<ReportIcon />}
              selected={location.pathname === '/reports/log'}
            />
          </>
        )}

      </List>
    </>
  );
};

export default ReportsMenu;
