import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'extraLayers',
  initialState: {
    panel: {
      onFlightRadarFlights: 0,
      onFlightRadarAirports: 0,
    },
    stateSyncPanel: new Date().getTime(),
    flightRadarFlights: [],
    flightRadarAirports: [],
  },
  reducers: {
    switch(state, action) {
      state.panel[action.payload.name] = action.payload.status;
      window.localStorage.setItem('extraLayersPanel', JSON.stringify(state.panel));
      state.stateSyncPanel = new Date().getTime();
    },
    refresh(state, action) {
      state[action.payload.name] = action.payload.points;
    },
  },
});

export { actions as extraLayersActions };
export { reducer as extraLayersReducer };
