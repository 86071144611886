import { formatBoolean } from '../../../utils/formatter';
import {
  ICON_NAME_SEPARATOR, STRING_SETTINGS_PRESENCE_SIGN, TEMPORARY_PROPERTY,
  YES, NO,
} from '../constants';
import { eventName, notify } from '../../../../NotificationSyncController';
import { isUndefinedOrNull } from '../../../utils/stringUtils';

export const getStringFromBoolean = (value, t) => formatBoolean(value, t);

/**
 * Вставляет настройки в строку. Для корректной работы экспорта таблицы devextreme рекомендуется первым параметром передавать переведенную строку.
 * @param {*} value Оригинальное значение.
 * @param {*} settings Строка, которую необходимо внедрить в строку.
 */
export const insertSettingsToString = (value, settings) => `${value}${ICON_NAME_SEPARATOR}${settings}${ICON_NAME_SEPARATOR}${STRING_SETTINGS_PRESENCE_SIGN}`;
/**
 * Возвращает строку и внедренные настройки.
 * @param {*} value Строка содержащие настройки.
 */
export const parseSettingsFromString = (value) => value.split(ICON_NAME_SEPARATOR);

export const isEmptyOrNullCollection = (collection) => {
  if (collection === undefined) return true;
  if (collection.length === 0) return true;
  if (collection[0] === undefined) return true;
  return false;
};

export const clearObjectsWithTemporaryProperties = (objects) => {
  for (const object of objects) {
    for (const field of Object.values(TEMPORARY_PROPERTY)) {
      delete object[field];
    }
  }
};

export const handleClickByObject = (object, chosenDevice) => {
  if (chosenDevice === object.id) {
    notify(eventName.StopTrackingDevice);
  } else {
    notify(eventName.TrackDevice, object);
  }
};

export const filterAndClearCollectionProperties = (collection, filterPredicate) => {
  if (isEmptyOrNullCollection(collection)) return [];

  const foundedAndFilteredObjects = collection.filter(filterPredicate);

  if (isEmptyOrNullCollection(foundedAndFilteredObjects)) return [];

  clearObjectsWithTemporaryProperties(foundedAndFilteredObjects);

  return foundedAndFilteredObjects;
};

export const isUndefined = (value) => value === undefined || value === null || value === 'undefined';

export const isTextEqualsYes = (value) => value.toLocaleLowerCase() === YES.toLocaleLowerCase();

export const getPropertyOrDefaultValue = (object, property, defaultValue = undefined) => {
  if (isUndefinedOrNull(object)) {
    return defaultValue;
  }
  if (property in object) {
    return object[property];
  }
  return defaultValue;
};

export const getYesOrNoPhrase = (value) => (value ? YES : NO);
