import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'groups',
  initialState: {
    items: {},
  },
  reducers: {
    update(state, action) {
      // Пересобираем массив в объект с ключом id.
      state.items = action.payload.reduce((cur, next) => ({ ...cur, [next.id]: next }), {});
    },
    clear(state) {
      state.items = {};
    },
  },
});

export { actions as groupsActions };
export { reducer as groupsReducer };
