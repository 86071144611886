import React from 'react';
import { toTitleCase } from '../../../utils/formatter';

const ClusterIcon = ({ value, panel, onMouseEnter }) => {
  const getClustersIconSrc = (check) => {
    let iconName;
    const iconPartName = 'clusters';
    if (check) {
      iconName = `${iconPartName}-1`;
    } else if (onMouseEnter === value) {
      iconName = `${iconPartName}-off-enter`;
    } else {
      iconName = `${iconPartName}-off`;
    }
    return `/images/icon/switch/${iconName}.svg`;
  };

  return (
    <img
      src={getClustersIconSrc(panel[`on${toTitleCase(value)}`])}
      alt=""
    />
  );
};

export default ClusterIcon;
