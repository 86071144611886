import React from 'react';
import {
  Select, MenuItem, FormControl, IconButton, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from './LocalizationProvider';
import tailPeriods from '../static/tailPeriods';
import { sortPeriods } from '../utils/selectors';
import { tailActions } from '../../store';

const TailFilter = ({
  period, setPeriod,
}) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const item = useSelector((state) => state.tail.tail);
  const periods = useSelector((state) => state.session.server.attributes.tailPeriods || tailPeriods);

  const changePeriod = (value) => {
    setPeriod(value);
    // dispatch(tailActions.changePeriod(value));
    window.localStorage.setItem('periodTail', value);
  };

  return (
    <>
      <Tooltip title={t('tailTitle')}>
        <IconButton onClick={() => dispatch(tailActions.turnTail(!item))}>
          <img
            src={`/images/icon/switch/${item ? 'tail-1' : 'tail-off'}.svg`}
            alt=""
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('tailTimeTitle')}>
        <FormControl variant="filled" fullWidth>
          <Select value={period} onChange={(e) => changePeriod(e.target.value)}>
            {[...periods].sort(sortPeriods).map((val) => (
              <MenuItem key={val} value={val}>
                {`${val.split('_')[0]} ${t(`shared${val.split('_')[1].toUpperCase()}`)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </>
  );
};

export default TailFilter;
