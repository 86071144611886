import { createSlice } from '@reduxjs/toolkit';
import { getCategory, getFrom } from '../common/utils/formatter';

const { reducer, actions } = createSlice({
  name: 'tail',
  initialState: {
    stateSyncTail: new Date().getTime(),
    tail: JSON.parse(window.localStorage.getItem('tailOn')) ?? false,
    deviceCategories: {},
    working: false,
    positions: {},
    positionsPath: {},
    positionsSimilar: {},
    positionsStop: {},
    pathMatching: {},
    selectedDevices: JSON.parse(window.localStorage.getItem('tailSelectedDevices')) || {},
    selectedCategories: JSON.parse(window.localStorage.getItem('tailSelectedCategories')) || {},
    devicesToChangeState: {},
    stateDevicesToChangeState: new Date().getTime(),
  },
  reducers: {
    turnWorking(state, action) {
      state.working = action.payload;
    },
    changeState(state, action) {
      let needUpdateState = false;

      const addOrDelete = (deviceId, val) => {
        if (state.devicesToChangeState[deviceId] === undefined) {
          state.devicesToChangeState[deviceId] = val;
        } else if (state.devicesToChangeState[deviceId] !== val) {
          delete state.devicesToChangeState[deviceId];
        }
        needUpdateState = true;
      }

      Object.entries(action.payload).forEach(([deviceId, val]) => {
        if (val) {
          if (state.selectedDevices[deviceId] ?? (state.deviceCategories[deviceId] === undefined ? false : state.selectedCategories[state.deviceCategories[deviceId]]) ?? true) {
            addOrDelete(deviceId, val);
          }
        } else {
          addOrDelete(deviceId, val);
        }
      });
      if (needUpdateState) {
        state.stateDevicesToChangeState = new Date().getTime();
      }
    },
    clearState(state, action) {
      state.devicesToChangeState = {};
    },
    refreshDevices(state, action) {
      state.deviceCategories = {};
      action.payload.forEach((item) => {
        state.deviceCategories[item.id] = getCategory(item);
      });
    },
    updateDevices(state, action) {
      action.payload.forEach((item) => {
        state.deviceCategories[item.id] = getCategory(item);
      });
    },
    removeDevices(state, action) {
      for (const item of action.payload) {
        delete state.deviceCategories[item];
        delete state.selectedDevices[item];
        window.localStorage.setItem('tailSelectedDevices', JSON.stringify(state.selectedDevices));
      }
    },
    turnTail(state, action) {
      state.tail = action.payload;
      window.localStorage.setItem('tailOn', action.payload);
      if (!action.payload) {
        state.positions = {};
        state.positionsPath = {};
        state.positionsSimilar = {};
        state.positionsStop = {};
        state.pathMatching = {};
        state.stateSyncTail = new Date().getTime();
      }
    },
    changeCategory(state, action) {
      state.selectedCategories = { ...state.selectedCategories, ...action.payload };
      window.localStorage.setItem('tailSelectedCategories', JSON.stringify(state.selectedCategories));
    },
    changeDevice(state, action) {
      state.selectedDevices = { ...state.selectedDevices, ...action.payload };
      window.localStorage.setItem('tailSelectedDevices', JSON.stringify(state.selectedDevices));
    },
    // changePeriod(state, action) {
    //   state.period = action.payload;
    // },
    init(state, action) {
      if (state.tail && state.working) {
        state.positions = { ...state.positions, ...action.payload.positions };
        state.positionsPath = { ...state.positionsPath, ...action.payload.positionsPath };
        state.positionsSimilar = { ...state.positionsSimilar, ...action.payload.positionsSimilar };
        state.positionsStop = { ...state.positionsStop, ...action.payload.positionsStop };
        state.stateSyncTail = new Date().getTime();
      }
    },
    clear(state) {
      state.positions = {};
      state.positionsPath = {};
      state.positionsSimilar = {};
      state.positionsStop = {};
      state.pathMatching = {};
      state.stateSyncTail = new Date().getTime();
    },
    add(state, action) {
      const check = (current, arrPositions) => current?.longitude && !(arrPositions.length && arrPositions[arrPositions.length - 1].fixTime === current.fixTime);
      let needUpdateState = false;
      if (state.tail && state.working) {
        action.payload.forEach((item) => {
          const { deviceId } = item;
          if (state.selectedDevices[deviceId] ?? (state.deviceCategories[deviceId] === undefined ? false : state.selectedCategories[state.deviceCategories[deviceId]]) ?? true) {
            if (!state.positions[deviceId]) {
              state.positions[deviceId] = [];
            }
            if (check(item, state.positions[deviceId])) {
              if (state.positionsSimilar[deviceId]) {
                const similarKey = `${item.longitude}-${item.longitude}`;
                const similar = state.positionsSimilar[deviceId];
                state.positionsSimilar[deviceId][similarKey] = similar[similarKey] ? [...similar[similarKey], item] : [item];
              }
              state.positions[deviceId].push(item);
            }

            if (!state.positionsPath[deviceId]) {
              state.positionsPath[deviceId] = [];
            }
            if (check(item, state.positionsPath[deviceId])) {
              state.positionsPath[deviceId].push(item);
            }

            if (!state.pathMatching[deviceId]) {
              state.pathMatching[deviceId] = [];
            }
            state.pathMatching[deviceId].push(item);
            needUpdateState = true;
          }
        });
        if (needUpdateState) {
          state.stateSyncTail = new Date().getTime();
        }
      }
    },
    clean(state, action) {
      const selectedFrom = getFrom(action.payload);

      const cleanTail = (positionsF) => {
        let cleanedPositions = [];
        for (let i = 0; i < positionsF.length; i += 1) {
          if ((positionsF[i]?.fixTime ?? positionsF[i]?.endTime) >= selectedFrom.toISOString()) {
            cleanedPositions = positionsF.slice(i);
            break;
          }
        }
        return cleanedPositions;
      };

      const iterate = (sP) => Object.entries(sP).forEach(([key, positions]) => sP[key] = cleanTail(positions));

      const iterateSimilar = (sP) => Object.keys(sP).forEach((key) => iterate(sP[key]));

      if (state.tail && state.working) {
        iterate(state.positions);
        iterateSimilar(state.positionsSimilar);
        iterate(state.positionsStop);
        iterate(state.positionsPath);
        iterate(state.pathMatching);
        state.stateSyncTail = new Date().getTime();
      }
    },
    hideOpen(state) {
      state.stateSyncTail = { name: 'hideOpen', state: new Date().getTime() };
    },
    remove(state, action) {
      if (state.tail && state.working) {
        action.payload.forEach((deviceId) => {
          delete state.positions[deviceId];
          delete state.positionsPath[deviceId];
          delete state.pathMatching[deviceId];
          delete state.positionsSimilar[deviceId];
          delete state.positionsStop[deviceId];
        });
        state.stateSyncTail = new Date().getTime();
      }
    },
    changePathMatching(state, action) {
      state.pathMatching = action.payload;
    },
  },
});

export { actions as tailActions };
export { reducer as tailReducer };
