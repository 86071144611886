export default {
  buttons: {
    opacityMaps: [{ title: 'opacityMaps' }],
    ruler: [{ title: 'ruler' }],
    tail: [{ title: 'tail' }],
    extra: [{ title: 'extra' }],
    layers: [{ title: 'layers' }],
    route: [
      { title: 'current', text: true, clusters: true },
      { title: 'markers', text: true, clusters: true },
      { title: 'geofence' },
      { title: 'bs', text: false, clusters: false },
      {
        title: 'stops',
        text: true,
        clusters: true,
      },
      {
        title: 'sides',
        text: true,
        clusters: true,
      },
      {
        title: 'positions',
        text: true,
        clusters: true,
      },
      {
        title: 'line',
        text: false,
        clusters: false,
        childrens: [
          { title: 'lineFact', index: 1 },
          { title: 'lineMatching', index: 2 },
        ],
      },
      {
        title: 'tower',
        text: false,
        clusters: false,
        childrens: [
          { title: 'towerMain', index: 1 },
          { title: 'towerAll', index: 2 },
        ],
      },
    ],
    markerAdd: [{ title: 'markerAdd' }],
    extraLayers: [{ title: 'extraLayers' }],
  },
  ordering: [
    'opacityMaps',
    'ruler',
    'tail',
    'route',
    'extra',
    'markerAdd',
    'extraLayers',
  ],
};
