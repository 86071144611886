import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'devextreme-react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PreviewFileWindow from './PreviewFileWindow';
import FreeModalWindow from './FreeModalWindow';
import { filePreviewLayoutActions } from '../../store';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles(() => ({
  tools: {
    position: 'absolute',
    right: 5,
    bottom: 5,
    zIndex: 5,
  },
}));

const defaultZ = 1400;

const FilePreviewLayout = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useTranslation();

  const { windows } = useSelector((state) => state.filePreviewLayout);

  const zIndexes = useRef({});
  const max = useRef();

  const [imageItemToDisplay, setImageItemToDisplay] = useState({});

  useEffect(() => {
    const removing = new Set();

    for (const key of Object.keys(windows)) {
      removing.add(key);
      if (zIndexes.current[key]) {
        continue;
      }
      max.current = defaultZ + Object.keys(zIndexes.current).length;
      zIndexes.current[key] = max.current;
    }

    for (const key of Object.keys(zIndexes.current)) {
      if (removing.has(key)) {
        continue;
      }
      delete zIndexes.current[key];
    }
    setImageItemToDisplay(windows);
  }, [windows]);

  const isNumberWindowsEqualsToZero = Object.entries(imageItemToDisplay).length === 0;

  const hideImagePopup = (name) => {
    dispatch(filePreviewLayoutActions.removeByKey(name));
  };

  const hideImagePopupWithWrapper = (name) => {
    try {
      hideImagePopup(name);
    } catch (error) {
      console.log(error);
    }
  };

  const selectWindow = (name) => {
    if (zIndexes.current[name] === max.current) {
      return;
    }
    for (const key of Object.keys(zIndexes.current)) {
      zIndexes.current[key] -= 1;
    }
    zIndexes.current[name] = max.current;
    setImageItemToDisplay({ ...imageItemToDisplay });
  };

  if (isNumberWindowsEqualsToZero) {
    return (null);
  }

  const closeAllWindows = () => dispatch(filePreviewLayoutActions.clear());

  return (
    <>
      {Object.entries(imageItemToDisplay).map(([key, { name, url }]) => (
        <FreeModalWindow
          key={key}
          style={{ zIndex: zIndexes.current[key] }}
          title={name}
          enableFullscreenMode
          enableResizable
          onCloseModalWindow={() => hideImagePopupWithWrapper(key)}
          onMouseDown={() => selectWindow(key)}
        >
          <PreviewFileWindow path={url} />
        </FreeModalWindow>
      ))}

      <div className={classes.tools}>
        <Button
          icon="close"
          text={t('filePreviewLayoutCloseAllWindows')}
          hint={t('filePreviewLayoutCloseAllWindows')}
          onClick={closeAllWindows}
        />
      </div>
    </>
  );
};

export default FilePreviewLayout;
