import React from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from '../components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  search: {
    marginTop: 0,
    width: '100%',
  },
}));

const SearchField = ({
  searchName, setSearchName, small, placeholder, label, margin, disableAutoFocus, classes,
}) => {
  const t = useTranslation();
  const innerClasses = useStyles();

  return (
    <TextField
      margin={margin ?? 'normal'}
      value={searchName}
      onChange={(event) => setSearchName(event.target.value)}
      label={label ?? t('sharedSearch')}
      className={classes?.search ?? (!small ? innerClasses.search : '')}
      variant="standard"
      autoFocus={!disableAutoFocus && !small}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
